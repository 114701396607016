import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchLessonCount = createAsyncThunk('lesson/count/fetch', async (data) => {
  const response = await api.get(`/api/temp/practice/get_lesson_count`, data);
  return response.data;
});

export const fetchLessonUsedInfo = createAsyncThunk('lesson/usedinfo/fetch', async (data) => {
  const response = await api.get(`/api/temp/practice/get_lesson_used_info`, data);
  return response.data;
});

export const fetchAllPractices = createAsyncThunk(
  "/practice/fetchAllProblem",
  async () => {
    const response = await api.get(`/api/temp/practice/get_all_round`);
    return response.data;
  }
);

export const deleteOneRound = createAsyncThunk(
    "/practice/deleteOneRound",
    async (data) => {
      const response = await api.post(`/api/temp/practice/delete_one_round/${data._id}`, data);
      return response.data;
    }
);

export const addOneRound = createAsyncThunk(
  "/practice/addOneRound",
  async (data) => {
    const response = await api.post(`/api/temp/practice/add_round`, data);
    return response.step;
  }
);

export const editOneRound = createAsyncThunk(
  "/practice/editOneRound",
  async (data) => {
    const rid = data.rid;
    const response = await api.post(`/api/temp/practice/edit_round/${rid}`, data);
    return response;
  }
);


const practiceSlice = createSlice({
  name: "practiceField",
  initialState: { practices: [], status: "idle", error: null, message: "", loading: false, version: 0.0, count: 0, usedInfo: [], unfilled: false, maxLesson: 0 },
  reducers: {
    setPracticeFieldStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setPracticeUnfilled: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchLessonCount.pending, (state) => {
          state.status = "loading";
          state.loading = true;
        })
        .addCase(fetchLessonCount.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            state.count = action.payload.count;
        })
        .addCase(fetchLessonCount.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(fetchLessonUsedInfo.pending, (state) => {
          state.status = "loading";
          state.loading = true;
        })
        .addCase(fetchLessonUsedInfo.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            state.usedInfo = action.payload;
        })
        .addCase(fetchLessonUsedInfo.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(fetchAllPractices.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllPractices.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.practices = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllPractices.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneRound.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneRound.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneRound.pending, (state) => {
            state.status = "loading";
        })
        .addCase(deleteOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })

  },
});

export const {setPracticeFieldStatus, setPracticeUnfilled} = practiceSlice.actions;

export default practiceSlice.reducer;
