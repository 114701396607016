import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchUsers = createAsyncThunk('/user/fetch', async (data) => {
    const response = await api.post(`/api/user/get_page`, data);
    return response.data;
});

export const deleteOneUser = createAsyncThunk('/user/deleteOneUser', async (id) => {
    const response = await api.delete(`/api/user/delete_one/${id}`);
    return response.data;
});

export const registerUser = createAsyncThunk('/user/register', async (data) => {
  const response = await api.post(`/api/user/register`, data);
  return response.data;
});

export const resetUserPassword = createAsyncThunk('/user/resetPassword', async (data) => {
    const response = await api.post(`/api/user/reset_password/${data.id}`, data);
    return response.data;
});

export const forgetPianoUserPassword = createAsyncThunk('/user/forgetPassword', async (data) => {
    const response = await api.post(`/api/auth/piano/user/reset_password`, data);
    return response.data;
});

export const addCoinsToUser = createAsyncThunk('/user/addCoins', async (data) => {
    console.log(data);
    const response = await api.post(`/api/user/add_coins/${data.id}`, data);
    return response.data;
})

const pianoUserSlice = createSlice({
  name: "pianoUser",
  initialState: { users:[],  status: "idle", error: null, loading: false, amount: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchUsers.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchUsers.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.amount = action.payload.amount;
            state.users = action.payload.users;
        })
        .addCase(fetchUsers.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(registerUser.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(registerUser.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Added!")
        })
        .addCase(registerUser.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneUser.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneUser.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneUser.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(resetUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(resetUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Reset Password!")
        })
        .addCase(resetUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(forgetPianoUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(forgetPianoUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully send password reset message to the user!")
        })
        .addCase(forgetPianoUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addCoinsToUser.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(addCoinsToUser.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully add coins to the user!")
        })
        .addCase(addCoinsToUser.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default pianoUserSlice.reducer;