import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchOneLesson = createAsyncThunk(
  "/bubble/fetchOneLesson",
  async (lid) => {
    const response = await api.get(`/api/bubble/temp/lesson/get_one_lesson/${lid}`);
    return response.data;
  }
);

export const addOneBubble = createAsyncThunk(
  "/bubble/addOneBubble",
  async (lid) => {
    const response = await api.post(`/api/bubble/temp/lesson/add_bubble/${lid}`);
    return response.data;
  }
);

export const editOneBubble = createAsyncThunk(
  "/bubble/editOneBubble",
  async (data) => {
    const response = await api.post(`/api/bubble/temp/lesson/edit_bubble/${data.bid}`, data);
    return response.data;
  }
);

export const deleteOneBubble = createAsyncThunk(
  "/bubble/deleteOneBubble",
  async (bid) => {
    const response = await api.delete(`/api/bubble/temp/lesson/delete_one_bubble/${bid}`);
    return response.data;
  }
);

const bubbleLessonDetailSlice = createSlice({
  name: "bubbleLessonDetail",
  initialState: { bubbles: [], status: "idle", error: null, message: "", loading: false, lid: "", lesson: 1 },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchOneLesson.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchOneLesson.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.bubbles = action.payload.bubbles;
            state.lesson = action.payload.lesson;
            state.loading = false;
        })
        .addCase(fetchOneLesson.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneBubble.pending, (state) => {
          state.status = "loading";
        })
        .addCase(addOneBubble.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully add!")
            state.status = "idle";
        })
        .addCase(addOneBubble.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneBubble.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneBubble.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneBubble.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneBubble.pending, (state) => {
          state.status = "loading";
        })
        .addCase(deleteOneBubble.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneBubble.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default bubbleLessonDetailSlice.reducer;
