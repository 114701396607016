import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Form, Input, InputNumber, Popconfirm, Table, Typography, Button, Space, message} from 'antd';
import { fetchVideos, editVideo, deleteOneVideo, addVideo } from "../../../../features/unused/videoSlice";

import "./Video.css"

const Video = () => {
    const dispatch = useDispatch();
    const videoInfo = useSelector((state) => state.video);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = () => {
        messageApi.open({
            type: 'warning',
            content: 'Please fill out the video URL.' 
        })
    }

    useEffect(() => {
        if(videoInfo.status === "idle") {
          dispatch(fetchVideos());
        }
    }, [dispatch, videoInfo]);


    const [form] = Form.useForm();
    const [editingID, setEditingID] = useState('');
    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20'],
    });
    const [newVideoUrl, setNewVideoUrl] = useState("");


    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        const inputNode = inputType === 'number' ? <InputNumber style={{textAlign: "center"}}/> : <Input style={{textAlign: "center"}}/>;
        return (
            <td {...restProps}>
                {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                    margin: 0,
                    }}
                    rules={[
                    {
                        required: true,
                        message: `Please Input ${title}!`,
                    },
                    ]}
                >
                    {inputNode}
                </Form.Item>
                ) : (
                children
                )}
            </td>
        );
    };
    const isEditing = (record) => record._id === editingID;


    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    const handleNewVideoUrl = (e) => {
        setNewVideoUrl(e.target.value);
    }

    const handleVideoAdd = () => {
        if(!newVideoUrl) {
            warning();
            return;
        }
        dispatch(addVideo({url: newVideoUrl}))
    }
    
    //Operation (edit, delete)

    const edit = (record) => {
        form.setFieldsValue({
            url: '',
            ...record,
        });
        setEditingID(record._id);
    };
    const cancel = () => {
        setEditingID('');
    };
    const save = async () => {
        dispatch(editVideo(form.getFieldValue()));
        setEditingID('');
    };
    const deleteVideo = (record) => {
        dispatch(deleteOneVideo(record._id));
    }
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '8%',
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Social Video Url',
            dataIndex: 'url',
            key: 'url',
            width: '77%',
            textWrap: 'word-break',
            editable: true,
            ellipsis: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                <span>
                    <Typography.Link
                        onClick={() => save(record)}
                        style={{
                            marginRight: 15,
                        }}
                    >
                        Save
                    </Typography.Link>
                    <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Typography.Link
                        style={{
                            color: "red"
                        }}
                    >
                        Cancel
                    </Typography.Link>
                    </Popconfirm>
                </span>
                ) : (
                    <span>
                        <Typography.Link disabled={editingID !== ''} onClick={() => edit(record)} style={{marginRight: 15}}>
                            Edit
                        </Typography.Link>
                        <Popconfirm title="Sure to cancel?" onConfirm={() => deleteVideo(record)}>
                            <Typography.Link
                                disabled={editingID !== ''}
                                style = {editingID === '' ? {color: "red"} : null}
                            >
                                Delete
                            </Typography.Link>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return(
        <>
            {contextHolder}
            <Space.Compact style={{ width: '40%' }} className = "addVideo">
                <Input placeholder="Please input social video url" value={newVideoUrl} onChange={handleNewVideoUrl}/>
                <Button type="primary" onClick={() => handleVideoAdd()}>Add Video</Button>
            </Space.Compact>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    loading={videoInfo.loading}
                    bordered
                    dataSource={videoInfo.videos}
                    columns={mergedColumns}
                    rowClassName="editable-row"
                    pagination={pagination}
                    onChange={handleTableChange}  
                    className="videoTable"
                />
            </Form>
        </>
    )
}

export default Video;