import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout } from 'antd';

import "./Home.css";

import Header from "../../layouts/header/Header";
import Main from "../../layouts/content/dashboard/Main";
import Footer from "../../layouts/footer/Footer";
import MySider from "../../layouts/sider/Sider";

import Lesson from "../../layouts/content/piano/lesson/Lesson";
import Admin from "../../layouts/content/admin/Admin";
import PianoUser from "../../layouts/content/piano/user/User";
import PianoPayment from "../../layouts/content/piano/payment/PianoPayment";
import Avatar from "../../layouts/content/piano/shop/avatar/Avatar";
import Coin from "../../layouts/content/piano/shop/coin/Coin";
import Background from "../../layouts/content/piano/background/Background";
import Notification from "../../layouts/content/piano/notification/Notification";
import Unit from "../../layouts/content/piano/unit/Unit";
import Topic from "../../layouts/content/piano/topic/Topic";
import Test1 from "../../layouts/content/piano/test/test1/Test1";
import Test2 from "../../layouts/content/piano/test/test2/Test2";
import Test3 from "../../layouts/content/piano/test/test3/Test3";
import Test4 from "../../layouts/content/piano/test/test4/Test4";
import Test5 from "../../layouts/content/piano/test/test5/Test5";
import LessonDetail from "../../layouts/content/piano/lesson/components/LessonDetail";
import Test1Detail from "../../layouts/content/piano/test/test1/components/Test1Detail";
import Test2Detail from "../../layouts/content/piano/test/test2/components/Test2Detail";
import Test3Detail from "../../layouts/content/piano/test/test3/components/Test3Detail";
import Test4Detail from "../../layouts/content/piano/test/test4/components/Test4Detail";
import Test5Detail from "../../layouts/content/piano/test/test5/components/Test5Detail";
import Test5ProblemDetail from "../../layouts/content/piano/test/test5/components/Test5ProblemDetail";

import BubbleUser from "../../layouts/content/bubble/user/User";
import BubblePayment from "../../layouts/content/bubble/payment/BubblePayment";
import BubbleBackground from "../../layouts/content/bubble/background/Background";
import BubbleTopicBackground from "../../layouts/content/bubble/topic_background/TopicBackground";
import BubbleLesson from "../../layouts/content/bubble/lesson/BubbleLesson";
import BubbleLessonDetail from "../../layouts/content/bubble/lesson/components/BubbleLessonDetail";
import BubbleSound from "../../layouts/content/bubble/sound/BubbleSound";
import BubbleNotification from "../../layouts/content/bubble/notification/BubbleNotification";

import EBubbleUser from "../../layouts/content/ebubble/user/User";
import EBubblePayment from "../../layouts/content/ebubble/payment/EBubblePayment";
import EBubbleBackground from "../../layouts/content/ebubble/background/Background";
import EBubbleTopicBackground from "../../layouts/content/ebubble/topic_background/TopicBackground";
import EBubbleLesson from "../../layouts/content/ebubble/lesson/EBubbleLesson";
import EBubbleLessonDetail from "../../layouts/content/ebubble/lesson/components/EBubbleLessonDetail";
import EBubbleSound from "../../layouts/content/ebubble/sound/EBubbleSound";
import EBubbleNotification from "../../layouts/content/ebubble/notification/EBubbleNotification";

import QuranUser from "../../layouts/content/quran/user/User";
// import QuranPayment from "../../layouts/content/quran/payment/QuranPayment";
import QuranReciters from "../../layouts/content/quran/reciters/Reciters";
import QuranRecitation from "../../layouts/content/quran/recitation/Recitation";
import QuranText from "../../layouts/content/quran/text/QuranText";
import QuranTextDetail from "../../layouts/content/quran/text/components/QuranTextDetail";
// import QuranSuraAya from "../../layouts/content/quran/download/Sura";
import QuranSuraAya from "../../layouts/content/quran/download/Sura";
import QuranJuzHizb from "../../layouts/content/quran/download/Juz";
// import QuranPage from "../../layouts/content/quran/download/Page";

import Score from "../../layouts/content/unused/score/Score";
import Version from "../../layouts/content/unused/version/Version";
import Video from "../../layouts/content/unused/video/Video";

const { Content } = Layout;

const Home = () => {

  const themeInfo = useSelector((state) => state.theme);

  return (
    <React.Fragment>
      <Layout
        hasSider
        style={{
          minHeight: '100vh',
        }}
      >
        <MySider/>
        <Layout  className= { themeInfo.collapsed ? "sider-collapsed-content" : "sider-no-collapsed-content" } >
          <Header />
            <Content
                style={{
                  margin: '0 16px',
                }}
              >
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/arabic/piano/user" element={<PianoUser />} />
                <Route path="/arabic/piano/payment" element={<PianoPayment />} />
                <Route path="/arabic/piano/version" element={<Version />} />
                <Route path="/arabic/piano/video" element={<Video/>}/>
                <Route path="/arabic/piano/score" element={<Score />} />
                <Route path="/arabic/piano/shop/avatar" element={<Avatar />} />
                <Route path="/arabic/piano/shop/coin" element={<Coin />} />
                <Route path="/arabic/piano/background" element={<Background />} />
                {/* <Route path="/arabic/piano/notification" element={<Notification />} /> */}
                <Route path="/arabic/piano/unit" element={<Unit />}/>
                <Route path="/arabic/piano/topic" element={<Topic />}/>
                <Route path="/arabic/piano/lesson" element={<Lesson />} />
                <Route path="/arabic/piano/lesson/:id" element={<LessonDetail />} />
                <Route path="/arabic/piano/test1" element={<Test1 />} />
                <Route path="/arabic/piano/test1/:id" element={<Test1Detail />} />
                <Route path="/arabic/piano/test2" element={<Test2 />} />
                <Route path="/arabic/piano/test2/:id" element={<Test2Detail />} />
                <Route path="/arabic/piano/test3" element={<Test3 />} />
                <Route path="/arabic/piano/test3/:id" element={<Test3Detail />} />
                <Route path="/arabic/piano/test4" element={<Test4 />} />
                <Route path="/arabic/piano/test4/:id" element={<Test4Detail />} />
                <Route path="/arabic/piano/test5" element={<Test5 />} />
                <Route path="/arabic/piano/test5/:id" element={<Test5Detail />} />
                <Route path="/arabic/piano/test5/:rid/:pid" element={<Test5ProblemDetail />} />
                <Route path="/arabic/bubble/user" element={<BubbleUser />} />
                <Route path="/arabic/bubble/payment" element={<BubblePayment />} />
                <Route path="/arabic/bubble/background" element={<BubbleBackground />} />
                <Route path="/arabic/bubble/topic-background" element={<BubbleTopicBackground />} />
                <Route path="/arabic/bubble/lesson" element={<BubbleLesson />} />
                <Route path="/arabic/bubble/lesson/:id" element={<BubbleLessonDetail />} />
                <Route path="/arabic/bubble/sound" element={<BubbleSound/>} />
                {/* <Route path="/arabic/bubble/notification" element={<BubbleNotification/>} /> */}
                <Route path="/english/bubble/user" element={<EBubbleUser />} />
                <Route path="/english/bubble/payment" element={<EBubblePayment />} />
                <Route path="/english/bubble/background" element={<EBubbleBackground />} />
                <Route path="/english/bubble/topic-background" element={<EBubbleTopicBackground />} />
                <Route path="/english/bubble/lesson" element={<EBubbleLesson />} />
                <Route path="/english/bubble/lesson/:id" element={<EBubbleLessonDetail />} />
                <Route path="/english/bubble/sound" element={<EBubbleSound/>} />
                {/* <Route path="/english/bubble/notification" element={<EBubbleNotification/>} /> */}
                <Route path="/arabic/quran/user" element={<QuranUser />} />
                {/* <Route path="/arabic/quran/payment" element={<QuranPayment />} /> */}
                <Route path="/arabic/quran/sura_aya" element={<QuranSuraAya />} />
                <Route path="/arabic/quran/text_tafseer" element={<QuranText />} />
                <Route path="/arabic/quran/reciters" element={<QuranReciters/>}/>
                <Route path="/arabic/quran/recitation" element={<QuranRecitation/>}/>
                <Route path="/arabic/quran/text_tafseer/:id" element={<QuranTextDetail />} />
                <Route path="/arabic/quran/juz_hizb" element={<QuranJuzHizb/>} />
                
                {/* <Route path="/arabic/quran/page" element={<QuranPage/>} /> */}

                {/* <Route path="/arabic/quran/background" element={<QuranBackground />} />
                <Route path="/arabic/quran/topic-background" element={<QuranTopicBackground />} />
                <Route path="/arabic/quran/lesson" element={<QuranLesson />} />
                <Route path="/arabic/quran/lesson/:id" element={<QuranLessonDetail />} />
                <Route path="/arabic/quran/sound" element={<QuranSound/>} /> */}
              </Routes>
            </Content>
          <Footer />
        </Layout>
      </Layout>
    </React.Fragment>
  );
};

export default Home;
