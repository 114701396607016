import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {Popconfirm, Typography, Space, Table, Button, Modal, Divider, ColorPicker, Badge, message} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { fetchAllReciters, addOneReciter, editOneReciter, deleteOneReciter } from "../../../../features/quran/reciters/recitersSlice";

import './Reciters.css';

const Reciters = () => {
    const dispatch = useDispatch();
    const topicInfo = useSelector(state => state.quranReciters);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = (content) => {
        messageApi.open({
          type: 'warning',
          content: content,
        });
    };

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20'],
    });
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [selectedTopicInfo, setSelectedTopicInfo] = useState({no: 0, name: "", reciterid: 0, bio: "", id: ""});

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    useEffect(() => {
        if(topicInfo.status === "idle") {
          dispatch(fetchAllReciters());
        }
      }, [dispatch, topicInfo]);

      let maxNo = 0;

    //Table operation

    const handleAddTopic = () => {
        setSelectedTopicInfo({ no: maxNo + 1, name:"", reciterid: 0, bio: "", id: ""});
        setIsAddModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedTopicInfo({
            no: record.no,
            name: record.name,
            reciterid: record.reciterid,
            bio: record.bio,
            id: record._id
        });
        setIsEditModalOpen(true);
    }

    const deleteTopic = (record) => {
        dispatch(deleteOneReciter({id: record._id, no: record.no}));
    }

    //modal operation

    const handleCreate = () => {
        if(selectedTopicInfo.name === "") {
            warning("Please input Name title.");
            return;
        }
        dispatch(addOneReciter(selectedTopicInfo));
        setIsAddModalOpen(false);
    }

    const handleSave = () => {
        dispatch(editOneReciter(selectedTopicInfo));
        setIsEditModalOpen(false);
    }

    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '10%',
            sorter: (a, b) => b.unit - a.unit,
            defaultSortOrder: 'descend',
            editable: true,
            ellipsis: true,
            render : (text, record, index) => {
                if(maxNo < text) maxNo = text;
                return text;
            }
        },
        {
            title: 'Reciter_Id',
            dataIndex: 'reciterid',
            key: 'reciterid',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            textWrap: 'word-break',
            ellipsis: true,
        },
      
        // {
        //     title: 'Aya',
        //     dataIndex: 'aya',
        //     key: 'name',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     ellipsis: true,
        // },
        {
            title: 'Bio',
            dataIndex: 'bio',
            key: 'bio',
            textWrap: 'word-break',
            ellipsis: true,
        },
        // {
        //     title: 'Badge Color',
        //     dataIndex: 'badge',
        //     key: 'badge',
        //     width: "12%",
        //     render: (text, record, index) => (<Badge color={text} />)
                
        // },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '15%',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteTopic(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return(
        <>
            {contextHolder}
            <Button type="primary" size='middle' onClick={() => handleAddTopic()} className = "addTopic"> Add </Button>
            <Table
                loading={topicInfo.loading}
                bordered
                dataSource={topicInfo.topics}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Reciter</h2>} open={isAddModal} onOk={handleCreate} onCancel={handleCancel} okText="Create">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="No"
                    sx={{ m: 1, width: '12ch' }}
                    value = {selectedTopicInfo.no}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, no: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    disabled
                    />
                    <TextField
                        label="Reciter_Id"
                        sx={{ m: 1, width: '20ch' }}
                        value = {selectedTopicInfo.reciterid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, reciterid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                  
                    <TextField
                    label="Name"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedTopicInfo.name}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, name: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <TextField
                        label="Bio"
                        sx={{ m: 1, width: '100%' }}
                        value = {selectedTopicInfo.bio}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, bio: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>Reciter {selectedTopicInfo.no}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                        label="Reciter_Id"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedTopicInfo.reciterid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, reciterid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Name"
                        sx={{ m: 1, width: '40ch' }}
                        value = {selectedTopicInfo.name}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, name: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Bio"
                        sx={{ m: 1, width: '100%' }}
                        value = {selectedTopicInfo.bio}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, bio: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}

export default Reciters;