import React, {useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchOneDetail, editOneDetail, addOneDetail, deleteOneDetail} from "../../../../../features/quran/text/textDetailSlice";
import { setContentFieldStatus } from "../../../../../features/quran/text/textSlice";
import { api } from "../../../../../config/api";

import { Popconfirm, Table, Typography, Space, Button, Image, Modal, Divider, Col, Row, message } from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { successNotification, errorNotification } from "../../../../../utilities/Notification";

import { serverURL } from "../../../../../config/config";
import "./QuranTextDetail.css"

const {Title} = Typography;

const QuranTextDetail = () => {

    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [pagination, setPagination] = useState({
        pageSize: 5,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
    });

    const [selectedPage, setSelectedPage] = useState("");

    const [selectedDetailInfo, setSelectedDetailInfo] = useState({
        trans: "", taf: "", arabictext: "", meaning: "", ayahid: 0,
    });
    
    const dispatch = useDispatch();
    const contentInfo = useSelector((state) => state.quranContentDetailField);


    useEffect(() => {
        dispatch(fetchOneDetail(id));
    }, [])

    useEffect(() => {
        if(contentInfo.status === "idle") {
            dispatch(fetchOneDetail(id));
        }
    }, [dispatch, id, contentInfo]);


    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    // operation

    const handleAdd = () => {
        dispatch(setContentFieldStatus({status: "idle"}));
        dispatch(addOneDetail(id));
        setSelectedDetailInfo({
            ayahid : 0,
            trans : "",
            taf : "",
            arabictext: "", 
            meaning: "",
        })
    }

    const handleEdit = (record) => {

        setSelectedDetailInfo({
            ayahid : record.ayahid,
            trans : record.trans,
            taf : record.taf,
            arabictext : record.arabictext,
            meaning: record.meaning,
        })

        setSelectedPage(record._id);

        showModal();
    }

    const handleDelete = (record) => {
        dispatch(setContentFieldStatus({status: "idle"}));
        dispatch(deleteOneDetail(record._id));
    }

    //modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    }

    const isAudioFile = (file) => {
        return file && file.type.startsWith('audio/');
    }

    const handleSave = () => {
        const info = {
            bid: selectedPage,
            ayahid: selectedDetailInfo.ayahid,
            trans: selectedDetailInfo.trans,
            taf: selectedDetailInfo.taf,
            arabictext: selectedDetailInfo.arabictext,
            meaning: selectedDetailInfo.meaning,
        }
        dispatch(setContentFieldStatus({status: "idle"}));
        dispatch(editOneDetail(info));
        hideModal();
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: '15ch',
            render: (text, record, index) => {
                const i = contentInfo.details.findIndex(detail => detail === record);
                return i + 1;
            }
        },
        {
            title: 'Ayah_id',
            dataIndex: 'ayahid',
            width: '18ch',
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: 'arabic_text',
            dataIndex: 'arabictext',
            width: '25%',
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: 'Translation',
            dataIndex: 'trans',
            width: '25%',
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: 'Tafseer',
            dataIndex: 'taf',
            width: '25%',
            render: (text, record, index) => {
                return text;
            }
        },
        {
            title: 'meaning',
            dataIndex: 'meaning',
            width: '25%',
            render: (text, record, index) => {
                return text;
            }
        },
    
        {
        title: 'Operation',
        dataIndex: 'operation',
        width: '20ch',
        render: (_, record) => (
            <Space size = "middle" >

                <Typography.Link  onClick={() => handleEdit(record)} style={{ color: "blue"}}> 
                    <Tooltip title="Edit" >
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link> 

                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];
    console.log("content-"+JSON.stringify(contentInfo.details));
    return (
        <>
            <Title level={2} style={{textAlign: "center"}}>Translation & Tafseer {contentInfo.lesson}_Page</Title>
        
            <Button type="primary" size='middle' onClick={() => handleAdd()} className = "addRound"> Add Detail </Button>
            <Table
                className="MyPracticeDetailTable"
                bordered
                dataSource={contentInfo.details}
                columns={columns}
                loading={contentInfo.loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Change Detail</h2>} open={isModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <TextField
                    label="Ayah_id"
                    sx={{ m: 1, width: '20ch' }}
                    value = {selectedDetailInfo.ayahid}
                    onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, ayahid: e.target.value})}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Arabic_text"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedDetailInfo.arabictext}
                    onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, arabictext: e.target.value})}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Translation"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedDetailInfo.trans}
                    onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, trans: e.target.value})}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Tafseer"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedDetailInfo.taf}
                    onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, taf: e.target.value})}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Meaning"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedDetailInfo.meaning}
                    onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, meaning: e.target.value})}
                    InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                </Space>
            </Modal>

        </>
    )
}

export default QuranTextDetail;