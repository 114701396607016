import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../config/api";

import { successNotification, errorNotification } from "../../../../utilities/Notification";

export const fetchAllAvatars = createAsyncThunk('/temp/avatar/fetch_all', async () => {
  const response = await api.get(`/api/temp/avatar/get_all_avatar`);
  return response.data;
});

export const addOneAvatar = createAsyncThunk('/temp/avatar/add_one', async (data) => {
    const response = await api.post(`/api/temp/avatar/add_avatar`, data);
    return response.data;
});

export const editOneAvatar = createAsyncThunk('/temp/avatar/edit_one', async (data) => {
    const response = await api.post(`/api/temp/avatar/edit_avatar/${data.id}`, data);
    return response.data;
});

export const deleteOneAvatar = createAsyncThunk('/temp/avatar/delete_one', async (id) => {
    const response = await api.delete(`/api/temp/avatar/delete_one/${id}`);
    return response.data;
});

export const editTag = createAsyncThunk('/temp/coin/edit_tag', async (data) => {
  const response = await api.post(`/api/temp/avatar/edit_tag`, data);
  return response.data;
});

const avatarSlice = createSlice({
  name: "avatar",
  initialState: { avatars:[],  status: "idle", error: null, loading: false},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllAvatars.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllAvatars.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.avatars = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllAvatars.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneAvatar.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneAvatar.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneAvatar.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneAvatar.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneAvatar.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneAvatar.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneAvatar.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneAvatar.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleteded!")
        })
        .addCase(deleteOneAvatar.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTag.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editTag.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editTag.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default avatarSlice.reducer;