import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchAllNotifications = createAsyncThunk(
  "/ebubble/notification/fetchAllNotifications",
  async () => {
    const response = await api.get(`/api/english_bubble/notification/get_all`);
    return response.data;
  }
);

export const deleteOneNotification = createAsyncThunk(
    "/ebubble/notification/deleteOneNotification",
    async (id) => {
      const response = await api.delete(`/api/english_bubble/notification/delete_one/${id}`);
      return response.data;
    }
);

export const pushNotification = createAsyncThunk(
  "/ebubble/notification/pushNotification",
  async (data) => {
    console.log(data);
    const response = await api.post(`/api/english_bubble/notification/push_notification`, data);
    return response.data;
  }
);

const ebubbleNotificationSlice = createSlice({
  name: "ebubbleNotification",
  initialState: { notifications: [], status: "idle", error: null, loading: false, version: 0.0 },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllNotifications.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllNotifications.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.notifications = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllNotifications.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(pushNotification.pending, (state) => {
            state.status = "loading";
        })
        .addCase(pushNotification.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully Sent!")
            state.status = "idle";
        })
        .addCase(pushNotification.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneNotification.pending, (state) => {
            state.status = "loading";
        })
        .addCase(deleteOneNotification.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneNotification.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })

  },
});

export default ebubbleNotificationSlice.reducer;
