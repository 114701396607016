import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllSounds = createAsyncThunk(
  "/sound/fetchAllSounds",
  async () => {
    const response = await api.get(`/api/bubble/temp/sound/get_all_sound`);
    return response.data;
  }
);

export const editOneSound = createAsyncThunk(
  "/sound/editOneSound",
  async (data) => {
    const sid = data.sid;
    const response = await api.post(`/api/bubble/temp/sound/edit_sound/${sid}`, data);
    return response.data;
  }
);


const bubbleSoundSlice = createSlice({
  name: "soundField",
  initialState: { sounds: [], status: "idle", error: null, message: "", loading: false, version: 0.0, unfilled: false, maxSound: 0 },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllSounds.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllSounds.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.sounds = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllSounds.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneSound.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneSound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneSound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export const {} = bubbleSoundSlice.actions;

export default bubbleSoundSlice.reducer;
