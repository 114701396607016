import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchPaymentHistories = createAsyncThunk('/payment/fetch', async (data) => {
    const response = await api.post(`/api/payment/get_page`, data);
    return response.data;
});

export const deleteOnePaymentHistory = createAsyncThunk('/payment/deleteOnePaymentHistory', async (id) => {
    const response = await api.delete(`/api/payment/delete_one/${id}`);
    return response.data;
});

const paymentSlice = createSlice({
  name: "payment",
  initialState: { histories:[],  status: "idle", error: null, loading: false, count: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchPaymentHistories.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchPaymentHistories.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.count = action.payload.count;
            state.histories = action.payload.histories;
        })
        .addCase(fetchPaymentHistories.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOnePaymentHistory.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOnePaymentHistory.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOnePaymentHistory.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default paymentSlice.reducer;