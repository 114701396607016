import axios from "axios";
import { serverURL } from "./config";
import { logoutSuccess } from "../features/authSlice";

export const api = axios.create({
  baseURL: serverURL // Replace with your server URL
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    if (token) {
      config.headers.Authorization = `${token}`; // Add the token to the Authorization header
    }

    return config;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logoutSuccess(); 
    }
    return Promise.reject(error);
  }
);