import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {Popconfirm, Typography, Space, Table, Button, Modal, Divider, ColorPicker, Badge, message} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { fetchAllRecitation, addOneRecitation, editOneRecitation, deleteOneRecitation } from "../../../../features/quran/recitation/recitationSlice";
import { api } from "../../../../config/api";

import { successNotification, errorNotification } from "../../../../utilities/Notification";
import { serverURL } from "../../../../config/config";

import './Recitation.css';

const Recitation = () => {
    const dispatch = useDispatch();
    const topicInfo = useSelector(state => state.quranRecitation);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = (content) => {
        messageApi.open({
          type: 'warning',
          content: content,
        });
    };

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20'],
    });
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [selectedTopicInfo, setSelectedTopicInfo] = useState({no: 0, suraid: 0, ayahid: 0, reciterid: 0, id: "", sound: ""});
    const [uploadedSoundUrl, setUploadedSoundUrl] = useState("");
    const [uploadedSoundFileName, setUploadedSoundFileName] = useState("");
    const [selectedSound, setSelectedSound] = useState("");
    const [soundUploadPercent, setSoundUploadPercent] = useState(0);

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };
    const audioRef = useRef(null);


    useEffect(() => {
        if(topicInfo.status === "idle") {
          dispatch(fetchAllRecitation());
        }
      }, [dispatch, topicInfo]);

      let maxNo = 0;

    //Table operation

    const handleAddTopic = () => {
        setSelectedTopicInfo({ no: maxNo + 1, suraid:0, ayahid: 0, reciterid: 0, id: "", sound:""});
        setUploadedSoundUrl("");
        setIsAddModalOpen(true);
    }

    const handleEdit = (record) => {
        console.log("record_sound "+ record.sound)
        setUploadedSoundFileName(record.sound);
        setUploadedSoundUrl(serverURL + "/public/quran/recitation/" + record.sound);
        setSoundUploadPercent(0);
        setSelectedTopicInfo({
            no: record.no,
            sura: record.suraid,
            ayahid: record.ayahid,
            reciterid: record.reciterid,
            id: record._id,
            sound: record.sound,
        });
        setIsEditModalOpen(true);
    }

    const deleteTopic = (record) => {
        dispatch(deleteOneRecitation({id: record._id, no: record.no}));
    }

    //modal operation

    const handleCreate = () => {
        if(selectedTopicInfo.ayahid === 0 || selectedTopicInfo.reciterid === 0) {
            warning("Please input correct ayah_id or reciter_id.");
            return;
        }
        const info = {
            id : selectedTopicInfo.id,
            no: selectedTopicInfo.no, 
            suraid: selectedTopicInfo.suraid,
            ayahid: selectedTopicInfo.ayahid, 
            reciterid: selectedTopicInfo.reciterid, 
            sound : uploadedSoundFileName
        }
        console.log("selectedTopicInfo sound "+ info.sound);
        console.log("selectedTopicInfo reciterid "+ info.reciterid);
        dispatch(addOneRecitation(info));
        setIsAddModalOpen(false);
    }

    const isAudioFile = (file) => {
        return file && file.type.startsWith('audio/');
    }

    const handleSoundUpload = (e) => {
        e.preventDefault();
        if(!isAudioFile(selectedSound[0])) {
            message.error("Not an audio file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedSound[0]);
        // const fileNameID = lessonInfo.round + "-" + selectedProblemIndex + "-" + selectedBubbleIndex;

        api.post(`/api/quran/temp/recitation/sound_upload`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setSoundUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("success", "Successfully audio uploaded");
                const soundUrl = serverURL + '/public/quran/recitation/' + res.data.soundFileName;
                setUploadedSoundFileName(res.data.soundFileName);
                setUploadedSoundUrl(soundUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedSound = (e) => {
        setSelectedSound(e.target.files);
    }

    const handleSave = () => {
        const info = {
            id : selectedTopicInfo.id,
            no: selectedTopicInfo.no, 
            suraid: selectedTopicInfo.suraid,
            ayahid: selectedTopicInfo.ayahid, 
            reciterid: selectedTopicInfo.reciterid, 
            sound : selectedTopicInfo.sound
        }
        dispatch(editOneRecitation(info));
        setIsEditModalOpen(false);
    }

    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
    };

    const getReciterList = () => {
        const response = api.get(`/api/quran/temp/reciters/get_all_recitername`);
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'no',
            key: 'no',
            width: '10ch',
            sorter: (a, b) => b.unit - a.unit,
            defaultSortOrder: 'descend',
            editable: true,
            ellipsis: true,
            render : (text, record, index) => {
                if(maxNo < text) maxNo = text;
                return text;
            }
        },
        {
            title: 'Sura_id',
            dataIndex: 'suraid',
            key: 'suraid',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Ayah_id',
            dataIndex: 'ayahid',
            key: 'ayahid',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Reciter_Id',
            dataIndex: 'reciterid',
            key: 'reciterid',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        
      
        // {
        //     title: 'Aya',
        //     dataIndex: 'aya',
        //     key: 'name',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     ellipsis: true,
        // },
        {
            title: 'Sound',
            dataIndex: 'sound',
            width: '55%',
            render: (text, record, index) => {
                return (
                    <audio controls style={{width: 300}} src={serverURL + "/public/quran/recitation/" + text}/>
                )
            }
            },
        // {
        //     title: 'Badge Color',
        //     dataIndex: 'badge',
        //     key: 'badge',
        //     width: "12%",
        //     render: (text, record, index) => (<Badge color={text} />)
                
        // },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '15ch',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteTopic(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return(
        <>
            {contextHolder}
            <Button type="primary" size='middle' onClick={() => handleAddTopic()} className = "addTopic"> Add </Button>
            <Table
                loading={topicInfo.loading}
                bordered
                dataSource={topicInfo.topics}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Recitation</h2>} open={isAddModal} onOk={handleCreate} onCancel={handleCancel} okText="Create">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                        label="No"
                        sx={{ m: 1, width: '8ch' }}
                        value = {selectedTopicInfo.no}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, no: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                        disabled
                    />
                     <TextField
                        label="Sura_id"
                        sx={{ m: 1, width: '13ch' }}
                        value = {selectedTopicInfo.suraid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, suraid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Ayah_id"
                        sx={{ m: 1, width: '13ch' }}
                        value = {selectedTopicInfo.ayahid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, ayahid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                  
                        label="Reciter_Id"
                        sx={{ m: 1, width: '13ch' }}
                        value = {selectedTopicInfo.reciterid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, reciterid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    {/* <FormControl variant="standard" sx={{ m: 1, minWidth: '50%' }}>
                        <InputLabel id="demo-simple-select-standard-label">Reciter</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="reciter id"
                            style= {{width:"100%"}}
                            value={selectedTopicInfo.reciterid}
                            onChange={(e) => setSelectedTopicInfo(prev => {return {...prev, reciterid: e.target.value}})}
                        >
                            <MenuItem value={1}>One</MenuItem>
                            <MenuItem value={2}>Two</MenuItem>
                            <MenuItem value={3}>Three</MenuItem>
                        </Select>
                    </FormControl> */}
                </Box>
                <form onSubmit={(e)=>handleSoundUpload(e)}>
                    <label for="file-input">Sound :&nbsp;</label>
                    <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedSound(e)} />
                    <button className="upload-button" type="submit">Upload</button>
                </form>

                <audio ref={audioRef} controls src={uploadedSoundUrl} />
                <Box sx={{ width: '95%' }}>
                    <LinearProgress variant="determinate" value={soundUploadPercent} />
                </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>Recitation {selectedTopicInfo.no}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                        label="Reciter_Id"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedTopicInfo.reciterid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, reciterid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Sura_id"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedTopicInfo.suraid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, suraid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Ayah_id"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedTopicInfo.ayahid}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, ayahid: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <form onSubmit={(e)=>handleSoundUpload(e)}>
                        <label for="file-input">Sound :&nbsp;</label>
                        <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedSound(e)} />
                        <button className="upload-button" type="submit">Upload</button>
                    </form>

                    <audio ref={audioRef} controls src={uploadedSoundUrl} />
                    <Box sx={{ width: '95%' }}>
                        <LinearProgress variant="determinate" value={soundUploadPercent} />
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default Recitation;