import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchVersion } from "../../../../features/piano/versionSlice";

import "./Version.css"

const Version = () => {
    const dispatch = useDispatch();
    const versionInfo = useSelector((state) => state.version);

    useEffect(() => {
        if(versionInfo.status === "idle") {
            dispatch(fetchVersion());
        }
    }, [dispatch, versionInfo]);

    return(
        <>
            <h1>Version</h1>
        </>
    )
}

export default Version;