import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {Popconfirm, Typography, Space, Table, Button, Modal, Divider, message} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { fetchAllTopics, addOneTopic, editOneTopic, editOneDetailTopic, deleteOneTopic, resetOneDetailTopic } from "../../../../features/quran/juz/juzSlice";

import './Juz.css';

const JuzHizb = () => {
    const dispatch = useDispatch();
    const topicInfo = useSelector(state => state.quranJuzHizb);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = (content) => {
        messageApi.open({
          type: 'warning',
          content: content,
        });
    };

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20'],
    });
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [isEditDetailModal, setIsEditDetailModalOpen] = useState(false);
    const [selectedTopicInfo, setSelectedTopicInfo] = useState({no: 0, juz: 0, id: ""});
    const [selectedDetailInfo, setSelectedDetailInfo] = useState({hizb: 0, startpage: 0, endpage: 0, id: ""})

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    useEffect(() => {
        if(topicInfo.status === "idle") {
          dispatch(fetchAllTopics());
        }
      }, [dispatch, topicInfo]);

      let maxNo = 0;

    //Table operation

    const handleAddTopic = () => {
        setSelectedTopicInfo({ no: maxNo + 1, juz:0, hizb: 0, startpage: 0, endpage: 0, id: ""});
        setIsAddModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedTopicInfo({
            no: record.no,
            juz: record.juz, 
            id: record._id
        });
        setIsEditModalOpen(true);
    }

    const handledetailEdit = (record, index) => {
        console.log("record")
        setSelectedDetailInfo({
            hizb: record.hizb,
            startpage: record.startpage,
            endpage: record.endpage,
            id: record._id
        })
        setIsEditDetailModalOpen(true);
    }

    const deleteTopic = (record) => {
        dispatch(deleteOneTopic({id: record._id, no: record.no}));
    }

    const handledetailDelete = (record, index) => {
        dispatch(resetOneDetailTopic({id: record._id, startpage: record.startpage, endpage: record.endpage}));
    }

    //modal operation

    const handleCreate = () => {
        if(selectedTopicInfo.juz === "") {
            warning("Please input Juz title.");
            return;
        }
        dispatch(addOneTopic(selectedTopicInfo));
        setIsAddModalOpen(false);
    }

    const handleSave = () => {
        dispatch(editOneTopic(selectedTopicInfo));
        setIsEditModalOpen(false);
    }

    const handleDetailSave = () => {
        dispatch(editOneDetailTopic(selectedDetailInfo));
        setIsEditDetailModalOpen(false);
    }
    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
        setIsEditDetailModalOpen(false);
    };

    const pageRangeColumns = [
        {
            title: 'Hizb_Title',
            dataIndex: 'hizb',
            key: 'hizb',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'start_page_id',
            dataIndex: 'startpage',
            key: 'startpage',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        // {
        //     title: 'endpage',
        //     dataIndex: 'endpage',
        //     key: 'name',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     ellipsis: true,
        // },
        {
            title: 'end_page_id',
            dataIndex: 'endpage',
            key: 'endpage',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Operation',
            dataIndex: 'operation1',
            width: '15%',
            render: (_, record, index) => {
                return (
                    <Space size="large">
                    <Typography.Link onClick={() => handledetailEdit(record, index + 1)}>
                        <Tooltip title="Edit">
                            <Icon sx={{fontSize: 16}}>edit</Icon>
                        </Tooltip>
                    </Typography.Link>
                    <Popconfirm title="Sure to reset?" onConfirm={() => handledetailDelete(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Reset">
                                <Icon sx={{fontSize: 20}}>refresh</Icon>
                            </Tooltip>
                        </Typography.Link>
                    </Popconfirm>
                    </Space>
                );
            },
        },
    ]
    
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '10%',
            sorter: (a, b) => b.unit - a.unit,
            defaultSortOrder: 'descend',
            editable: true,
            ellipsis: true,
            render : (text, record, index) => {
                if(maxNo < text) maxNo = text;
                return text;
            }
        },
        {
            title: 'Juz_Title',
            dataIndex: 'juz',
            key: 'juz',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Page_Range',
            key: 'innerTable',
            width: '50%',
            render: (text, record, index) => {
                const i = topicInfo.topics.findIndex(topics => topics === record);
                return (<Table
                    dataSource={topicInfo.topics[i].pagerange}
                    columns = {pageRangeColumns}
                    pagination={false}
                />)
            }
        }, 

        // {
        //     title: 'Badge Color',
        //     dataIndex: 'badge',
        //     key: 'badge',
        //     width: "12%",
        //     render: (text, record, index) => (<Badge color={text} />)
                
        // },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: '10%',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteTopic(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return(
        <>
            {contextHolder}
            <Button type="primary" size='middle' onClick={() => handleAddTopic()} className = "addTopic"> Add List </Button>
            <Table
                loading={topicInfo.loading}
                bordered
                dataSource={topicInfo.topics}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Juz_Hizb</h2>} open={isAddModal} onOk={handleCreate} onCancel={handleCancel} okText="Create">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="No"
                    sx={{ m: 1, width: '12ch' }}
                    value = {selectedTopicInfo.no}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, no: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    disabled
                    />
                    <TextField
                        label="juz"
                        sx={{ m: 1, width: '20ch' }}
                        value = {selectedTopicInfo.juz}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, juz: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <Divider/>
                </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>Juz {selectedTopicInfo.juz}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                        label="juz"
                        sx={{ m: 1, width: '20ch' }}
                        value = {selectedTopicInfo.juz}
                        onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, juz: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}></h2>} open={isEditDetailModal} onOk={handleDetailSave} onCancel={handleCancel} okText="Save">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                        label="hizb"
                        sx={{ m: 1, width: '20ch' }}
                        value = {selectedDetailInfo.hizb}
                        onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, hizb: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="start_page"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedDetailInfo.startpage}
                        onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, startpage: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                    <TextField
                        label="end_page"
                        sx={{ m: 1, width: '15ch' }}
                        value = {selectedDetailInfo.endpage}
                        onChange = {(e) => setSelectedDetailInfo({...selectedDetailInfo, endpage: e.target.value})}
                        InputProps={{
                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                        }}
                    />
                </Box>
            </Modal>
        </>
    );
}

export default JuzHizb;