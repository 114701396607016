import React, { useRef, useState, useEffect } from 'react';
import { Button, Space, Table, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

import { useDispatch, useSelector } from 'react-redux';
import { fetchScores } from '../../../../features/unused/scoreSlice';

import "./Score.css";

const Score = () => {

    const dispatch = useDispatch();
    
    const scoreField = useSelector((state) => state.score);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {
            userName: "",
            email: ""
        },
        sorter: {
            field: null,
            order: null
        }
    });
    const searchInput = useRef(null);

    useEffect(() => {
        if(scoreField.status === "idle") {
            dispatch(fetchScores(tableParams));
        }
    }, [dispatch, scoreField]);
    
    useEffect(() => {
        dispatch(fetchScores(tableParams));
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        const { order, field } = sorter;
        setTableParams(prevState => ({ 
            ...prevState,
            pagination,
            sorter: {
                ...prevState.sorter,
                field: field,
                order: order
            }
        }))
    };

    //For search function in antd Table

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        setTableParams(prevState => ({ 
            ...prevState,
            filters: {
                ...prevState.filters,
                [dataIndex]: selectedKeys[0]
            }
        }))
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
        <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
            marginBottom: 8,
            display: 'block',
        }}
        />
        <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
        </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    const columns = [
        {
          title: 'No',
          key: 'No',
          width: '5%',
          render: (text, record, index) => {
            return (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1;
          }
        },
        {
          title: 'Name',
          dataIndex: 'userName',
          key: 'userName',
          width: '12%',
          textWrap: 'word-break',
          ellipsis: true,
          ...getColumnSearchProps('userName'),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '23%',
          textWrap: 'word-break',
          ellipsis: true,
          ...getColumnSearchProps('email'),
        },
        {
          title: 'Test1',
          dataIndex: 'test1',
          key: 'test1',
          width: '10%',
          textWrap: 'word-break',
          sorter: true,
          ellipsis: true,
        },
        {
            title: 'Test2',
            dataIndex: 'test2',
            key: 'test2',
            width: '10%',
            textWrap: 'word-break',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Test3',
            dataIndex: 'test3',
            key: 'test3',
            width: '10%',
            textWrap: 'word-break',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Test4',
            dataIndex: 'test4',
            key: 'test4',
            width: '10%',
            textWrap: 'word-break',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Test5',
            dataIndex: 'test5',
            key: 'test5',
            width: '10%',
            textWrap: 'word-break',
            sorter: true,
            ellipsis: true,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            width: '10%',
            textWrap: 'word-break',
            sorter: true,
            ellipsis: true,
        },
    ];

    return(
        <>
            <Table
                className="scoreTable"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={scoreField.scores}
                pagination={{...tableParams.pagination, total: scoreField.amount, showSizeChanger: true,}}
                loading={scoreField.loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default Score;