import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../../utilities/Notification";
import { api } from "../../config/api";

export const fetchVersion = createAsyncThunk('/version/fetchVersion', async () => {
  const response = await api.get(`/api/version/admin/get_all_version`);
  return response.data;
});

export const fetchTestRounds = createAsyncThunk('/version/fetch_test_rounds', async () => {

  const response = await api.get(`/api/version/admin/get_test_rounds`);
  return response.data;
})

export const editAvatarVersion  = createAsyncThunk(
  "/version/editavatarVersion",
  async (data) => {
    const response = await api.post(`/api/version/edit_avatar_version`, data);
    return response.data;
  }
);

export const editBackgroundVersion  = createAsyncThunk(
  "/version/editbackgroundVersion",
  async (data) => {
    const response = await api.post(`/api/version/edit_background_version`, data);
    return response.data;
  }
);

export const editPracticeVersion  = createAsyncThunk(
  "/version/editpracticeVersion",
  async (data) => {
    const response = await api.post(`/api/version/edit_practice_version`, data);
    return response.data;
  }
);

export const editTest1Version  = createAsyncThunk(
  "/version/editTest1Version",
  async (data) => {
    const response = await api.post(`/api/version/edit_test1_version`, data);
    return response.data;
  }
);

export const editTest2Version  = createAsyncThunk(
  "/version/editTest2Version",
  async (data) => {
    const response = await api.post(`/api/version/edit_test2_version`, data);
    return response.data;
  }
);

export const editTest3Version  = createAsyncThunk(
  "/version/editTest3Version",
  async (data) => {
    const response = await api.post(`/api/version/edit_test3_version`, data);
    return response.data;
  }
);

export const editTest4Version  = createAsyncThunk(
  "/version/editTest4Version",
  async (data) => {
    const response = await api.post(`/api/version/edit_test4_version`, data);
    return response.data;
  }
);

export const editTest5Version  = createAsyncThunk(
  "/version/editTest5Version",
  async (data) => {
    const response = await api.post(`/api/version/edit_test5_version`, data);
    return response.data;
  }
);

const versionSlice = createSlice({
  name: "version",
  initialState: {versions: [], status: "idle", practiceVersion: 0.0, test1Version: 0.0, test2Version: 0.0, test3Version: 0.0, test4Version: 0.0, test5Version: 0.0, avatarVersion: 0.0, backgroundVersion: 0.0, testRounds: [],},
  reducers: {
    setVersionStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchVersion.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.versions = action.payload;
          state.practiceVersion = action.payload[0].version;
          state.test1Version = action.payload[1].version;
          state.test2Version = action.payload[2].version;
          state.test3Version = action.payload[3].version;
          state.test4Version = action.payload[4].version;
          state.test5Version = action.payload[5].version;
          state.avatarVersion = action.payload[6].version;
          state.backgroundVersion = action.payload[7].version;
        })
        .addCase(fetchVersion.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
        })
        .addCase(fetchTestRounds.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchTestRounds.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.versions = action.payload;
          state.testRounds.push(action.payload[0].test1);
          state.testRounds.push(action.payload[1].test2);
          state.testRounds.push(action.payload[2].test3);
          state.testRounds.push(action.payload[3].test4);
          state.testRounds.push(action.payload[4].test5);
        })
        .addCase(fetchTestRounds.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
        })
        .addCase(editAvatarVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editAvatarVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editAvatarVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editBackgroundVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editBackgroundVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editBackgroundVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editPracticeVersion.pending, (state) => {
            state.status = "loading";
        })
        .addCase(editPracticeVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editPracticeVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTest1Version.pending, (state) => {
            state.status = "loading";
        })
        .addCase(editTest1Version.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editTest1Version.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTest2Version.pending, (state) => {
            state.status = "loading";
        })
        .addCase(editTest2Version.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editTest2Version.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTest3Version.pending, (state) => {
            state.status = "loading";
        })
        .addCase(editTest3Version.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editTest3Version.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTest4Version.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editTest4Version.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editTest4Version.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTest5Version.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editTest5Version.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editTest5Version.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export const {setVersionStatus} = versionSlice.actions;
export default versionSlice.reducer;