import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { errorNotification } from "../../../utilities/Notification";

export const fetchBubblePayInfo= createAsyncThunk('/ebubble/pay/info/fetch', async () => {
    const response = await api.get(`/api/english_bubble/pay_info/get_all`);
    return response.data;
});

export const editBubbleOncePayAmount = createAsyncThunk('/ebubble/pay/info/edit', async (data) => {
    const response = await api.post(`/api/english_bubble/pay_info/edit_once_pay_quantity`, data);
    return response.data;
})

const ebubblePayInfoSlice = createSlice({
  name: "ebubblePayInfo",
  initialState: { status: "idle", error: null, loading: false, total_amount: 0, once_amount: 0 },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBubblePayInfo.pending, (state) => {
          state.status = "loading";
          state.loading = true;
      })
      .addCase(fetchBubblePayInfo.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.loading = false;
          state.total_amount = action.payload[0].amount;
          state.once_amount = action.payload[1].amount;
      })
      .addCase(fetchBubblePayInfo.rejected, (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(editBubbleOncePayAmount.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(editBubbleOncePayAmount.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.loading = false;
          state.once_amount = action.payload.amount;
      })
      .addCase(editBubbleOncePayAmount.rejected, (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
  },
});

export default ebubblePayInfoSlice.reducer;