import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import { Button, Modal, Divider, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { fetchBubblePayInfo, editBubbleOncePayAmount } from '../../../../../features/ebubble/payInfo/ebubblePayInfo';

import './EBubblePayInfo.css';

const EBubblePayInfo = () => {

  const dispatch = useDispatch();

  const bubblePayInfo = useSelector(state => state.ebubblePayInfo);

  const quantityRef = useRef(null);

  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    dispatch(fetchBubblePayInfo());
  }, []);

  const checkValidity = (value) => {
    if(isNaN(value)) {
      message.error("Not a number!", 2);
      return false;
    }
    if(value < 0.5) {
      message.error("The amount must be bigger than $0.5", 3);
      return false;
    }
    return true;
  }

  const handleSave = () => {
    if(!checkValidity(quantityRef.current.value)) {
      return;
    }
    dispatch(editBubbleOncePayAmount({amount: quantityRef.current.value}));
    setIsModal(false);
  }

  return (
    <>
      <TextField
        label="Payment Quantity"
        id="outlined-size-small"
        size="small"
        value={bubblePayInfo.once_amount}
        className="payment-quantity"
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">
                        {bubblePayInfo.loading ? 
                          <Spin
                            indicator={
                              <LoadingOutlined
                                style={{
                                  fontSize: 24,
                                }}
                                spin
                              />
                            }
                            /> : <></>
                          }
                          </InputAdornment>
        }}
      />
      <Button type="primary" size='middle'  className="updateButton" onClick={() => setIsModal(true)}> Change </Button>

      <span style = {{float: 'right', fontSize: 20, fontWeight: 'bold'}} className="total-payment">Total Payment : ${bubblePayInfo.total_amount}</span>

      <Modal title={<h4>Payment Quantity Edit</h4>} open={isModal} onCancel={() => setIsModal(false)} onOk={handleSave} width={350} okText="Save">
        <Divider/>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <TextField
              label="Payment Quantity"
              sx={{ m: 1, width: '24ch' }}
              inputRef={quantityRef}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Box>
        <Divider/>
      </Modal>
    </>
  )
}

export default EBubblePayInfo;