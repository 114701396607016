import React, {useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchOneRound, deleteOneProblem, editOneStep, editOneBlackStep, deleteOneStep, addOneStep, addOneProblem, editOneProblem } from "../../../../../features/piano/lesson/practiceRoundSlice";
import { setPracticeFieldStatus } from "../../../../../features/piano/lesson/practiceSlice";
import { api } from "../../../../../config/api";

import { Popconfirm, Table, Typography, Space, Button, Image, Modal, Divider, Col, Row, message } from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { successNotification, errorNotification } from "../../../../../utilities/Notification";

import { serverURL } from "../../../../../config/config";
import "./LessonDetail.css"

const {Title} = Typography;

const LessonDetail = () => {

    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isBlackModalOpen, setIsBlackModalOpen] = useState(false);
    const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);

    const titleFieldRef = useRef(null);

    const [pagination, setPagination] = useState({
        pageSize: 5,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
    });

    const [uploadedLetterImgUrl, setUploadedLetterImgUrl] = useState("");
    const [uploadedKeyboardImgUrl, setUploadedKeyboardImgUrl] = useState("");
    const [uploadedAudioUrl, setUploadedAudioUrl] = useState("");
    const [uploadedLetterImgFileName, setUploadedLetterImgFileName] = useState("");
    const [uploadedKeyboardImgFileName, setUploadedKeyboardImgFileName] = useState("");
    const [uploadedAudioFileName, setUploadedAudioFileName] = useState("");
    const [selectedLetter, setSelectedLetter] = useState("");
    const [selectedLetterImg, setSelectedLetterImg] = useState("");
    const [selectedKeyboardImg, setSelectedKeyboardImg] = useState("");
    const [selectedAudio, setSelectedAudio] = useState("");
    const [selectedStep, setSelectedStep] = useState("");
    // const [selectedStepIndex, setSelectedStepIndex] = useState(0);
    // const [selectedProblemIndex, setSelectedProblemIndex] = useState(0);
    const [selectedProblemInfo, setSelectedProblemInfo] = useState({pid: "", title: ""});
    const [audioUploadPercent, setAudioUploadPercent] = useState(0);
    const [letterImgUploadPercent, setLetterImgUploadPercent] = useState(0);
    const [keyboardImgUploadPercent, setKeyboardImgUploadPercent] = useState(0);

    
    const dispatch = useDispatch();
    const roundInfo = useSelector((state) => state.practiceRound);

    const audioRef = useRef(null);

    useEffect(() => {
        dispatch(fetchOneRound(id));
    }, [])

    useEffect(() => {
        if(roundInfo.status === "idle") {
            dispatch(fetchOneRound(id));
        }
    }, [dispatch, id, roundInfo]);

    useEffect(() => {
        if(titleFieldRef.current) {
          titleFieldRef.current.value = selectedProblemInfo.title;
        }
    }, [isTitleModalOpen])

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    // operation

    const handleProblemAdd = () => {
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(addOneProblem(roundInfo.rid));
    }
    
    const handleProblemDelete = (record) => {
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(deleteOneProblem(record._id));
    }

    const handleLetterEdit = (record, index) => {
        // const problemIndex = roundInfo.problems.findIndex(problem => problem.steps.findIndex(step => step === record) > -1) + 1;
        // setSelectedProblemIndex(problemIndex);
        // setSelectedStepIndex(index);
        setSelectedLetter(record.letter);
        setUploadedAudioFileName(record.sound);
        setUploadedAudioUrl(serverURL + "/public/uploads/practice/audios/" + record.sound);
        setUploadedKeyboardImgFileName(record.keyboardImg);
        setUploadedKeyboardImgUrl(serverURL + "/public/uploads/practice/images/keyboards/" + record.keyboardImg);
        setUploadedLetterImgFileName(record.letterImg);
        setUploadedLetterImgUrl(serverURL + "/public/uploads/practice/images/letters/" + record.letterImg);
        setSelectedStep(record._id);
        setAudioUploadPercent(0);
        setKeyboardImgUploadPercent(0);
        setLetterImgUploadPercent(0);
        showModal();
    }

    const handleBlackLetterEdit = (record, index) => {
        // const problemIndex = roundInfo.problems.findIndex(problem => problem.steps.findIndex(step => step === record) > -1) + 1;
        // setSelectedProblemIndex(problemIndex);
        // setSelectedStepIndex(index);
        setUploadedAudioFileName(record.sound);
        setUploadedAudioUrl(serverURL + "/public/uploads/practice/audios/black/" + record.sound);
        setUploadedLetterImgFileName(record.letterImg);
        setUploadedLetterImgUrl(serverURL + "/public/uploads/practice/images/black/" + record.letterImg);
        setSelectedStep(record._id);
        setAudioUploadPercent(0);
        setLetterImgUploadPercent(0);
        showBlackModal();
    }

    const handleLetterDelete = (record) => {
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(deleteOneStep(record._id));
    }

    const handleAddStep = (record) => {
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(addOneStep(record._id));
    }

    const handleTitleEdit = (record) => {
        setSelectedProblemInfo({
            pid: record._id,
            title: record.title
        });
        setIsTitleModalOpen(true);
    }

    //modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    const showBlackModal = () => {
        setIsBlackModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
        setIsBlackModalOpen(false);
    }

    //edit title modal
    const handleTitleSave = () => {
        setSelectedProblemInfo(prev => {
            return {...prev, title: titleFieldRef.current.value}
        });
        dispatch(editOneProblem({
            title: titleFieldRef.current.value,
            pid: selectedProblemInfo.pid
        }));
        setIsTitleModalOpen(false);
    }

    //check upload files type
    const isPngFile = (file) => {
        return file && file.type === 'image/png';
      }

    const isAudioFile = (file) => {
        return file && file.type.startsWith('audio/');
    }

    //upload images and audios
    const handleLetterImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedLetterImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedLetterImg[0]);

        api.post(`/api/temp/practice/letter_image_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setLetterImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const letterImgUrl = serverURL + '/public/uploads/practice/images/letters/' + res.data.letterImgFileName;
                setUploadedLetterImgFileName(res.data.letterImgFileName);
                setUploadedLetterImgUrl(letterImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const handleKeyboardImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedKeyboardImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedKeyboardImg[0]);

        api.post(`/api/temp/practice/keyboard_image_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setKeyboardImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const keyboardImgUrl = serverURL + '/public/uploads/practice/images/keyboards/' + res.data.keyboardImgFileName;
                setUploadedKeyboardImgFileName(res.data.keyboardImgFileName);
                setUploadedKeyboardImgUrl(keyboardImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const handleBlackLetterImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedLetterImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedLetterImg[0]);

        api.post(`/api/temp/practice/black_letter_image_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setLetterImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const letterImgUrl = serverURL + '/public/uploads/practice/images/black/' + res.data.letterImgFileName;
                setUploadedLetterImgFileName(res.data.letterImgFileName);
                setUploadedLetterImgUrl(letterImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const handleAudioUpload = (e) => {
        e.preventDefault();
        if(!isAudioFile(selectedAudio[0])) {
            message.error("Not an audio file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedAudio[0]);
        // const fileNameID = roundInfo.round + "-" + selectedProblemIndex + "-" + selectedStepIndex;

        api.post(`/api/temp/practice/audio_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setAudioUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("success", "Successfully audio uploaded");
                const audioUrl = serverURL + '/public/uploads/practice/audios/' + res.data.audioFileName;
                setUploadedAudioFileName(res.data.audioFileName);
                setUploadedAudioUrl(audioUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const handleBlackAudioUpload = (e) => {
        e.preventDefault();
        if(!isAudioFile(selectedAudio[0])) {
            message.error("Not an audio file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedAudio[0]);
        // const fileNameID = roundInfo.round + "-" + selectedProblemIndex + "-" + selectedStepIndex;

        api.post(`/api/temp/practice/black_audio_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setAudioUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("success", "Successfully audio uploaded");
                const audioUrl = serverURL + '/public/uploads/practice/audios/black/' + res.data.audioFileName;
                setUploadedAudioFileName(res.data.audioFileName);
                setUploadedAudioUrl(audioUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedLetterImg = (e) => {
        setSelectedLetterImg(e.target.files);
    }

    const changeSelectedKeyboardImg = (e) => {
        setSelectedKeyboardImg(e.target.files);
    }

    const changeSelectedAudio = (e) => {
        setSelectedAudio(e.target.files);
    }

    const handleWhiteSave = () => {
        const info = {
            sid: selectedStep,
            letter: selectedLetter,
            letterImgFileName: uploadedLetterImgFileName,
            keyboardImgFileName: uploadedKeyboardImgFileName,
            audioFileName: uploadedAudioFileName
        }
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(editOneStep(info));
        hideModal();
    }

    const handleBlackSave = () => {
        const info = {
            sid: selectedStep,
            letterImgFileName: uploadedLetterImgFileName,
            audioFileName: uploadedAudioFileName
        }
        dispatch(setPracticeFieldStatus({status: "idle"}));
        dispatch(editOneBlackStep(info));
        hideModal();
    }

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsTitleModalOpen(false);
        setIsBlackModalOpen(false);
    }

    const letterColumns = [
        {
        title: 'Key', 
        dataIndex: 'key',
        width: '10%',
        render: (text, record, index) => {
           return <><img src={process.env.PUBLIC_URL + '/white.png'} alt="white" style={{width: 20, height:50}}/>&nbsp; {index + 1}</>
        }
        },
        // {
        // title: 'Letter', 
        // dataIndex: 'letter',
        // width: '12%',
        // render: (text, record, index) => {
        //     return <h4>{text}</h4>
        // }
        // },

        {
        title: 'Letter Image',
        dataIndex: 'letterImg',
        width: '20%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/uploads/practice/images/letters/" + text} style={{width: 80, height: 80}} alt="img"/>
        }
        },
        {
        title: 'Key Image',
        dataIndex: 'keyboardImg',
        width: '20%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/uploads/practice/images/keyboards/" + text} style={{width: 80, height: 80}} alt="img"/>
        }
        },
        {
        title: 'Sound',
        dataIndex: 'sound',
        width: '35%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
            return (
                <audio controls style={{width: 300}} src={serverURL + "/public/uploads/practice/audios/" + text}/>
            )
        }
        },
        {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        render: (_, record, index ) => (
            <Space size="large">
                <Typography.Link onClick={() => handleLetterEdit(record, index + 1)}>
                <Tooltip title="Edit White Button">
                        <Icon sx={{fontSize: 16}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleLetterDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete One White Button">
                            <Icon sx={{fontSize: 16}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    const blackLetterColumns = [
        {
        title: 'Key', 
        dataIndex: 'key',
        width: '10%',
        render: (text, record, index) => {
            return <><img src={process.env.PUBLIC_URL + '/black.png'} alt="white" style={{width: 15, height:40}}/>&nbsp; {index + 1}</>
        }
        },
        {
        title: 'Image',
        dataIndex: 'letterImg',
        width: '40%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/uploads/practice/images/black/" + text} style={{width: 80, height: 80}} alt="img"/>
        }
        },
        {
        title: 'Sound',
        dataIndex: 'sound',
        width: '35%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
            return (
                <audio controls style={{width: 300}} src={serverURL + "/public/uploads/practice/audios/black/" + text}/>
            )
        }
        },
        {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        render: (_, record, index ) => (
                <Typography.Link onClick={() => handleBlackLetterEdit(record, index + 1)}>
                    <Tooltip title="Edit Black Button">
                        <Icon sx={{fontSize: 16}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>
            ),
        },
    ];

    const columns = [
        {
        title: 'No',
        dataIndex: 'no',
        width: '5%',
        fixed: 'left',
        render: (text, record, index) => {
            const i = roundInfo.problems.findIndex(problem => problem === record);
            return i + 1;
        }
        },
        {
        title: 'KeyNum',
        dataIndex: 'keyNum',
        width: '8%',
        fixed: 'left',
        },
        {
        title: "Title",
        dataIndex: "title",
        width: '11%',
        fixed: 'left',
        },
        {
        title: 'Problem',
        key: 'innerTable',
        width: '65%',
        render: (text, record, index) => { 
            const i = roundInfo.problems.findIndex(problem => problem === record);
            return (
            <>
                <Table
                dataSource={roundInfo.problems[i].steps}
                columns={letterColumns}
                pagination={false}
                className="PracticeDetailInnerTable"
                bordered
                />
                <Table
                dataSource={roundInfo.problems[i].blackSteps}
                columns={blackLetterColumns}
                pagination={false}
                className="PracticeDetailInnerTable"
                bordered
                />
            </>)
        },
        },
        {
        title: 'Operation',
        dataIndex: 'operation',
        fixed: 'right',
        render: (_, record) => (
            <Space size = "middle" >
                {/* <Typography.Link  onClick={() => handleProblemEdit(record)} >
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>*/}

                <Typography.Link  onClick={() => handleAddStep(record)} style={{ color: "blue"}}> 
                    <Tooltip title="Add White Button" >
                        <Icon sx={{fontSize: 18}}>add_circle</Icon>
                    </Tooltip>
                </Typography.Link> 

                <Typography.Link  onClick={() => handleTitleEdit(record)} >
                    <Tooltip title="Edit Title">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>

                <Popconfirm title="Sure to delete?" onConfirm={() => handleProblemDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return (
        <>
            <Title level={2} style={{textAlign: "center"}}>Practice for Lesson {roundInfo.round}</Title>
        
            <Button type="primary" size='middle' onClick={() => handleProblemAdd()} className = "addRound"> Add Problem </Button>
            <Table
                className="MyPracticeDetailTable"
                bordered
                dataSource={roundInfo.problems}
                columns={columns}
                loading={roundInfo.loading}
                pagination={pagination}
                scroll={{ x: '1400px' }}
                onChange={handleTableChange}
            />

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Letter</h2>} open={isModalOpen} onOk={handleWhiteSave} onCancel={handleCancel} okText="Save" width={700}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                {/* <Input addonBefore="Letter:" placeholder="Please enter your letter here." style={{width: "50%"}} onChange= {(e)=>setSelectedLetter(e.target.value)} value={selectedLetter} /> */}
                
                <Row>
                    <Col span={12}>    
                        <form onSubmit={(e)=>handleLetterImgUpload(e)}>
                            <label for="file-input"> Letter :&nbsp;</label>
                            <input type="file" className="file-input upload-img" name="my-letter-image" onChange= {(e)=>changeSelectedLetterImg(e)} />
                            <button className="upload-button" type="submit"> Upload</button>
                        </form>             
                        <Image src={uploadedLetterImgUrl} alt='letter image' style={{width: "100%"}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={letterImgUploadPercent} />
                        </Box>
                    </Col>
                    <Col span={12}>    
                        <form onSubmit={(e)=>handleKeyboardImgUpload(e)}>
                            <label for="file-input">Keyboard :&nbsp;</label>
                            <input type="file" className="file-input upload-img" name="my-keyboard-image" onChange= {(e)=>changeSelectedKeyboardImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <Image src={uploadedKeyboardImgUrl} alt='keyboard image' style={{width: "100%"}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={keyboardImgUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                

                <form onSubmit={(e)=>handleAudioUpload(e)}>
                    <label for="file-input">Sound :&nbsp;</label>
                    <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedAudio(e)} />
                    <button className="upload-button" type="submit">Upload</button>
                </form>

                <audio ref={audioRef} controls src={uploadedAudioUrl} />
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={audioUploadPercent} />
                </Box>
                
                </Space>
            </Modal>

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Image for Black Keyboard</h2>} open={isBlackModalOpen} onOk={handleBlackSave} onCancel={handleCancel} okText="Save" width={700}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleBlackLetterImgUpload(e)}>
                            <label for="file-input"> Image :&nbsp;</label>
                            <input type="file" className="file-input upload-img" name="my-letter-image" onChange= {(e)=>changeSelectedLetterImg(e)} />
                            <button className="upload-button" type="submit"> Upload</button>
                        </form>             
                        <Image src={uploadedLetterImgUrl} alt='letter image' style={{width: "100%"}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={letterImgUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                

                <form onSubmit={(e)=>handleBlackAudioUpload(e)}>
                    <label for="file-input">Sound :&nbsp;</label>
                    <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedAudio(e)} />
                    <button className="upload-button" type="submit">Upload</button>
                </form>

                <audio ref={audioRef} controls src={uploadedAudioUrl} />
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={audioUploadPercent} />
                </Box>
                
                </Space>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"Title Edit"}</h2>} open={isTitleModalOpen} onOk={handleTitleSave} okText="Save" onCancel={handleCancel}>
              <Divider/>
                <TextField
                  label="Title"
                  sx={{ m: 1, width: '100%' }}
                  inputRef={titleFieldRef}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
            </Modal>
        </>
    )
}

export default LessonDetail;