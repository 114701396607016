import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllRecitation = createAsyncThunk('/fetchRecitation', async (data) => {
  const response = await api.get(`/api/quran/temp/recitation/get_all_recitation`);
  return response.data;
});

export const deleteOneRecitation = createAsyncThunk(
  "/recitation/deleteOneRecitation",
  async (data) => {
    const response = await api.post(`/api/quran/temp/recitation/delete_recitation/${data.id}`, data);
    return response.data;
  }
);

export const addOneRecitation = createAsyncThunk(
  "/recitation/addOneRecitation",
  async (data) => {
    const response = await api.post(`/api/quran/temp/recitation/add_recitation`, data);
    return response.step;
  }
);

export const editOneRecitation = createAsyncThunk(
  "/recitation/editOneRecitation",
  async (data) => {
    const id = data.id;
    const response = await api.post(`/api/quran/temp/recitation/edit_recitation/${id}`, data);
    return response;
  }
);

const recitationSlice = createSlice({
  name: "topic",
  initialState: { topics: [], status: "idle", loading: false, message: ""},
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRecitation.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchAllRecitation.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topics = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllRecitation.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        if(action.error.message === "Request failed with status code 403") {
          state.error = "Your token is expired or invalid! Please try to login again."
        }
        errorNotification("Failed!", state.error);
      })
      .addCase(addOneRecitation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOneRecitation.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully added!")
          state.status = "idle";
      })
      .addCase(addOneRecitation.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(editOneRecitation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editOneRecitation.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully edited!")
          state.status = "idle";
      })
      .addCase(editOneRecitation.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(deleteOneRecitation.pending, (state) => {
          state.status = "loading";
      })
      .addCase(deleteOneRecitation.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully deleted!")
          state.status = "idle";
      })
      .addCase(deleteOneRecitation.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
    },
});

export default recitationSlice.reducer;