import React from "react";

import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Menu, Layout } from 'antd';

import { DesktopOutlined,SnippetsOutlined, ReadOutlined, ProfileOutlined, AndroidOutlined, ApartmentOutlined, DatabaseOutlined, MehOutlined, ShopOutlined, FileImageOutlined, SoundOutlined, CreditCardOutlined, CopyrightOutlined, NotificationOutlined, AudioOutlined } from '@ant-design/icons';
import Typography from "@mui/material/Typography";
import AdbIcon from "@mui/icons-material/Adb";

import { setAuth } from "../../features/authSlice";
import { setCollapsed } from "../../features/themeSlice";

import "./Sider.css"

const { Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
}
const items = [
    getItem("", "Main", 
        <div>
           <AdbIcon sx={{ display: { xs: "none", xl: "flex" }, mr: 1 }} />
            <Typography
                variant="h6"
                noWrap
                sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize:32,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                }}
            >
            <Link to="/home">               
                ADMIN
            </Link>
            </Typography>
        </div>
    ),
    getItem('Admin List', 'Admin', <DesktopOutlined/>),
    getItem("Arabic Piano", "Piano", null, [    
        getItem('User List', 'Piano User', <AndroidOutlined/>),
        getItem('Payment', 'Piano Payment', <CreditCardOutlined/>),
        // getItem("Notification", "Piano Notification", <NotificationOutlined />, null),
        getItem("Shop", "Shop", <ShopOutlined/>, [
            getItem('Avatar', 'Avatar', <MehOutlined/>),
            getItem('Coin', 'Coin', <CopyrightOutlined/>)
        ]),
        getItem("Background", "Background", <FileImageOutlined/> ),
        getItem("Topic", "Topic", <ApartmentOutlined/>, null),
        getItem("Unit", "Unit", <DatabaseOutlined/>, null),
        getItem("Lesson", "Lesson", <ReadOutlined />, null),
        getItem('Test', 'Test', <ProfileOutlined />, [
            getItem('Test1', 'Test1', <SnippetsOutlined/>),
            getItem('Test2', 'Test2', <SnippetsOutlined/>),
            getItem('Test3', 'Test3', <SnippetsOutlined/>),
            getItem('Test4', 'Test4', <SnippetsOutlined/>),
            getItem('Test5', 'Test5', <SnippetsOutlined/>),
        ]),
    ], 'group'),
    getItem("Arabic Bubble", "Bubble", null, [
        getItem('User List', 'Bubble User', <AndroidOutlined/>),
        getItem('Payment', 'Bubble Payment', <CreditCardOutlined/>),
        // getItem("Notification", "Bubble Notification", <NotificationOutlined />, null),
        getItem("Background", "Bubble Background", <FileImageOutlined/> ),
        getItem("BG-Topic", "Bubble Topic Background", <FileImageOutlined/> ),
        getItem("Lesson", "Bubble Lesson", <ReadOutlined />, null),
        getItem("Sound", "Bubble Sound", <SoundOutlined />, null),
    ], 'group'),
    getItem("English Bubble", "EBubble", null, [
        getItem('User List', 'EBubble User', <AndroidOutlined/>),
        getItem('Payment', 'EBubble Payment', <CreditCardOutlined/>),
        // getItem("Notification", "EBubble Notification", <NotificationOutlined />, null),
        getItem("Background", "EBubble Background", <FileImageOutlined/> ),
        getItem("BG-Topic", "EBubble Topic Background", <FileImageOutlined/> ),
        getItem("Lesson", "EBubble Lesson", <ReadOutlined />, null),
        getItem("Sound", "EBubble Sound", <SoundOutlined />, null),
    ], 'group'),
    getItem("AI Quran", "Quran", null, [
        getItem('User List', "Quran User", <AndroidOutlined/>),
        getItem("Payment", "Quran Payment", <CreditCardOutlined/>),
        getItem("Reciter", "Quran Reciter", <SoundOutlined/>, null),
        getItem("Recitation", "Quran Recitation", <AudioOutlined />, null),
        getItem("Text & Tafseer", "Quran Text", <ReadOutlined/>, null),
        getItem('Download', 'Quran Download', <ProfileOutlined />, [
            getItem('Sura-Aya', 'Sura-Aya', <SnippetsOutlined/>),
            getItem('Juz-Hizb', 'Juz-Hizb', <SnippetsOutlined/>),
            getItem('Page', 'Page', <SnippetsOutlined/>),
        ]),
    ], 'group'),
];

const MySider = () => {

    const themeInfo = useSelector((state) => state.theme);
    // const [collapsed, setCollapsed] = useState(false);

    const dispatch = useDispatch();

    const onClick = (e) => {
        switch (e.key) {
            case "Main":
                navigate("/home");
                break;
            case "Admin":
                navigate("/home/admin");
                break;
            case "Piano User":
                navigate("/home/arabic/piano/user");
                break;
            case "Piano Payment":
                navigate("/home/arabic/piano/payment");
                break;
            case "Piano Notification":
                navigate("/home/arabic/piano/notification");
                break;
            case "Topic":
                navigate("/home/arabic/piano/topic");
                break;
            case "Unit":
                navigate("/home/arabic/piano/unit");
                break;
            case "Lesson":
                navigate("/home/arabic/piano/lesson");
                break;
            case "User Score":
                navigate("/home/arabic/piano/score");
                break;
            case "Avatar":
                navigate("/home/arabic/piano/shop/avatar");
                break;
            case "Coin":
                navigate("/home/arabic/piano/shop/coin");
                break;
            case "Background":
                navigate("/home/arabic/piano/background");
                break;
            case "Test1":
                navigate("/home/arabic/piano/test1");
                break;    
            case "Test2":
                navigate("/home/arabic/piano/test2");
                break;  
            case "Test3":
                navigate("/home/arabic/piano/test3");
                break;
            case "Test4":
                navigate("/home/arabic/piano/test4");
                break;
            case "Test5":
                navigate("/home/arabic/piano/test5");
                break;
            case "Version":
                navigate("/home/arabic/piano/version");
                break;
            case "Video":
                navigate("/home/arabic/piano/video");
                break;

            case "Bubble User":
                navigate("/home/arabic/bubble/user");
                break;
            case "Bubble Payment":
                navigate("/home/arabic/bubble/payment");
                break;
            case "Bubble Notification":
                navigate("/home/arabic/bubble/notification")
                break;
            case "Bubble Background":
                navigate("/home/arabic/bubble/background");
                break;
            case "Bubble Topic Background":
                navigate("/home/arabic/bubble/topic-background");
                break;
            case "Bubble Lesson":
                navigate("/home/arabic/bubble/lesson");
                break;
            case "Bubble Sound":
                navigate("/home/arabic/bubble/sound");
                break;

            case "EBubble User":
                navigate("/home/english/bubble/user");
                break;
            case "EBubble Payment":
                navigate("/home/english/bubble/payment");
                break;
            case "EBubble Notification":
                navigate("/home/english/bubble/notification");
                break;
            case "EBubble Topic Background":
                navigate("/home/english/bubble/topic-background");
                break;
            case "EBubble Background":
                navigate("/home/english/bubble/background");
                break;
            case "EBubble Lesson":
                navigate("/home/english/bubble/lesson");
                break;
            case "EBubble Sound":
                navigate("/home/english/bubble/sound");
                break;

            case "Quran User":
                navigate("/home/arabic/quran/user");
                break;
            case "Quran Payment":
                navigate("/home/arabic/quran/payment");
                break;
            case "Quran Reciter": 
                navigate("/home/arabic/quran/reciters");
                break;
            case "Quran Recitation":
                navigate("/home/arabic/quran/recitation");
                break;
            case "Quran Text":
                navigate("/home/arabic/quran/text_tafseer");
                break;
            case "Sura-Aya":
                navigate("/home/arabic/quran/sura_aya");
                break;
            case "Juz-Hizb":
                navigate("/home/arabic/quran/juz_hizb");   
                break;
            case "Page":
                navigate("/home/arabic/quran/page");
                break;
            case "Logout":
                handleLogout();
                break;    
            default:
                navigate("/home");
                break;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(setAuth({
            user: null,
            login: false
        }));
        navigate("/login");
    }

    const navigate = useNavigate();

    return(
        <Sider collapsible collapsed={themeInfo.collapsed} onCollapse={(value) => dispatch(setCollapsed(value))} theme={themeInfo.themeColor} className = "my_sider"
        style={{overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,}}
        >
            <div className="demo-logo-vertical" />
                <Menu defaultSelectedKeys={['1']} items={items} onClick={onClick} mode={themeInfo.themeMode} theme={themeInfo.themeColor}/>
        </Sider>
    )
}

export default MySider;