import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllLessons = createAsyncThunk(
  "/lesson/fetchAllLessons",
  async () => {
    const response = await api.get(`/api/bubble/temp/lesson/get_all_lesson`);
    return response.data;
  }
);

export const deleteOneLesson = createAsyncThunk(
    "/lesson/deleteOneLesson",
    async (data) => {
      const response = await api.post(`/api/bubble/temp/lesson/delete_one_lesson/${data._id}`, data);
      return response.data;
    }
);

export const addOneLesson = createAsyncThunk(
  "/lesson/addOneLesson",
  async (data) => {
    const response = await api.post(`/api/bubble/temp/lesson/add_lesson`, data);
    return response.data;
  }
);

export const editOneLesson = createAsyncThunk(
  "/lesson/editOneLesson",
  async (data) => {
    const lid = data._id;
    const response = await api.post(`/api/bubble/temp/lesson/edit_lesson/${lid}`, data);
    return response.data;
  }
);


const bubbleLessonSlice = createSlice({
  name: "lessonField",
  initialState: { lessons: [], status: "idle", error: null, message: "", loading: false, version: 0.0, unfilled: false, maxLesson: 0 },
  reducers: {
    setLessonFieldStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setLessonUnfilled: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllLessons.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllLessons.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.lessons = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllLessons.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneLesson.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneLesson.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneLesson.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneLesson.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneLesson.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneLesson.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneLesson.pending, (state) => {
            state.status = "loading";
        })
        .addCase(deleteOneLesson.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneLesson.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })

  },
});

export const {setLessonFieldStatus, setLessonUnfilled} = bubbleLessonSlice.actions;

export default bubbleLessonSlice.reducer;
