import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../../../../../config/api";
import {Popconfirm, Table, Typography, Button, Modal, Divider, message, Space, Image, Col, Row} from 'antd';
import { ExclamationCircleFilled, CopyrightOutlined } from '@ant-design/icons';

import LinearProgress from '@mui/material/LinearProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { fetchAllAvatars, addOneAvatar, editOneAvatar, deleteOneAvatar, editTag } from "../../../../../features/piano/shop/avatar/avatarSlice";
import { editAvatarVersion, fetchVersion } from "../../../../../features/piano/versionSlice";
import { successNotification, errorNotification } from "../../../../../utilities/Notification";
import { serverURL } from "../../../../../config/config";

import './Avatar.css';

const Avatar = () => {
    const dispatch = useDispatch();
    const avatarInfo = useSelector((state) => state.avatar);
    const version = useSelector((state) => state.version);
    const [selectedAvatars, setSelectedAvatars] = useState([]);

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '20'],
    });

    useEffect(() => {
        if(avatarInfo.status === 'idle') {
            dispatch(fetchAllAvatars());
        }
        if(version.status === 'idle') {
            dispatch(fetchVersion());
        }
        if(version.status === 'succeeded') {
            setAvatarCurrentVersion(version.avatarVersion);
            setAvatarUpdatedVersion(parseFloat(version.avatarVersion + 0.1).toFixed(1));
        }
    }, [dispatch, avatarInfo, version]);

    const [avatarCurrentVersion, setAvatarCurrentVersion] = useState(1.0);
    const [avatarUpdatedVersion, setAvatarUpdatedVersion] = useState(1.0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [uploadedAvatarImgUrl, setUploadedAvatarImgUrl] = useState("");
    const [selectedAvatarImg, setSelectedAvatarImg] = useState("");
    const [avatarImgUploadPercent, setAvatarImgUploadPercent] = useState(0);

    const [selectedAvatarInfo, setSelectedAvatarInfo] = useState({coin: 0.0, name: "",type: [], gender: 1, id: ""});
    const [offRate, setOffRate] = useState(0.0);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          const selectedIds = selectedRows.map(row => row._id);
          setSelectedAvatars(selectedIds);
        },
    };

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    const handleChangeVersion = (e) => {
        setAvatarUpdatedVersion(e.target.value);
    }

    //table operation

    const handleAvatarAdd = () => {
        setSelectedAvatarInfo({
            name: "",
            coin: 0.0,
            type:[],
            gender: 1,
            id: ""
        });
        setUploadedAvatarImgUrl("");
        setIsCreateModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedAvatarInfo({
            name: record.name,
            coin: record.coin,
            type: record.type,
            gender: record.gender,
            id: record._id
        });
        setUploadedAvatarImgUrl(serverURL + "/public/uploads/avatar/" + record.name);
        setIsEditModalOpen(true);
    }

    const DeleteAvatar = (record) => {
        dispatch(deleteOneAvatar(record._id));
    }

    //modal operation

    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsCreateModalOpen(false);
    }

    const handleCreate = () => {
        dispatch(addOneAvatar(selectedAvatarInfo));
        setIsCreateModalOpen(false);
    }

    const handleSave = (record) => {
        dispatch(editOneAvatar(selectedAvatarInfo));
        setIsEditModalOpen(false);
    }

    const isPngFile = (file) => {
        return file && file.type === 'image/png';
    }

    const handleAvatarImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedAvatarImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedAvatarImg[0]);

        api.post(`/api/temp/avatar/avatar_image_upload`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setAvatarImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const avatarImgUrl = serverURL + '/public/uploads/avatar/' + res.data.avatarImgFileName;
                setSelectedAvatarInfo(prev => {
                    return {
                    ...prev,
                    name: res.data.avatarImgFileName
                    }
                })
                setUploadedAvatarImgUrl(avatarImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedAvatarImg = (e) => {
        setSelectedAvatarImg(e.target.files);
    }

    const handleVersionUpdate = () => {
        if(avatarUpdatedVersion <= avatarCurrentVersion) {
          message.error("Plesae check the Next Version number", 3);
          return;
        }
        dispatch(editAvatarVersion({version: avatarUpdatedVersion}));
    }

    const handleAddTag = () => {
        if(isNaN(offRate)) {
            message.error("Off-Rate is not a number!", 1.5);
            return;
        }
        if(offRate > 100 || offRate <= 0) {
            message.error("Off-Rate must be between 0 to 100!", 1.5);
            return;
        }
        dispatch(editTag(
            {
                ids: selectedAvatars,
                offRate: offRate
            }
        ));
    }
    const handleDeleteTag = () => {
        dispatch(editTag(
            {
                ids: selectedAvatars,
                offRate: 0
            }
        ));
    }
    
    const updateConfirmModal = () => {
        Modal.confirm({
          title: 'Do you really Want to update version?',
          icon: <ExclamationCircleFilled />,
          width: 500,
          content: (
                <div>
                <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
                <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version. </p>
                <TextField
                    disabled
                    label="Next Version"
                    id="outlined-size-small"
                    size="small"
                    value={avatarUpdatedVersion}
                    className="version"
                />
                </div>
            ),
            onOk() {
                handleVersionUpdate();
            },
            onCancel() {
            },
        });
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            width: '10%',
            ellipsis: true,
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Avatar Image',
            dataIndex: 'name',
            width: '16%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <Image src={serverURL + "/public/uploads/avatar/" + text} style={{height: 80}} alt="img"/>
            }
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            width: '15%',
            ellipsis: true,
            render: (text, record, index) => {
                return (text === 1 ? "Male" : "Female");
            }
        },
        {
            title: 'Coin',
            dataIndex: 'coin',
            width: '15%',
            sorter: (a, b) => b.coin - a.coin,
            ellipsis: true,
            render: (text, record, index) => {
                return <><CopyrightOutlined/> {(text * (100 - record.offRate) / 100.0).toFixed(2)}</>
            }
        },
        {
            title: 'Used',
            dataIndex: 'used',
            sorter: (a, b) => b.used - a.used,
            width: '15%',
            ellipsis: true,
        },
        {
            title: 'Tag',
            dataIndex: 'offRate',
            width: '12%',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <>
                        {text > 0 ? <span className='off-tag'> &nbsp; {text}% Off &nbsp; </span> :  null}
                    </> 
                )
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => DeleteAvatar(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];


    return (
        <>
            <TextField
              disabled
              label="Current Version"
              id="outlined-size-small"
              size="small"
              value={avatarCurrentVersion}
              onChange={handleChangeVersion}
              className="version"
            />
            <TextField
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={avatarUpdatedVersion}
              onChange={handleChangeVersion}
              style={{backgroundColor: "white"}}
              className="version"
            />
            <Button type="primary" size='middle' onClick={updateConfirmModal} className="updateButton"> Update </Button>

            <Button type="primary" size='middle' onClick={() => handleAvatarAdd()} className = "addRound"> Add Avatar </Button>

            <span className="tag-button-group">
                <TextField
                    label="Off Rate"
                    id="outlined-size-small"
                    sx={{width: 150}}
                    value = {offRate}
                    onChange = {(e) => setOffRate(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    className="off-rate"
                    size="small"
                    disabled = {selectedAvatars.length === 0}
                />
                <Button type="primary" title="Add 'Off' badge" size='middle' onClick={() => handleAddTag()} className="tag-button" disabled = {selectedAvatars.length === 0}> Add Tag </Button>
                <Button type="primary" danger title="Delete 'Off' badge" size='middle' onClick={() => handleDeleteTag()} className="tag-button" disabled = {selectedAvatars.length === 0}> Delete Tag </Button>
                
            </span>

            <Table
                bordered
                rowSelection={{
                type: 'checkbox',
                ...rowSelection,
                }}
                dataSource={avatarInfo.avatars}
                columns={columns}
                loading={avatarInfo.loading}
                pagination={pagination}
                scroll={{ x: '100%' }}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            /> 

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Avatar</h2>} open={isCreateModalOpen} onOk={handleCreate} onCancel={handleCancel} okText="Create" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                <TextField
                  label="Coin"
                  sx={{ m: 1, width: '25ch' }}
                  value = {selectedAvatarInfo.coin}
                  onChange = {(e) => setSelectedAvatarInfo(prev => {return {...prev, coin: e.target.vaule}})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><CopyrightOutlined/></InputAdornment>,
                  }}
                />
                    <FormControl sx={{ m: 1, width: '25ch'}}>
                        <InputLabel id="demo-select-small-label">Gender</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="Test Style"
                            style={{width: "100%"}}
                            value={selectedAvatarInfo.gender}
                            onChange={(e) => setSelectedAvatarInfo(prev => {return {...prev, gender: e.target.value}})}
                        >
                            <MenuItem value={1}>Male</MenuItem>
                            <MenuItem value={2}>Female</MenuItem>
                        </Select>
                    </FormControl>
                </Row>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleAvatarImgUpload(e)}>
                            <label for="file-input">Avatar Image:&nbsp;</label>
                            <input type="file" className="file-input" name="my-avatar-image" onChange= {(e)=>changeSelectedAvatarImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <div style={{marginTop:10, marginBottom: 10, textAlign: "center"}}>
                        <Image src={uploadedAvatarImgUrl} alt='avatar image' style={{width: "100%", }}  />
                        </div>
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={avatarImgUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                
                </Space>
            </Modal>

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Avatar</h2>} open={isEditModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                <TextField
                  label="Coin"
                  sx={{ m: 1, width: '25ch' }}
                  value = {selectedAvatarInfo.coin}
                  onChange = {(e) => setSelectedAvatarInfo(prev => {return {...prev, coin: e.target.value}})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><CopyrightOutlined/></InputAdornment>,
                  }}
        />
                    <FormControl sx={{ m: 1, width: '25ch'}}>
                        <InputLabel id="demo-select-small-label">Gender</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="Test Style"
                            style={{width: "100%"}}
                            value={selectedAvatarInfo.gender}
                            onChange={(e) => setSelectedAvatarInfo(prev => {return {...prev, gender: e.target.value}})}
                        >
                            <MenuItem value={1}>Male</MenuItem>
                            <MenuItem value={2}>Female</MenuItem>
                        </Select>
                    </FormControl>
                </Row>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleAvatarImgUpload(e)}>
                            <label for="file-input">Avatar Image:&nbsp;</label>
                            <input type="file" className="file-input" name="my-avatar-image" onChange= {(e)=>changeSelectedAvatarImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <div style={{marginTop:10, marginBottom: 10, textAlign: "center"}}>
                        <Image src={uploadedAvatarImgUrl} alt='avatar image' style={{width: "100%", }}  />
                        </div>
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={avatarImgUploadPercent} />
                        </Box>
                        
                    </Col>
                </Row>
                
                </Space>
            </Modal>
        </>
    )
}

export default Avatar;