import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchAllBackgrounds = createAsyncThunk('/temp/bubble/topic_background/fetch_all', async () => {
  const response = await api.get(`/api/bubble/temp/topic_background/get_all_background`);
  return response.data;
});

export const addOneBackground = createAsyncThunk('/temp/bubble/topic_background/add_one', async (data) => {
    const response = await api.post(`/api/bubble/temp/topic_background/add_background`, data);
    return response.data;
});

export const editOneBackground = createAsyncThunk('/temp/bubble/topic_background/edit_one', async (data) => {
    const response = await api.post(`/api/bubble/temp/topic_background/edit_background/${data.id}`, data);
    return response.data;
});

export const deleteOneBackground = createAsyncThunk('/temp/bubble/topic_background/delete_one', async (id) => {
    const response = await api.delete(`/api/bubble/temp/topic_background/delete_one/${id}`);
    return response.data;
});

const bubbleTopicBackgroundSlice = createSlice({
  name: "topicbackground",
  initialState: { backgrounds:[],  status: "idle", error: null, loading: false},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllBackgrounds.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllBackgrounds.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.backgrounds = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllBackgrounds.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneBackground.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneBackground.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneBackground.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneBackground.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneBackground.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneBackground.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneBackground.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneBackground.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleteded!")
        })
        .addCase(deleteOneBackground.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default bubbleTopicBackgroundSlice.reducer;