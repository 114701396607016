import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../config/api";
import { successNotification, errorNotification } from "../../../../utilities/Notification";

export const fetchOneRound = createAsyncThunk(
  "/test2/fetchOneRound",
  async (rid) => {
    const response = await api.get(`/api/temp/test2/get_one_round/${rid}`);
    return response.data;
  }
);

export const addOneProblem = createAsyncThunk(
  "/test2/addOneProblem",
  async (rid) => {
    const response = await api.post(`/api/temp/test2/add_problem/${rid}`);
    return response.data;
  }
);

export const editOneProblem = createAsyncThunk(
  "/test2/editOneProblem",
  async (data) => {
    const response = await api.post(`/api/temp/test2/edit_problem/${data.pid}`, data);
    return response.data;
  }
);

export const deleteOneProblem = createAsyncThunk(
  "/test2/deleteOneProblem",
  async (pid) => {
    const response = await api.delete(`/api/temp/test2/delete_one_problem/${pid}`);
    return response.data;
  }
);

export const addOneStep = createAsyncThunk(
  "/test2/addOneStep",
  async (pid) => {
    const response = await api.post(`/api/temp/test2/add_step/${pid}`)
    return response;
  }
);

export const editOneStep = createAsyncThunk(
  "/test2/editOneStep",
  async (info) => {
    const data = {
      letter: info.letter,
      keyboardImgFileName: info.keyboardImgFileName,
      soundFileName: info.audioFileName
    }
    const response = await api.post(`/api/temp/test2/edit_step/${info.sid}`, data);
    return response.data;
  }
);

export const deleteOneStep = createAsyncThunk(
  "/test2/deleteOneStep",
  async (sid) => {
    const response = await api.delete(`/api/temp/test2/delete_one_step/${sid}`);
    return response.data;
  }
);

const test2RoundSlice = createSlice({
  name: "test2Round",
  initialState: { problems: [], status: "idle", error: null, message: "", loading: false, fee: 0.0, round: 1, style: 1, rid: "" },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchOneRound.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchOneRound.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.problems = action.payload.problems;
            state.fee = action.payload.fee;
            state.round = action.payload.round;
            state.style = action.payload.style;
            state.rid = action.payload._id;
            state.loading = false;
        })
        .addCase(fetchOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneProblem.pending, (state) => {
          state.status = "loading";
        })
        .addCase(addOneProblem.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully add!")
            state.status = "idle";
        })
        .addCase(addOneProblem.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneProblem.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneProblem.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully edited!")
          state.status = "idle";
        })
        .addCase(editOneProblem.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneProblem.pending, (state) => {
          state.status = "loading";
        })
        .addCase(deleteOneProblem.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneProblem.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneStep.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneStep.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneStep.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneStep.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneStep.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneStep.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneStep.pending, (state) => {
          state.status = "loading";
        })
        .addCase(deleteOneStep.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneStep.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

// export const { } = test2Slice.actions;

export default test2RoundSlice.reducer;
