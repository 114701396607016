import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";

import { successNotification, errorNotification } from "../../utilities/Notification";


export const registerAdmin = createAsyncThunk('/admin/register', async (data) => {
  const response = await api.post(`/api/admin/register`, data);
  return response.data;
});

export const deleteOneAdmin = createAsyncThunk('/admin/delete_one', async (id) => {
  const response = await api.delete(`/api/admin/delete_one/${id}`);
  return response.data;
});

export const fetchAllAdmins = createAsyncThunk('/admin/fetch_all', async () => {
    const response = await api.get(`/api/admin/get_all`);
    return response.data;
});

export const resetPassword = createAsyncThunk('/admin/reset_password', async (data) => {
  const response = await api.post(`/api/admin/reset_password/${data.id}`, data);
  return response.data;
});

const adminSlice = createSlice({
  name: "admin",
  initialState: { admins:[],  status: "idle", error: null, loading: false},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllAdmins.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllAdmins.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.admins = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllAdmins.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(registerAdmin.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(registerAdmin.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Added!")
        })
        .addCase(registerAdmin.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneAdmin.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneAdmin.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleteded!")
        })
        .addCase(deleteOneAdmin.rejected, (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
        })
        .addCase(resetPassword.pending, (state) => {
          state.status = "loading";
          state.loading = true;
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Password Reset!")
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export const {} = adminSlice.actions;

export default adminSlice.reducer;