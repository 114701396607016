import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../config/api";

import { successNotification, errorNotification } from "../../../../utilities/Notification";

export const fetchAllCoinBundles = createAsyncThunk('/shop/coin/fetch_all', async () => {
  const response = await api.get(`/api/pay_info/coin_bundle/get_all`);
  return response.data;
});

export const addOneCoinBundle = createAsyncThunk('/shop/coin/add_one', async (data) => {
    const response = await api.post(`/api/pay_info/coin_bundle/add`, data);
    return response.data;
});

export const editOneCoinBundle = createAsyncThunk('/shop/coin/edit_one', async (data) => {
    const response = await api.post(`/api/pay_info/coin_bundle/edit/${data.id}`, data);
    return response.data;
});

export const deleteOneCoinBundle = createAsyncThunk('/shop/coin/delete_one', async (id) => {
    const response = await api.delete(`/api/pay_info/coin_bundle/delete/${id}`);
    return response.data;
});

export const editTag = createAsyncThunk('/shop/coin/edit_tag', async (data) => {
  const response = await api.post(`/api/pay_info/coin_bundle/edit_tag`, data);
  return response.data;
});

const coinSlice = createSlice({
  name: "coin",
  initialState: { coinBundles:[],  status: "idle", error: null, loading: false},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllCoinBundles.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllCoinBundles.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.coinBundles = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllCoinBundles.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneCoinBundle.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneCoinBundle.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "succeeded";
            state.coinBundles = action.payload;
        })
        .addCase(addOneCoinBundle.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneCoinBundle.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneCoinBundle.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "succeeded";
            state.coinBundles = action.payload;
        })
        .addCase(editOneCoinBundle.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneCoinBundle.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneCoinBundle.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.coinBundles = action.payload;
            successNotification("Success!", "Successfully Deleteded!")
        })
        .addCase(deleteOneCoinBundle.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editTag.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editTag.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "succeeded";
            state.coinBundles = action.payload;
        })
        .addCase(editTag.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default coinSlice.reducer;