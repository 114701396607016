import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchUnits = createAsyncThunk('unit/fetch', async (data) => {
    const response = await api.post(`/api/temp/unit/get_all_unit`, data);
    return response.data;
});

export const addUnit = createAsyncThunk('unit/add', async (data) => {
    const response = await api.post(`/api/temp/unit/add_unit`, data);
    return response;
})

export const editUnit = createAsyncThunk('unit/edit', async (data) => {
    const response = await api.post(`/api/temp/unit/edit_unit/${data._id}`, data);
    return response.data;
});

export const deleteOneUnit = createAsyncThunk('unit/deleteOne', async (data) => {
    const response = await api.post(`/api/temp/unit/delete_one/${data.id}`, data);
    return response.data;
});

const unitSlice = createSlice({
  name: "unit",
  initialState: { units:[],  status: "idle", error: null, loading: false, amount: 0, },
  reducers: {
    setStatus: (state, action) => {
        state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchUnits.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchUnits.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.units = action.payload;
        })
        .addCase(fetchUnits.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addUnit.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(addUnit.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully added!");
        })
        .addCase(addUnit.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editUnit.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(editUnit.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully edited!");
        })
        .addCase(editUnit.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneUnit.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneUnit.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneUnit.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
    },
});

export const {setStatus} = unitSlice.actions;

export default unitSlice.reducer;