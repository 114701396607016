import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllTopics = createAsyncThunk('/fetchTopics', async (data) => {
  const response = await api.get(`/api/temp/topic/get_all_topic`);
  return response.data;
});

export const deleteOneTopic = createAsyncThunk(
  "/topic/deleteOneTopic",
  async (data) => {
    const response = await api.post(`/api/temp/topic/delete_one/${data.id}`, data);
    return response.data;
  }
);

export const addOneTopic = createAsyncThunk(
"/topic/addOneTopic",
async (data) => {
  const response = await api.post(`/api/temp/topic/add_topic`, data);
  return response.step;
}
);

export const editOneTopic = createAsyncThunk(
"/topic/editOneTopic",
async (data) => {
  const id = data.id;
  const response = await api.post(`/api/temp/topic/edit_topic/${id}`, data);
  return response;
}
);

const topicSlice = createSlice({
  name: "topic",
  initialState: { topics: [], status: "idle", loading: false, message: ""},
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllTopics.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchAllTopics.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topics = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllTopics.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        if(action.error.message === "Request failed with status code 403") {
          state.error = "Your token is expired or invalid! Please try to login again."
        }
        errorNotification("Failed!", state.error);
      })
      .addCase(addOneTopic.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOneTopic.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully added!")
          state.status = "idle";
      })
      .addCase(addOneTopic.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(editOneTopic.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editOneTopic.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully edited!")
          state.status = "idle";
      })
      .addCase(editOneTopic.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(deleteOneTopic.pending, (state) => {
          state.status = "loading";
      })
      .addCase(deleteOneTopic.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully deleted!")
          state.status = "idle";
      })
      .addCase(deleteOneTopic.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
    },
});

export default topicSlice.reducer;