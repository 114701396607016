import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import { loginSuccess, logoutSuccess } from './features/authSlice';

const Router = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(loginSuccess());
      localStorage.setItem('isAuthenticated', true);
    } else {
      dispatch(logoutSuccess());
      localStorage.removeItem('isAuthenticated');
    }
  }, [dispatch]);
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home/*" element={isAuthenticated ? <Home /> : <Navigate replace to="/login" />} />
            <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes> 
    </BrowserRouter>
  );
};

export default Router;
