import React, { useRef, useState, useEffect } from 'react';
import { Button, Space, Table, Modal, Typography, Popconfirm, message, Input, Image } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Highlighter from 'react-highlight-words';

import { useDispatch, useSelector } from 'react-redux';
import { fetchBubbleUsers, deleteOneBubbleUser, forgetBubbleUserPassword, changeBubbleUserPaymentStatus } from '../../../../features/bubble/user/bubbleUserSlice';
import { serverURL } from '../../../../config/config';
import "./User.css";

const BubbleUser = () => {

    const dispatch = useDispatch();
    
    const userField = useSelector((state) => state.bubbleUser);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {
            name: "",
            email: "",
            nation: ""
        },
        sorter: {
            score: 0,
            gem: 0,
            isPaid: 0,
        }
    });
    const searchInput = useRef(null);

    useEffect(() => {
        if(userField.status === "idle") {
            dispatch(fetchBubbleUsers(tableParams));
        }
    }, [dispatch, userField]);
    
    useEffect(() => {
        console.log(tableParams);
        dispatch(fetchBubbleUsers(tableParams));
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevState => ({ 
            ...prevState,
            pagination
        }))
        if(sorter) {
            let flag1 = 0;
            let flag2 = 0;
            let flag3 = 0;
            if(sorter.field === "score") {
                if(sorter.order === "ascend") {
                    flag1 = -1;
                }
                if(sorter.order === "descend") {
                    flag1 = 1;
                }
            }
            if(sorter.field === "gem") {
                if(sorter.order === "ascend") {
                    flag2 = -1;
                }
                if(sorter.order === "descend") {
                    flag2 = 1;
                }
            }
            if(sorter.field === "isPaid") {
                if(sorter.order === "ascend") {
                    flag3 = -1;
                }
                if(sorter.order === "descend") {
                    flag3 = 1;
                }
            }
            setTableParams(prevState => ({
                ...prevState,
                sorter : {
                    score : flag1,
                    gem : flag2,
                    isPaid: flag3
                }
            }))
        }
    };

    //operation
    const handleChangeStatus = (record) => {
        dispatch(changeBubbleUserPaymentStatus({id: record._id}));
    }

    const handleForgetPassword = (record) => {
        dispatch(forgetBubbleUserPassword({email: record.email}));
    }

    const handleDelete = (record) => {
        dispatch(deleteOneBubbleUser(record._id));
    }


    //For search function in antd Table

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        setTableParams(prevState => ({ 
            ...prevState,
            filters: {
                ...prevState.filters,
                [dataIndex]: selectedKeys[0]
            }
        }))
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
        <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
            marginBottom: 8,
            display: 'block',
        }}
        />
        <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
        </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    const columns = [
        {
            title: 'No',
            key: 'No',
            width: '8%',
            ellipse: true,
            render: (text, record, index) => {
                return (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '20%',
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Nation',
            dataIndex: 'nation',
            key: 'nation',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <Image src={serverURL + "/public/Flags/" + text + ".png"} style={{ width: 60}} alt="img"/>
            }
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            width: '10%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Gem',
            dataIndex: 'gem',
            key: 'gem',
            width: '10%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'isPaid',
            key: 'isPaid',
            width: '12%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <span className={text ? "paid-cell" : " unpaid-cell"}> &nbsp;{text ? "Paid" : "unPaid"} &nbsp;</span>;
            }
        },
        // {
        //     title: 'Password',
        //     dataIndex: 'password',
        //     key: 'password',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     ellipsis: true,
        // },
        {
          title: 'Operation',
          dataIndex: 'operation',
          render: (_, record) => {
            return (
                <div>
                    <Space size="middle" >
                        <Popconfirm title="Sure to change payment status to 'Paid'?" onConfirm={() => handleChangeStatus(record)}>
                            <Typography.Link>
                                <Tooltip title="Change the payment status.">
                                    <Icon sx={{fontSize: 20}}>credit_card</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>

                        <Popconfirm title="Sure to send message?" onConfirm={() => handleForgetPassword(record)}>
                            <Typography.Link style={{ color: "green"}}>
                                <Tooltip title="Send password reset email">
                                    <Icon sx={{fontSize: 20}}>mail_lock</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>

                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                            <Typography.Link style={{ color: "red"}}>
                                <Tooltip title="Delete">
                                    <Icon sx={{fontSize: 18}}>delete</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                </div>
            )
          }
        },
    ];

    return(
        <>
            <Table
                className="userTable"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={userField.users}
                pagination={{...tableParams.pagination, total: userField.amount, showSizeChanger: true, showTotal: (total) => `Total : ${total} users` }}
                loading={userField.loading}
                onChange={handleTableChange}
                
            />
        </>
    )
}

export default BubbleUser;