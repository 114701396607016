import React, { useState, useEffect } from 'react';
import { Button, Space, Table, Modal, Typography, Popconfirm, message } from 'antd';

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAdmins, registerAdmin, deleteOneAdmin, resetPassword } from '../../../features/admin/adminSlice';

import "./Admin.css";

const Admin = () => {

    const dispatch = useDispatch();
    
    const adminField = useSelector((state) => state.admin);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState({
      name: "",
      email: "",
      password: "",
      password2: "",
      id:""
    });
    const [isAddAdminModal, setIsAddAdminModal] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    useEffect(() => {
        if(adminField.status === "idle") {
          dispatch(fetchAllAdmins());
        }
    }, [dispatch, adminField]);

    //function for admin add button

    const handleAdminAdd = () => {
      setIsAddAdminModal(true);
      setSelectedAdmin({
        name: "",
        email: "",
        password: "",
        password2: "", 
        id: ""
      })
      setIsModalOpen(true);
    }

    // functions for table operations.

    const handleResetPassword = (record) => {
      setIsAddAdminModal(false);
      setSelectedAdmin({
        password: "",
        password2: "",
        id: record._id
      })
      setIsModalOpen(true);
    }
    const handleDelete = (record) => {
      dispatch(deleteOneAdmin(record._id));
    }

    // check the email with regular expression
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    //Modal function
    const changeSelectedName = (e) => {
      setSelectedAdmin(prev => {
        return {...prev, name: e.target.value};
      });
    }

    const changeSelectedEmail = (e) => {
      setSelectedAdmin(prev => {
        return {...prev, email: e.target.value};
      });
    }

    const changeSelectedPassword = (e) => {
      setSelectedAdmin(prev => {
        return {...prev, password: e.target.value};
      });
    }

    const changeSelectedPassword2 = (e) => {
      setSelectedAdmin(prev => {
        return {...prev, password2: e.target.value};
      });
    }

    const handleSave = () => {
      if(isAddAdminModal) {
        if(!isValidEmail(selectedAdmin.email) || selectedAdmin.email === "") {
          message.error("Plesae check email again", 3);
          return;
        }
        if(selectedAdmin.name === "" ) {
          message.error("Plesae check name again", 3);
          return;
        }
        if(selectedAdmin.password === "") {
          message.error("Please input password correctly.", 3);
          return;
        }
        if(selectedAdmin.password !== selectedAdmin.password2) {
          message.error("Not the same password. Please Check again.", 3);
          return;
        }
        dispatch(registerAdmin(selectedAdmin));
        setIsModalOpen(false);
      }
      else {
        if(selectedAdmin.password === "") {
          message.error("Please input password correctly.", 3);
          return;
        }
        if(selectedAdmin.password !== selectedAdmin.password2) {
          message.error("Not the same password. Please Check again.", 3);
          return;
        }
        dispatch(resetPassword(selectedAdmin));
        setIsModalOpen(false);
      }
    }

    const handleCancel = () => {
      setIsModalOpen(false);
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  
    const handleMouseDownPassword2 = (event) => {
      event.preventDefault();
    };


    const columns = [
        {
          title: 'No',
          key: 'No',
          width: '5%',
          render: (text, record, index) => index + 1
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          width: '15%',
          textWrap: 'word-break',
          ellipsis: true,
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          width: '25%',
          textWrap: 'word-break',
          ellipsis: true,
        },
        {
          title: 'Password',
          dataIndex: 'password',
          key: 'password',
          width: '45%',
          textWrap: 'word-break',
          ellipsis: true,
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          render: (_, record) => {
            return (
              <div>
            <Space size="middle" >
                <Typography.Link  onClick={() => handleResetPassword(record)} >
                    <Tooltip title="Reset Password">
                        <Icon sx={{fontSize: 22}}>lock_reset</Icon>
                    </Tooltip>
                </Typography.Link>

                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
              </div>
            )
          }
        },
    ];

    return(
        <>
            <Button type="primary" size='middle' onClick={() => handleAdminAdd()} className = "addAdmin"> Add Admin </Button>

            <Table
              bordered
              dataSource={adminField.admins}
              columns={columns}
              loading={adminField.loading}
              rowKey={(record) => record._id}
            />

            <Modal  odal size="large" style={{fontSize: 24, textAlign: "center"}} title={<h2 style={{fontSize: '24px'}}>{isAddAdminModal ? "Admin Register" : "Reset Password"}</h2>} open={isModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" >
             {isAddAdminModal &&
                <>
                  <FormControl fullWidth sx={{ m: 1, marginTop: 4 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Name</InputLabel>
                    <Input
                      id="standard-adornment-name"
                      value={selectedAdmin.name}
                      onChange={changeSelectedName}
                      startAdornment={<InputAdornment position="start"></InputAdornment>}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ m: 1}} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Email</InputLabel>
                    <Input
                      id="standard-adornment-email"
                      value={selectedAdmin.email}  
                      onChange={changeSelectedEmail}
                      startAdornment={<InputAdornment position="start"></InputAdornment>}
                    />
                  </FormControl> 
                </>
             }
                <FormControl fullWidth sx={isAddAdminModal ? { m: 1} : {m: 1, marginTop: 4}} variant="filled">
                  <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={selectedAdmin.password}  
                    onChange={changeSelectedPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth sx={{ m: 1}} variant="filled">
                  <InputLabel htmlFor="filled-adornment-password">Confirm</InputLabel>
                  <FilledInput
                    id="filled-adornment-password"
                    type={showPassword2 ? 'text' : 'password'}
                    value={selectedAdmin.password2}  
                    onChange={changeSelectedPassword2}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
            </Modal>
        </>
    )
}

export default Admin;