import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../../../../config/api";
import { Popconfirm, Table, Typography, Button, Modal, Divider, message, Space, Image, Col, Row } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import LinearProgress from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";


import { fetchAllBackgrounds, addOneBackground, editOneBackground, deleteOneBackground } from "../../../../features/ebubble/topic_background/ebubbleTopicBackgroundSlice";
import { editBubbleTopicBackgroundVersion, fetchBubbleVersion } from "../../../../features/ebubble/ebubbleVersionSlice";
import { successNotification, errorNotification } from "../../../../utilities/Notification";
import { serverURL } from "../../../../config/config";

import './TopicBackground.css';

const TopicBackground = () => {
    const dispatch = useDispatch();
    const backgroundInfo = useSelector((state) => state.ebubbleTopicBackground);
    const version = useSelector((state) => state.ebubbleVersion);

    const [pagination, setPagination] = useState({
        pageSize: 5,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['3', '5', '10'],
    });

    useEffect(() => {
        if(backgroundInfo.status === 'idle') {
            dispatch(fetchAllBackgrounds());
        }
        if(version.status === 'idle') {
            dispatch(fetchBubbleVersion());
        }
        if(version.status === 'succeeded') {
            setBackgroundCurrentVersion(version.topicBackgroundVersion);
            setBackgroundUpdatedVersion(parseFloat(version.topicBackgroundVersion + 0.1).toFixed(1));
        }
    }, [dispatch, backgroundInfo, version]);

    const [backgroundCurrentVersion, setBackgroundCurrentVersion] = useState(1.0);
    const [backgroundUpdatedVersion, setBackgroundUpdatedVersion] = useState(1.0);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [uploadedBackgroundImgUrl, setUploadedBackgroundImgUrl] = useState("");
    const [selectedBackgroundImg, setSelectedBackgroundImg] = useState("");
    const [backgroundImgUploadPercent, setBackgroundImgUploadPercent] = useState(0);

    const [selectedBackgroundInfo, setSelectedBackgroundInfo] = useState({name: "", id: ""});

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    const handleChangeVersion = (e) => {
        setBackgroundUpdatedVersion(e.target.value);
    }

    //table operation

    const handleBackgroundAdd = () => {
        setSelectedBackgroundInfo({
            name: "",
            id: ""
        });
        setBackgroundImgUploadPercent(0);
        setUploadedBackgroundImgUrl("");
        setIsCreateModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedBackgroundInfo({
            name: record.name,
            id: record._id
        });
        setBackgroundImgUploadPercent(0);
        setUploadedBackgroundImgUrl(serverURL + "/public/ebubble/topicbackground/" + record.name);
        setIsEditModalOpen(true);
    }

    const DeleteBackground = (record) => {
        dispatch(deleteOneBackground(record._id));
    }

    //modal operation

    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsCreateModalOpen(false);
    }

    const handleCreate = () => {
        dispatch(addOneBackground(selectedBackgroundInfo));
        setIsCreateModalOpen(false);
    }

    const handleSave = (record) => {
        dispatch(editOneBackground(selectedBackgroundInfo));
        setIsEditModalOpen(false);
    }

    const isPngFile = (file) => {
        return file && file.type === 'image/png';
    }

    const handleBackgroundImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedBackgroundImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedBackgroundImg[0]);

        api.post(`/api/english_bubble/temp/topic_background/background_image_upload`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setBackgroundImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const backgroundImgUrl = serverURL + '/public/ebubble/topicbackground/' + res.data.backgroundImgFileName;
                setSelectedBackgroundInfo(prev => {
                    return {
                    ...prev,
                    name: res.data.backgroundImgFileName
                    }
                })
                setUploadedBackgroundImgUrl(backgroundImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedBackgroundImg = (e) => {
        setSelectedBackgroundImg(e.target.files);
    }

    const handleVersionUpdate = () => {
        if(backgroundUpdatedVersion <= backgroundCurrentVersion) {
          message.error("Plesae check the Next Version number", 3);
          return;
        }
        dispatch(editBubbleTopicBackgroundVersion({version: backgroundUpdatedVersion}));
    }
    
    const updateConfirmModal = () => {
        Modal.confirm({
          title: 'Do you really Want to update version?',
          icon: <ExclamationCircleFilled />,
          width: 500,
          content: (
                <div>
                <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
                <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version. </p>
                <TextField
                    disabled
                    label="Next Version"
                    id="outlined-size-small"
                    size="small"
                    value={backgroundUpdatedVersion}
                    className="version"
                />
                </div>
            ),
            onOk() {
                handleVersionUpdate();
            },
            onCancel() {
            },
        });
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '10%',
            ellipsis: true,
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Background Image',
            dataIndex: 'name',
            key: 'name',
            width: '70%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <Image src={serverURL + "/public/ebubble/topicbackground/" + text} style={{height: 500}} alt="img"/>
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => DeleteBackground(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];


    return (
        <>
            <TextField
              disabled
              label="Current Version"
              id="outlined-size-small"
              size="small"
              value={backgroundCurrentVersion}
              onChange={handleChangeVersion}
              className="version"
            />
            <TextField
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={backgroundUpdatedVersion}
              onChange={handleChangeVersion}
              style={{backgroundColor: "white"}}
              className="version"
            />
            <Button type="primary" size='middle' onClick={updateConfirmModal} className="updateButton"> Update </Button>

            <Button type="primary" size='middle' onClick={() => handleBackgroundAdd()} className = "addRound"> Add Background </Button>
            <Table
                bordered
                dataSource={backgroundInfo.backgrounds}
                columns={columns}
                loading={backgroundInfo.loading}
                pagination={pagination}
                scroll={{ x: '100%' }}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            /> 
            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Background</h2>} open={isCreateModalOpen} onOk={handleCreate} onCancel={handleCancel} okText="Create" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleBackgroundImgUpload(e)}>
                            <label for="file-input">Background Image:&nbsp;</label>
                            <input type="file" className="file-input" name="my-background-image" onChange= {(e)=>changeSelectedBackgroundImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <div style={{marginTop:10, marginBottom: 10, textAlign: "center"}}>
                        <Image src={uploadedBackgroundImgUrl} alt='background image' style={{width: "100%", }}  />
                        </div>
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={backgroundImgUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                
                </Space>
            </Modal>

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Background</h2>} open={isEditModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleBackgroundImgUpload(e)}>
                            <label for="file-input">Background Image:&nbsp;</label>
                            <input type="file" className="file-input" name="my-background-image" onChange= {(e)=>changeSelectedBackgroundImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <div style={{marginTop:10, marginBottom: 10, textAlign: "center"}}>
                        <Image src={uploadedBackgroundImgUrl} alt='background image' style={{width: "100%", }}  />
                        </div>
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={backgroundImgUploadPercent} />
                        </Box>
                        
                    </Col>
                </Row>
                
                </Space>
            </Modal>
        </>
    )
}

export default TopicBackground;