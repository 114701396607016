import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchBubbleUsers = createAsyncThunk('/bubble/user/fetch', async (data) => {
    const response = await api.post(`/api/bubble/user/manage/get_page`, data);
    return response.data;
});

export const changeBubbleUserPaymentStatus = createAsyncThunk('/bubble/user/change/status', async (data) => {
    const response = await api.post(`/api/bubble/user/manage/change_status/${data.id}`, data);
    return response.data;
});

export const deleteOneBubbleUser = createAsyncThunk('/bubble/user/deleteOneBubbleUser', async (id) => {
    const response = await api.delete(`/api/bubble/user/manage/delete_one/${id}`);
    return response.data;
});

export const resetBubbleUserPassword = createAsyncThunk('/bubble/user/resetPassword', async (data) => {
    const response = await api.post(`/api/bubble/user/manage/reset_password/${data.id}`, data);
    return response.data;
});

export const forgetBubbleUserPassword = createAsyncThunk('/bubble/user/forgetPassword', async (data) => {
    const response = await api.post(`/api/auth/bubble/user/reset_password`, data);
    return response.data;
});

const bubbleUserSlice = createSlice({
  name: "bubbleUser",
  initialState: { users:[],  status: "idle", error: null, loading: false, amount: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchBubbleUsers.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchBubbleUsers.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.amount = action.payload.amount;
            state.users = action.payload.users;
        })
        .addCase(fetchBubbleUsers.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneBubbleUser.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneBubbleUser.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneBubbleUser.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(changeBubbleUserPaymentStatus.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(changeBubbleUserPaymentStatus.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Reset Password!")
        })
        .addCase(changeBubbleUserPaymentStatus.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(resetBubbleUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(resetBubbleUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Reset Password!")
        })
        .addCase(resetBubbleUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(forgetBubbleUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(forgetBubbleUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            console.log(action.payload);
            successNotification("Success!", "Successfully send password reset message to the user!")
        })
        .addCase(forgetBubbleUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default bubbleUserSlice.reducer;