import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { fetchAllPractices } from "../../../features/piano/lesson/practiceSlice";

const Main = () => {
    const dispatch = useDispatch();

    // const practiceField = useSelector((state) => state.practiceField);

    // useEffect(() => {
    //     dispatch(fetchAllPractices);
    // }, []);

    return (
        <div className="my_main_section" style={{minHeight: window.innerHeight}}>
            <h1>Main</h1>
        </div>
    )
}

export default Main;