import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import { serverURL } from "../config/config";

import { successNotification, errorNotification } from "../utilities/Notification";


export const fetchAuth = createAsyncThunk('/fetchAuth', async (userInfo) => {
  // socket.emit('login', {type: "bubble", email: userInfo.email});
  const response = await axios.post(`${serverURL}/api/auth/admin/login`, userInfo);
  localStorage.setItem('token', response.data.token);
  return response.data;
});

const authSlice = createSlice({
  name: "auth",
  initialState: { user: "", login: false, isAuthenticated: localStorage.getItem("isAuthenticated"), error: null },
  reducers: {
    setAuth: (state, action) => {
      state.user = action.payload.user;
      state.login = action.payload.login;
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
    },
    logoutSuccess: (state, action) => {
      state.isAuthenticated = false;
      localStorage.removeItem('token');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.fulfilled, (state, action) => {
        state.login = action.payload.login;
        state.user = action.payload.user;
        state.isAuthenticated = true;
        successNotification("Login Success!", action.payload.user + ". Welcome to visit our Admin Website.");
      })
      .addCase(fetchAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.isAuthenticated = true;
        if(action.error.message === 'Network Error') {
          errorNotification("Failed!", action.error.message);
          return;
        }
        errorNotification('Failed', 'Wrong Email or Password! Check and try again')
    })
  },
});

export const { setAuth, loginSuccess, logoutSuccess } = authSlice.actions;

export default authSlice.reducer;