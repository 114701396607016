import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import UnitTable from "./components/UnitTable";

import { setStatus } from "../../../../features/piano/unit/unitSlice";
import { fetchLessonUsedInfo, fetchLessonCount } from "../../../../features/piano/lesson/practiceSlice";
import { fetchAllTopics } from "../../../../features/piano/topic/topicSlice";

import "./Unit.css"

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    const dispatch = useDispatch();
    dispatch(setStatus("idle"));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
    );
}
  
CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}
  
const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Unit = () => {
    const dispatch = useDispatch();
    const topicInfo = useSelector(state => state.topic);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
    useEffect(() => {
        dispatch(fetchLessonUsedInfo());
        dispatch(fetchLessonCount());

    });

    useEffect(() => {
        dispatch(fetchAllTopics());
    }, []);

    return(
        <>
            <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {/* <Tab label="Topic 1 (Letter)" {...a11yProps(0)} />
                <Tab label="Topic 2 (Word)" {...a11yProps(1)} /> */}
                {
                    topicInfo.topics.map((element, index) => (
                        <Tab label={"Topic" + element.no + " (" + element.name + ")"} {...a11yProps(element.no)} />
                    ))
                }
                </Tabs>
            </Box>
            {/* <CustomTabPanel value={value} index={0} children={<UnitTable type = {1} />} />
            <CustomTabPanel value={value} index={1} children={<UnitTable type = {2} />} /> */}
            {
                topicInfo.topics.map((element, index) => (
                    <CustomTabPanel value={value} index={index} children={<UnitTable type = {element.no} />} />
                ))
            }
            </Box>
        </>
    )
}

export default Unit;