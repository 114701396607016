import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: "theme",
  initialState: { themeMode: "inline", themeColor: "dark", collapsed: false },
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    setThemeColor:  (state, action) => {
      state.themeColor = action.payload;
    }, 
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    }
  },
});

export const { setThemeMode, setThemeColor, setCollapsed } = themeSlice.actions;

export default themeSlice.reducer;
