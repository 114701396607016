import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {  MDBBtn,  MDBContainer,  MDBRow,  MDBCol,  MDBCard,  MDBCardBody,  MDBInput } from 'mdb-react-ui-kit';

import { fetchAuth } from "../../features/authSlice";

import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginInfo = useSelector((state) => state.auth);

  const [userInfo, setUserInfo] = useState({ email: "", password: "" });

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(fetchAuth(userInfo));
  };

  useEffect(() => {
    if (loginInfo.login === true) {
      navigate("/home/admin");
    } 
  }, [loginInfo, navigate]);

  return (
    <MDBContainer fluid style={{backgroundColor: "#00284d", height: window.innerHeight}}>

        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
            <MDBCol col='12'>

            <MDBCard className='bg-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '500px'}}>
                <MDBCardBody className='p-5 w-100 d-flex flex-column'>

                <h2 className="fw-bold mb-2 text-center"><u>Admin Panel</u></h2>
                <p className="text-white-50 mb-3">Please enter your login and password!</p>

                <MDBInput 
                    value={userInfo.email}
                    onChange={(e) => setUserInfo({
                        ...userInfo,
                        email: e.target.value
                     })}
                    wrapperClass='mb-4 w-100' 
                    label='Email address' 
                    id='EmailAddress' 
                    type='email' 
                    size="lg" />
                <MDBInput 
                    value={userInfo.password}
                    onChange={(e) => setUserInfo({
                        ...userInfo,
                        password: e.target.value
                    })}
                    wrapperClass='mb-4 w-100' 
                    label='Password'
                    id='Password' 
                    type='password' 
                    size="lg" />
                <MDBBtn size='lg' onClick={handleLogin} style = {{marginTop: 20}}> Login </MDBBtn>

                <hr className="my-4" />
                </MDBCardBody>
            </MDBCard>

            </MDBCol>
        </MDBRow>

        </MDBContainer>
  );
};

export default Login;
