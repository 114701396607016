import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchQuranUsers = createAsyncThunk('/quran/user/fetch', async (data) => {
    const response = await api.post(`/api/quran/user/manage/get_page`, data);
    return response.data;
});

export const changeQuranUserPayment = createAsyncThunk('/quran/user/change/status', async (data) => {
    const response = await api.post(`/api/quran/user/manage/change_status/${data.id}`, data);
    return response.data;
});

export const deleteOneQuranUser = createAsyncThunk('/quran/user/deleteOneQuranUser', async (id) => {
    const response = await api.delete(`/api/quran/user/manage/delete_one/${id}`);
    return response.data;
});

export const resetQuranUserPassword = createAsyncThunk('/quran/user/resetPassword', async (data) => {
    const response = await api.post(`/api/quran/user/manage/reset_password/${data.id}`, data);
    return response.data;
});

export const forgetQuranUserPassword = createAsyncThunk('/quran/user/forgetPassword', async (data) => {
    const response = await api.post(`/api/auth/quran/user/reset_password`, data);
    return response.data;
});

const quranUserSlice = createSlice({
  name: "quranUser",
  initialState: { users:[],  status: "idle", error: null, loading: false, amount: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchQuranUsers.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchQuranUsers.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.amount = action.payload.amount;
            state.users = action.payload.users;
        })
        .addCase(fetchQuranUsers.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneQuranUser.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneQuranUser.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneQuranUser.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(changeQuranUserPayment.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(changeQuranUserPayment.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Reset Password!")
        })
        .addCase(changeQuranUserPayment.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(resetQuranUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(resetQuranUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Reset Password!")
        })
        .addCase(resetQuranUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(forgetQuranUserPassword.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(forgetQuranUserPassword.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            console.log(action.payload);
            successNotification("Success!", "Successfully send password reset message to the user!")
        })
        .addCase(forgetQuranUserPassword.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default quranUserSlice.reducer;