/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Highlighter from 'react-highlight-words';

import { Popconfirm, Table, Typography, Space, Button, Input, Modal, Divider, message, Row, Col, Image} from 'antd';
import { SearchOutlined, ExclamationCircleFilled, SketchOutlined} from '@ant-design/icons';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import LinearProgress from '@mui/material/LinearProgress';

import { deleteOneLesson, addOneLesson, editOneLesson, fetchAllLessons } from "../../../../features/bubble/lesson/bubbleLessonSlice";
import { fetchBubbleVersion, editBubbleLessonVersion } from "../../../../features/bubble/bubbleVersionSlice";
import { successNotification, errorNotification } from "../../../../utilities/Notification";
import { api } from "../../../../config/api";
import { serverURL } from "../../../../config/config";

import "./BubbleLesson.css";

const {Text} = Typography;

const BubbleLesson = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const lessonField = useSelector((state) => state.bubbleLessonField);
    const version = useSelector((state) => state.bubbleVersion);

    const [pagination, setPagination] = useState({
      pageSize: 5,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10'],
      showTotal: (total) => `Total : ${total} lessons`
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [lessonCurrentVersion, setLessonCurrentVersion] = useState(1.0);
    const [lessonUpdatedVersion, setLessonUpdatedVersion] = useState(1.0);
    const [selectedLessonInfo, setSelectedLessonInfo] = useState({
      topic: "", lesson: 0, gem: 0, title: "", _id: ""
    });

    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [isAddModal, setIsAddModalOpen] = useState(false);

    const [editedLesson, setEditedLesson] = useState(1);

    const [unfilledUpload, setUnfilledUpload] = useState(false);
    const [maxLesson, setMaxLesson] = useState(0);

    const [imageUploadPercent, setImageUploadPercent] = useState(0);
    const [selectedImage, setSelectedImage] = useState("");

    // functions for modal when update button is pressed.

    const updateErrorModal = () => {
      Modal.error({
        title: 'Check your upload done.',
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>There are still some images and sounds to upload.</p>
            <p>Please check then try again!</p>
          </div>
        ),
        onOk() {},
      });
    };

    const updateConfirmModal = () => {
      Modal.confirm({
        title: 'Do you really Want to update version?',
        icon: <ExclamationCircleFilled />,
        width: 500,
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
            <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version. </p>
            <TextField
              disabled
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={lessonUpdatedVersion}
              className="version"
            />
          </div>
        ),
        onOk() {
          handleVersionUpdate();
        },
        onCancel() {
        },
      });
    };

    useEffect(() => {
      if(lessonField.status === "idle") {
        dispatch(fetchAllLessons());
      }
      if(version.status === 'idle') {
        dispatch(fetchBubbleVersion());
      }
      if(version.status === 'succeeded') {
        setLessonCurrentVersion(version.lessonVersion);
        setLessonUpdatedVersion(parseFloat(version.lessonVersion + 0.1).toFixed(1));
      }
    }, [dispatch, lessonField, version]);

    useEffect(() => {
      let max = 0;
      let unfilled = false;
      for(let i = 0; i < lessonField.lessons.length; i ++) {
        if(lessonField.lessons[i].lesson > max) {
          max = lessonField.lessons[i].lesson;
        }
        if(lessonField.lessons[i].bubbleNum !== lessonField.lessons[i].uploadedImgs) {
          unfilled = true; break;
        }
        if(lessonField.lessons[i].bubbleNum !== lessonField.lessons[i].uploadedSounds) {
          unfilled = true; break;
        }
      }
      setUnfilledUpload(unfilled);
      setMaxLesson(max);
    }, [dispatch, lessonField.lessons])

    const handleTableChange = (pagina, filters, sorter) => {
      setPagination(pagina);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    // Edit Modal 

    const showEditModal = () => {
      setIsEditModalOpen(true);
    };

    const handleSave = () => {
      dispatch(editOneLesson(selectedLessonInfo));
      setIsEditModalOpen(false);
    };
    const handleCancel = () => {
      setIsEditModalOpen(false);
      setIsAddModalOpen(false);
    };

    // Add Modal

    const showAddModal = () => {
      setIsAddModalOpen(true);
    };

    const handleAdd = () => {
      dispatch(addOneLesson(selectedLessonInfo));
      setIsAddModalOpen(false);
    };

    //update textfield
    const handleChangeVersion = (e) => {
      setLessonUpdatedVersion(e.target.value);
    }

    //operation

    const handleLessonDetail = (record) => {
      navigate(`/home/arabic/bubble/lesson/${record._id}`);
    }

    const handleLessonDelete = (record) => {
        dispatch(deleteOneLesson(record));
    }

    const handleLessonAdd = () => {
      setImageUploadPercent(0);
      setSelectedLessonInfo({
        lesson: maxLesson + 1,
        topic: "",
        gem: 0,
        title: ""
      });
      showAddModal();
    }

    const handleLessonEdit = (record) => {
      setEditedLesson(record.lesson);
      setImageUploadPercent(0);
      setSelectedLessonInfo({
        lesson: record.lesson,
        gem: record.gem,
        _id: record._id,
        topic: record.topic,
        title: record.title
      });
      showEditModal();
    };

    const handleVersionUpdate = () => {
      if(lessonUpdatedVersion <= lessonCurrentVersion) {
        message.error("Plesae check the Next Version number", 3);
        return;
      }
      dispatch(editBubbleLessonVersion({version: lessonUpdatedVersion}));
    }

    //check upload files type
    const isPngFile = (file) => {
      return file && file.type === 'image/png';
    }

    //upload images
    const handleImageUpload = (e) => {
      e.preventDefault();
      if(!isPngFile(selectedImage[0])) {
          message.error("Not an image/png file", 1.5);
          return;
      }
      const formData = new FormData();
      formData.append("myfile", selectedImage[0]);

      api.post(`/api/bubble/temp/lesson/topic_image_upload`, formData, {
              onUploadProgress: function(progressEvent) {
                  const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                  setImageUploadPercent(percentComplete);
              }
          })
          .then((res) => {
              successNotification("Success", "Successfully image uploaded");
              setSelectedLessonInfo({...selectedLessonInfo, topic: res.data.imgFileName})
          }).catch((error) => {
              errorNotification("Error!", "Upload Failed!");
      });
    }
    
    const columns = [
        {
        title: 'No',
        key: 'lesson',
        dataIndex: 'lesson',
        width: '8%',
        sorter: (a, b) => b.lesson - a.lesson,
        defaultSortOrder: 'descend',
        ...getColumnSearchProps('lesson'),
        render : (text, record, index) => {
          return record.lesson;
        }
        },
        {
        title: 'Topic',
        key: 'topic',
        dataIndex: 'topic',
        width: '18%',
        ellipsis: true,
        render: (text, record, index) => {
          return <Image src={serverURL + "/public/bubble/topics/" + text} style={{width: 150}} alt="image"/>
        }
        },
        {
          title: 'Title',
          key: 'title',
          dataIndex: 'title',
          width: '13%',
          ellipsis: true,
        },
        {
          title: 'Gem',
          key: 'gem',
          dataIndex: 'gem',
          width: '8%',
          ellipsis: true,
        },
        {
        title: 'BubbleNum',
        key: 'bubbleNum',
        dataIndex: 'bubbleNum',
        width: '10%',
        ellipsis: true,
        },
        {
        title: 'Image',
        key: 'image',
        dataIndex: 'image',
        width: '16%',
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <>
              <div>
                <Text code>
                  <Text type={record.bubbleNum === record.uploadedImgs ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.bubbleNum} Upload: {record.uploadedImgs}
                  </Text>
                </Text>
              </div>
            </>
          )
        }
        },
        {
        title: 'Sound',
        key: 'sound',
        width: '16%',
        ellipsis: true,
        render: (text, record, index) => {
          return (
            <>
              <div>
                <Text code>
                  <Text type={record.bubbleNum === record.uploadedSounds ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.bubbleNum} Upload: {record.uploadedSounds}
                  </Text>
                </Text>
              </div>
            </>
          )
        }
        },
        {
        title: 'Operation',
        key: 'operation',
        dataIndex: 'operation',
        render: (_, record) => (
            <Space size="middle" >
                <Typography.Link  onClick={() => handleLessonEdit(record)} >
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>

                <Typography.Link  onClick={() => handleLessonDetail(record)} style={{ color: "green"}}> 
                    <Tooltip title="Detail" >
                        <Icon sx={{fontSize: 18}}>visibility</Icon>
                    </Tooltip>
                </Typography.Link>

                <Popconfirm title="Sure to delete?" onConfirm={() => handleLessonDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return(
        <>
            <TextField
              disabled
              label="Current Version"
              id="outlined-size-small"
              size="small"
              value={lessonCurrentVersion}
              onChange={handleChangeVersion}
              className="version"
            />
            <TextField
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={lessonUpdatedVersion}
              onChange={handleChangeVersion}
              style={{backgroundColor: "white"}}
              className="version"
            />
            <Button type="primary" size='middle' onClick={() => unfilledUpload ?  updateErrorModal() : updateConfirmModal()} className="updateButton"> Update </Button>
            
            <Button type="primary" size='middle' onClick={() => handleLessonAdd()} className = "addLesson"> Add Topic </Button>
            <Table
              bordered
              dataSource={lessonField.lessons}
              columns={columns}
              loading={lessonField.loading}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Topic</h2>} open={isAddModal} onCancel={handleCancel} onOk={handleAdd} width={550}>
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="No"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedLessonInfo.lesson}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, lesson: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <TextField
                  label="Required Gem to Open"
                  sx={{ m: 1, width: '20ch' }}
                  value = {selectedLessonInfo.gem}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, gem: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    endAdornment: <InputAdornment position="end"><SketchOutlined/></InputAdornment>,
                  }}
                />
                <TextField
                  label="Title"
                  sx={{ m: 1, width: '22ch' }}
                  value = {selectedLessonInfo.title}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, title: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                  <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleImageUpload(e)}>
                            <label for="file-input"> Image :&nbsp;</label>
                            <input type="file" className="file-input" name="my-letter-image" onChange= {(e)=>setSelectedImage(e.target.files)} />
                            <button className="upload-button" type="submit"> Upload</button>
                        </form>       
                        <Divider/>      
                        <Image src={serverURL + '/public/bubble/topics/' + selectedLessonInfo.topic} alt='image' style={{width: "100%", marginBottom: 20}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={imageUploadPercent} />
                        </Box>
                    </Col>
                  </Row>
                </Space>
              </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"Topic " + editedLesson}</h2>} open={isEditModal} onCancel={handleCancel} onOk={handleSave} width={550}>
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="Lesson"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedLessonInfo.lesson}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, lesson: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <TextField
                  label="Required Gem to Open"
                  sx={{ m: 1, width: '20ch' }}
                  value = {selectedLessonInfo.gem}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, gem: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                    endAdornment: <InputAdornment position="end"><SketchOutlined/></InputAdornment>,
                  }}
                />
                <TextField
                  label="Title"
                  sx={{ m: 1, width: '22ch' }}
                  value = {selectedLessonInfo.title}
                  onChange = {(e) => setSelectedLessonInfo({...selectedLessonInfo, title: e.target.value})}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </Box>
              <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                  <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleImageUpload(e)}>
                            <label for="file-input"> Image :&nbsp;</label>
                            <input type="file" className="file-input" name="my-letter-image" onChange= {(e)=>setSelectedImage(e.target.files)} />
                            <button className="upload-button" type="submit"> Upload</button>
                        </form>           
                        <Divider/>  
                        <Image src={serverURL + '/public/bubble/topics/' + selectedLessonInfo.topic} alt='image' style={{width: "100%", marginBottom: 20}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={imageUploadPercent} />
                        </Box>
                    </Col>
                  </Row>
                </Space>
            </Modal>

        </>
    )
}

export default BubbleLesson;
