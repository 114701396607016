import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllContents = createAsyncThunk(
  "/quran/text/fetchAllContents",
  async () => {
    const response = await api.get(`/api/quran/temp/text/get_all_pages`);
    return response.data;
  }
);

export const deleteOneContent = createAsyncThunk(
    "/quran/text/deleteOneContent",
    async (data) => {
      console.log("data " + data._id);
      const response = await api.post(`/api/quran/temp/text/delete_one_page/${data._id}`, data);
      return response.data;
    }
);

export const addOneContent = createAsyncThunk(
  "/quran/text/addOneContent",
  async (data) => {
    console.log(data);
    const response = await api.post(`/api/quran/temp/text/add_page`, data);
    return response.data;
  }
);

export const editOneContent = createAsyncThunk(
  "/quran/text/editOneContent",
  async (data) => {
    const lid = data._id;
    const response = await api.post(`/api/quran/temp/text/edit_page/${lid}`, data);
    return response.data;
  }
); 


const textSlice = createSlice({
  name: "contentField",
  initialState: { lessons: [], status: "idle", error: null, message: "", loading: false, version: 0.0, unfilled: false, maxLesson: 0 },
  reducers: {
    setContentFieldStatus: (state, action) => {
      state.status = action.payload.status;
    },
    setContentUnfilled: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllContents.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllContents.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.lessons = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllContents.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneContent.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneContent.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneContent.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneContent.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneContent.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneContent.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneContent.pending, (state) => {
            state.status = "loading";
        })
        .addCase(deleteOneContent.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneContent.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })

  },
});

export const {setContentFieldStatus, setContentUnfilled} = textSlice.actions;

export default textSlice.reducer;
