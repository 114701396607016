import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../config/api";
import { successNotification, errorNotification } from "../../../../utilities/Notification";

export const fetchOneProblem = createAsyncThunk(
  "/test5/fetchOneProblem",
  async (pid) => {
    const response = await api.get(`/api/temp/test5/get_one_problem/${pid}`);
    return response.data;
  }
);

export const addOneWordStep = createAsyncThunk(
  "/test5/addOneWordStep",
  async (data) => {
    const response = await api.post(`/api/temp/test5/add_word_step/${data.pid}`, data);
    return response.data;
  }
);

export const editOneWordStep = createAsyncThunk(
  "/test5/editOneWordStep",
  async (data) => {
    const response = await api.post(`/api/temp/test5/edit_word_step/${data.sid}`, data);
    return response.data;
  }
);

export const deleteOneWordStep = createAsyncThunk(
  "/test5/deletedOneWordStep",
  async (sid) => {
    const response = await api.delete(`/api/temp/test5/delete_one_word_step/${sid}`);
    return response.data;
  }
);

export const deleteAllWordStep = createAsyncThunk(
  "/test5/deletedallWordStep",
  async (pid) => {
    const response = await api.delete(`/api/temp/test5/delete_all_word_step/${pid}`);
    return response.data;
  }
);

const test5ProblemSlice = createSlice({
  name: "test5Problem",
  initialState: { steps: [], word: [], wordSound: "", status: "idle", error: null, message: "", loading: false},
  reducers: {
    setTest5ProblemStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOneProblem.pending, (state) => {
          state.status = "loading";
          state.loading = true;
      })
      .addCase(fetchOneProblem.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.steps = action.payload.steps;
          state.word = action.payload.word;
          state.wordSound = action.payload.wordSound;
          state.loading = false;
      })
      .addCase(fetchOneProblem.rejected, (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(addOneWordStep.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOneWordStep.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully added!")
          state.status = "idle";
      })
      .addCase(addOneWordStep.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(editOneWordStep.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editOneWordStep.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully edited!")
          state.status = "idle";
      })
      .addCase(editOneWordStep.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(deleteOneWordStep.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteOneWordStep.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully deleted!")
          state.status = "idle";
      })
      .addCase(deleteOneWordStep.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(deleteAllWordStep.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAllWordStep.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully clear!")
          state.status = "idle";
      })
      .addCase(deleteAllWordStep.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
},
});

export const {setTest5ProblemStatus} = test5ProblemSlice.actions;

export default test5ProblemSlice.reducer;
