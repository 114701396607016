import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";


export const fetchAllReciters = createAsyncThunk('/fetchReciters', async (data) => {
  const response = await api.get(`/api/quran/temp/reciters/get_all_reciters`);
  return response.data;
});

export const deleteOneReciter = createAsyncThunk(
  "/reciters/deleteOneReciter",
  async (data) => {
    const response = await api.post(`/api/quran/temp/reciters/delete_reciter/${data.id}`, data);
    return response.data;
  }
);

export const addOneReciter = createAsyncThunk(
  "/reciters/addOneReciter",
  async (data) => {
    const response = await api.post(`/api/quran/temp/reciters/add_reciter`, data);
    return response.step;
  }
);

export const editOneReciter = createAsyncThunk(
  "/reciters/editOneReciter",
  async (data) => {
    const id = data.id;
    const response = await api.post(`/api/quran/temp/reciters/edit_reciter/${id}`, data);
    return response;
  }
);


const recitersSlice = createSlice({
  name: "topic",
  initialState: { topics: [], status: "idle", loading: false, message: ""},
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReciters.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(fetchAllReciters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.topics = action.payload;
        state.loading = false;
      })
      .addCase(fetchAllReciters.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        if(action.error.message === "Request failed with status code 403") {
          state.error = "Your token is expired or invalid! Please try to login again."
        }
        errorNotification("Failed!", state.error);
      })
      .addCase(addOneReciter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addOneReciter.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully added!")
          state.status = "idle";
      })
      .addCase(addOneReciter.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(editOneReciter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editOneReciter.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully edited!")
          state.status = "idle";
      })
      .addCase(editOneReciter.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
      .addCase(deleteOneReciter.pending, (state) => {
          state.status = "loading";
      })
      .addCase(deleteOneReciter.fulfilled, (state, action) => {
          successNotification("Success!", "Successfully deleted!")
          state.status = "idle";
      })
      .addCase(deleteOneReciter.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
    },
});

export default recitersSlice.reducer;