import React, {useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchOneLesson, editOneBubble, addOneBubble, deleteOneBubble} from "../../../../../features/bubble/lesson/bubbleLessonDetailSlice";
import { setLessonFieldStatus } from "../../../../../features/bubble/lesson/bubbleLessonSlice";
import { api } from "../../../../../config/api";

import { Popconfirm, Table, Typography, Space, Button, Image, Modal, Divider, Col, Row, message } from 'antd';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { successNotification, errorNotification } from "../../../../../utilities/Notification";

import { serverURL } from "../../../../../config/config";
import "./BubbleLessonDetail.css"

const {Title} = Typography;

const BubbleLessonDetail = () => {

    const { id } = useParams();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [pagination, setPagination] = useState({
        pageSize: 5,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
    });

    const [uploadedImageUrl, setUploadedImageUrl] = useState("");
    const [uploadedSoundUrl, setUploadedSoundUrl] = useState("");
    const [uploadedImageFileName, setUploadedImageFileName] = useState("");
    const [uploadedSoundFileName, setUploadedSoundFileName] = useState("");
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedSound, setSelectedSound] = useState("");
    const [selectedBubble, setSelectedBubble] = useState("");
    const [soundUploadPercent, setSoundUploadPercent] = useState(0);
    const [imageUploadPercent, setImageUploadPercent] = useState(0);

    
    const dispatch = useDispatch();
    const lessonInfo = useSelector((state) => state.bubbleLessonDetail);

    const audioRef = useRef(null);

    useEffect(() => {
        dispatch(fetchOneLesson(id));
    }, [])

    useEffect(() => {
        if(lessonInfo.status === "idle") {
            dispatch(fetchOneLesson(id));
        }
    }, [dispatch, id, lessonInfo]);


    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    // operation

    const handleProblemAdd = () => {
        dispatch(setLessonFieldStatus({status: "idle"}));
        dispatch(addOneBubble(id));
    }

    const handleEdit = (record) => {
        setUploadedSoundFileName(record.sound);
        setUploadedSoundUrl(serverURL + "/public/bubble/audios/" + record.sound);
        setUploadedImageFileName(record.image);
        setUploadedImageUrl(serverURL + "/public/bubble/images/" + record.image);
        setSelectedBubble(record._id);
        setSoundUploadPercent(0);
        setImageUploadPercent(0);
        showModal();
    }

    const handleDelete = (record) => {
        dispatch(setLessonFieldStatus({status: "idle"}));
        dispatch(deleteOneBubble(record._id));
    }

    //modal
    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setIsModalOpen(false);
    }

    //check upload files type
    const isPngFile = (file) => {
        return file && file.type === 'image/png';
    }

    const isAudioFile = (file) => {
        return file && file.type.startsWith('audio/');
    }

    //upload images and audios
    const handleImageUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedImage[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedImage[0]);

        api.post(`/api/bubble/temp/lesson/image_upload`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setImageUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const imageUrl = serverURL + '/public/bubble/images/' + res.data.imgFileName;
                setUploadedImageFileName(res.data.imgFileName);
                setUploadedImageUrl(imageUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }
    const handleSoundUpload = (e) => {
        e.preventDefault();
        if(!isAudioFile(selectedSound[0])) {
            message.error("Not an audio file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedSound[0]);
        // const fileNameID = lessonInfo.round + "-" + selectedProblemIndex + "-" + selectedBubbleIndex;

        api.post(`/api/bubble/temp/lesson/sound_upload`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setSoundUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("success", "Successfully audio uploaded");
                const soundUrl = serverURL + '/public/bubble/audios/' + res.data.soundFileName;
                setUploadedSoundFileName(res.data.soundFileName);
                setUploadedSoundUrl(soundUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedImage = (e) => {
        setSelectedImage(e.target.files);
    }

    const changeSelectedSound = (e) => {
        setSelectedSound(e.target.files);
    }

    const handleSave = () => {
        const info = {
            bid: selectedBubble,
            image: uploadedImageFileName,
            sound: uploadedSoundFileName
        }
        dispatch(setLessonFieldStatus({status: "idle"}));
        dispatch(editOneBubble(info));
        hideModal();
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }

    const columns = [
        {
        title: 'No',
        dataIndex: 'no',
        width: '10%',
        render: (text, record, index) => {
            const i = lessonInfo.bubbles.findIndex(bubble => bubble === record);
            return i + 1;
        }
        },
        {
        title: 'Image',
        dataIndex: 'image',
        width: '35%',
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/bubble/images/" + text} style={{width: 100, height:100}} alt="image"/>
        }
        },
        {
        title: 'Sound',
        dataIndex: 'sound',
        width: '35%',
        render: (text, record, index) => {
            return (
                <audio controls style={{width: 300}} src={serverURL + "/public/bubble/audios/" + text}/>
            )
        }
        },
        {
        title: 'Operation',
        dataIndex: 'operation',
        render: (_, record) => (
            <Space size = "middle" >

                <Typography.Link  onClick={() => handleEdit(record)} style={{ color: "blue"}}> 
                    <Tooltip title="Edit" >
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link> 

                <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return (
        <>
            <Title level={2} style={{textAlign: "center"}}>Bubbles for Lesson {lessonInfo.lesson}</Title>
        
            <Button type="primary" size='middle' onClick={() => handleProblemAdd()} className = "addRound"> Add Bubble </Button>
            <Table
                className="MyPracticeDetailTable"
                bordered
                dataSource={lessonInfo.bubbles}
                columns={columns}
                loading={lessonInfo.loading}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Letter</h2>} open={isModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleImageUpload(e)}>
                            <label for="file-input"> Image :&nbsp;</label>
                            <input type="file" className="file-input" name="my-letter-image" onChange= {(e)=>changeSelectedImage(e)} />
                            <button className="upload-button" type="submit"> Upload</button>
                        </form>             
                        <Image src={uploadedImageUrl} alt='image' style={{width: "100%"}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={imageUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                

                <form onSubmit={(e)=>handleSoundUpload(e)}>
                    <label for="file-input">Sound :&nbsp;</label>
                    <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedSound(e)} />
                    <button className="upload-button" type="submit">Upload</button>
                </form>

                <audio ref={audioRef} controls src={uploadedSoundUrl} />
                <Box sx={{ width: '95%' }}>
                    <LinearProgress variant="determinate" value={soundUploadPercent} />
                </Box>
                
                </Space>
            </Modal>

        </>
    )
}

export default BubbleLessonDetail;