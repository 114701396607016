import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { errorNotification } from "../../../utilities/Notification";

export const fetchPayInfo= createAsyncThunk('/piano/pay_info/fetch', async () => {
    const response = await api.get(`/api/pay_info/get_amount`);
    console.log(response.data);
    return response.data;
});

const payInfoSlice = createSlice({
  name: "payInfo",
  initialState: { status: "idle", error: null, loading: false, total_amount: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPayInfo.pending, (state) => {
          state.status = "loading";
          state.loading = true;
      })
      .addCase(fetchPayInfo.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.loading = false;
          state.total_amount = action.payload.totalAmount;
      })
      .addCase(fetchPayInfo.rejected, (state, action) => {
          state.status = "failed";
          state.loading = false;
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
      })
  },
});

export default payInfoSlice.reducer;