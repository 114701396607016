import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import {Popconfirm, Table, Typography, Button, Modal, Divider, message, Select, Space, Badge} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { fetchUnits, editUnit, deleteOneUnit, addUnit,} from "../../../../../features/piano/unit/unitSlice";
import { fetchLessonCount, fetchLessonUsedInfo, } from "../../../../../features/piano/lesson/practiceSlice";

import "./UnitTable.css"

const UnitTable = (props) => {
    const { type } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const unitInfo = useSelector((state) => state.unit);
    const practiceField = useSelector((state) => state.practiceField);
    const topicInfo = useSelector((state) => state.topic);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = (content) => {
        messageApi.open({
          type: 'warning',
          content: content,
        });
    };

    useEffect(() => {
        if(unitInfo.status === "idle") {
            dispatch(fetchLessonUsedInfo());
            dispatch(fetchLessonCount());
            dispatch(fetchUnits({type: type}));
        }
    }, [dispatch, unitInfo, type]);

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
    });
    const [selectedUnitInfo, setSelectedUnitInfo] = useState({unit: 1, lessons: [], title: "", _id: ""});
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [isEditModal, setIsEditModalOpen] = useState(false);

    let maxUnit = 0;

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };
    const handleUnitAdd = () => {
        setSelectedUnitInfo({
            unit: maxUnit + 1,
            title: "",
            lessons: [],
            _id: ""
        });
        showAddModal();
    }
    
    // Add Modal

    const showAddModal = () => {
        setIsAddModalOpen(true);
    };

    const showEditModal = () => {
        setIsEditModalOpen(true);
    };

    const handleCreate = () => {
        if(isNaN(selectedUnitInfo.unit)) {
            warning('Please input only number!');
            return;
        }
        dispatch(addUnit({...selectedUnitInfo, type: type}));
        setIsAddModalOpen(false);
    };

    const handleCancel = () => {
        setIsAddModalOpen(false);
        setIsEditModalOpen(false);
        setSelectedUnitInfo(prev => {
            return {...prev, lessons: []};
        });
    };

    const changeSelectedUnit = (e) => {
        setSelectedUnitInfo(prev => {
          return {...prev, unit: e.target.value};
        });
    }
  
    const changeSelectedLessons = (value) => {
        setSelectedUnitInfo(prev => {
            return {...prev, lessons: value};
        });
    };

    const changeSelectedUnitTitle = (e) => {
        setSelectedUnitInfo(prev => {
          return {...prev, title: e.target.value}
        })
    }

    //Operation (edit, delete)
    const handleEdit = (record) => {
        setSelectedUnitInfo({
            unit: record.unit,
            title: record.title,
            lessons: record.lessons,
            _id: record._id
        })
        showEditModal();
    }

    const handleSave = () => {
        if(isNaN(selectedUnitInfo.unit)) {
            warning('Please input only number!');
            return;
        }
        dispatch(editUnit({...selectedUnitInfo, type: type}));
        setIsEditModalOpen(false);
    };

    const deleteUnit = (record) => {
        dispatch(deleteOneUnit({id: record._id, type: type}));
    }
    
    const options = [];
    if(practiceField.usedInfo.length > 0) {
        for (let i = 1; i <= practiceField.count; i++) {
            if(!practiceField.usedInfo[i - 1]) {continue; }
            options.push({
                label:  
                        <Space>
                            {practiceField.usedInfo[i - 1].round}
                            {
                                topicInfo.topics.map((element, index) => (
                                    practiceField.usedInfo[i - 1].used.includes(element.no) ? <Badge color={element.badge} /> : null
                                ))
                            }
                        </Space>,
                value: practiceField.usedInfo[i - 1]._id,
                round: practiceField.usedInfo[i - 1].round,
                used: practiceField.usedInfo[i - 1].used,
            });
        }
    }

    const getRoundFromId = (id) => {
        const result = options.find((item) => item.value === id);
        if(result) {
            return result.round;
        }
        return null;
    }

    const getUsedFromId = (id) => {
        const result = options.find((item) => item.value === id);
        if(result) {
            return result.used;
        }
        return [];
    }

    // const lessonColumns = [
    //     {
    //     title: 'No', 
    //     dataIndex: 'no',
    //     width: '15%',
    //     render: (text, record, index) => (index + 1),
    //     },
    //     {
    //     title: 'Lesson',
    //     dataIndex: 'lesson',
    //     width: "30%",
    //     ellipse: true
    //     },
    //     {
    //     title: 'Status',
    //     dataIndex: 'status',
    //     width: "20%",
    //     ellipse: true,
    //     render: (text, record, index) => {
    //         return (<Tooltip title="Used in Topic1"><Badge color="red" /></Tooltip>)
    //     }
    //     },
    // ]

    const columns = [
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'no',
            width: '12%',
            sorter: (a, b) => b.unit - a.unit,
            defaultSortOrder: 'descend',
            editable: true,
            ellipsis: true,
            render : (text, record, index) => {
                maxUnit = maxUnit > record.unit ? maxUnit : record.unit
                return record.unit;
            }
            // render: (text, record, index) => {
            //     return (pagination.current - 1) * pagination.pageSize + index + 1;
            // }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        // {
        //     title: 'From (Lesson)',
        //     dataIndex: 'start',
        //     key: 'start',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     editable: true,
        //     ellipsis: true,
        // },
        // {
        //     title: 'To (Lesson)',
        //     dataIndex: 'end',
        //     key: 'end',
        //     width: '15%',
        //     textWrap: 'word-break',
        //     editable: true,
        //     ellipsis: true,
        //     render : (text, record, index) => {
        //         maxLesson = maxLesson > record.end ? maxLesson : record.end
        //         return record.end;
        //     }
        // },
        {
            title: 'Lessons',
            dataIndex: 'lessons',
            key: 'lessons',
            width: '40%',
            textWrap: 'word-break',
            ellipse: true,
            render: (text, record, index) => {
                // const data =[];
                // for (let i = 0; i < text.length; i ++) {
                //     data.push({lesson: text[i]});
                // }
                // return (<Table
                //     style={{fontSize:15}}
                //     dataSource={data}
                //     columns={lessonColumns}
                //     pagination={false}
                // />)
                return (
                    <>
                        {text.map((element, index) => (
                            <Button style={{marginRight: 20, backgroundColor: "#f9ffe5", marginBottom: 10}} onClick={() => navigate(`/home/piano/lesson/${element}`)} >
                            <Space>
                            {getRoundFromId(element)}
                            {
                                topicInfo.topics.map((elem, index) => (
                                    getUsedFromId(element).includes(elem.no) ? <Badge color={elem.badge} /> : null
                                ))
                            }
                            </Space>
                            </Button>
                        ))}
                    </>
                )
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteUnit(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return(
        <>
            {contextHolder}
            <Button type="primary" size='middle' onClick={() => handleUnitAdd()} className = "addRound"> Add Unit </Button>
                <Table
                    loading={unitInfo.loading}
                    bordered
                    dataSource={unitInfo.units}
                    columns={columns}
                    pagination={pagination}
                    onChange={handleTableChange}  
                    className="unitTable"
                    rowKey={(record) => record._id}
                />

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"New Unit"}</h2>} open={isAddModal} onOk={handleCreate} onCancel={handleCancel} okText="Create">
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                    label="unit"
                    sx={{ m: 1, width: '20ch' }}
                    value = {selectedUnitInfo.unit}
                    onChange = {changeSelectedUnit}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Title"
                    sx={{ m: 1, width: "100%" }}
                    value = {selectedUnitInfo.title}
                    onChange = {changeSelectedUnitTitle}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                
                <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    style={{ width: '100%', marginTop: 15 }}
                    placeholder="Please select"
                    value={selectedUnitInfo.lessons}
                    onChange={changeSelectedLessons}
                    options={options}
                />
                <div style={{marginTop: 30, marginLeft: 30}}>
                    <h6>Description:</h6> 
                    {
                        topicInfo.topics.map((element, index) => (
                            <p>
                            <Badge dot color={element.badge}></Badge> : This lesson is already used in Topic {element.no}.
                            </p>
                        ))
                    }
                </div>
                {/* <TextField
                  label="From"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedUnitInfo.start}
                  onChange = {changeSelectedUnitStart}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <TextField
                  label="To"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedUnitInfo.end}
                  onChange = {changeSelectedUnitEnd}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                /> */}
                
                
              </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"New Unit"}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                    label="unit"
                    sx={{ m: 1, width: '20ch' }}
                    value = {selectedUnitInfo.unit}
                    onChange = {changeSelectedUnit}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                <TextField
                    label="Title"
                    sx={{ m: 1, width: "100%" }}
                    value = {selectedUnitInfo.title}
                    onChange = {changeSelectedUnitTitle}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                />
                
                <Select
                    mode="multiple"
                    size="large"
                    allowClear
                    style={{ width: '100%', marginTop: 15 }}
                    placeholder="Please select"
                    value={selectedUnitInfo.lessons}
                    onChange={changeSelectedLessons}
                    options={options}
                />
                <div style={{marginTop: 30, marginLeft: 30}}>
                    <h6>Description:</h6> 
                    <p>
                        {
                            topicInfo.topics.map((element, index) => (
                                <p>
                                <Badge dot color={element.badge}></Badge> : This lesson is used in Topic {element.no} at least once.
                                </p>
                            ))
                        }
                    </p>
                </div>
              </Box>
            </Modal>
        </>
    )
}

export default UnitTable;