import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPayInfo } from '../../../../../features/piano/payInfo/payInfo';


import './PianoPayInfo.css';

const PianoPayInfo = () => {

  const dispatch = useDispatch();

  const payInfo = useSelector(state => state.payInfo);

  useEffect(() => {
    dispatch(fetchPayInfo());
  }, []);

  return (
    <>
      <span style = {{float: 'right', fontSize: 20, fontWeight: 'bold'}} className="total-payment">Total Payment : ${payInfo.total_amount}</span>
    </>
  )
}

export default PianoPayInfo;