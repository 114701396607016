import React from 'react';
import {
  MDBFooter,
} from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <MDBFooter  className='text-black text-center text-lg-left'>
      <div className='text-center p-3' style={{backgroundColor: 'rgba(255, 255, 255, 0.8)', borderRadius: 12, marginLeft: 5, marginRight: 5 }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
          Almedad Education
      </div>
    </MDBFooter>
  );
}

export default Footer;