import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";
import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchOneDetail = createAsyncThunk(
  "/quran/fetchOneDetail",
  async (lid) => {
    const response = await api.get(`/api/quran/temp/text/get_one_detail/${lid}`);
    return response.data;
  }
);

export const addOneDetail = createAsyncThunk(
  "/quran/addOneDetail",
  async (lid) => {
    const response = await api.post(`/api/quran/temp/text/add_detail/${lid}`);
    return response.data;
  }
);

export const editOneDetail = createAsyncThunk(
  "/quran/editOneDetail",
  async (data) => {
    const response = await api.post(`/api/quran/temp/text/edit_detail/${data.bid}`, data);
    return response.data;
  }
);

export const deleteOneDetail = createAsyncThunk(
  "/quran/deleteOneDetail",
  async (bid) => {
    const response = await api.delete(`/api/quran/temp/text/delete_one_detail/${bid}`);
    return response.data;
  }
);

const textDetailSlice = createSlice({
  name: "quranContentDetailField",
  initialState: { details: [], status: "idle", error: null, message: "", loading: false, lid: "", lesson: 1 },
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchOneDetail.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchOneDetail.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.details = action.payload.details;
            state.lesson = action.payload.lesson;
            state.loading = false;
        })
        .addCase(fetchOneDetail.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneDetail.pending, (state) => {
          state.status = "loading";
        })
        .addCase(addOneDetail.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully add!")
            state.status = "idle";
        })
        .addCase(addOneDetail.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneDetail.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneDetail.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneDetail.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneDetail.pending, (state) => {
          state.status = "loading";
        })
        .addCase(deleteOneDetail.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneDetail.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default textDetailSlice.reducer;
