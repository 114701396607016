import React, { useRef, useState, useEffect } from 'react';
import { Button, Space, Table, Typography, Popconfirm, Popover, Input, Image } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Highlighter from 'react-highlight-words';
import TextField from '@mui/material/TextField';

import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers, deleteOneUser, forgetPianoUserPassword, addCoinsToUser } from '../../../../features/piano/user/pianoUserSlice';
import { serverURL } from '../../../../config/config';
import "./User.css";

const PianoUser = () => {

    const dispatch = useDispatch();
    
    const userField = useSelector((state) => state.pianoUser);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {
            name: "",
            email: "",
            nation: ""
        },
        sorter: {
            level: 0,
            total: 0
        }
    });
    const searchInput = useRef(null);
    const coinInputRef = useRef(null);

    useEffect(() => {
        if(userField.status === "idle") {
            dispatch(fetchUsers(tableParams));
        }
    }, [dispatch, userField]);
    
    useEffect(() => {
        dispatch(fetchUsers(tableParams));
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevState => ({ 
            ...prevState,
            pagination
        }))
        if(sorter) {
            let flag1 = 0;
            let flag2 = 0;
            if(sorter.field === "level") {
                if(sorter.order === "ascend") {
                    flag1 = -1;
                }
                if(sorter.order === "descend") {
                    flag1 = 1;
                }
            }
            if(sorter.field === "total") {
                if(sorter.order === "ascend") {
                    flag2 = -1;
                }
                if(sorter.order === "descend") {
                    flag2 = 1;
                }
            }
            setTableParams(prevState => ({
                ...prevState,
                sorter : {
                    level : flag1,
                    total : flag2
                }
            }))
        }
    };

    //operation

    const handleForgetPassword = (record) => {
        dispatch(forgetPianoUserPassword({email: record.email}))
    }

    const handleDelete = (record) => {
        dispatch(deleteOneUser(record._id));
    }

    //For search function in antd Table

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        setTableParams(prevState => ({ 
            ...prevState,
            filters: {
                ...prevState.filters,
                [dataIndex]: selectedKeys[0]
            }
        }))
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
        <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
            marginBottom: 8,
            display: 'block',
        }}
        />
        <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
        </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    const columns = [
        {
            title: 'No',
            key: 'No',
            width: '6%',
            ellipse: true,
            render: (text, record, index) => {
                return (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Avatar',
            dataIndex: 'avatar',
            key: 'avatar',
            width: '9%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <Image src={serverURL + "/public/uploads/avatar/" + text} style={{ height: 80}} alt="img"/>
            }
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Nation',
            dataIndex: 'nation',
            key: 'nation',
            width: '12%',
            textWrap: 'word-break',
            ellipsis: true,
            render: (text, record, index) => {
                return <Image src={serverURL + "/public/Flags/" + text + ".png"} style={{ width: 60}} alt="img"/>
            }
        },
        {
            title: 'Coin',
            dataIndex: 'coin',
            key: 'coin',
            width: '10%',
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            width: '10%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Score',
            dataIndex: 'total',
            key: 'total',
            width: '10%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
          title: 'Operation',
          dataIndex: 'operation',
          render: (_, record) => {
            const isPopoverOpen = openPopoverId === record._id;
            return (
                <div>
                    <Space size="middle" >
                        <Popconfirm title="Sure to send message?" onConfirm={() => handleForgetPassword(record)}>
                            <Typography.Link style={{ color: "green"}}>
                                <Tooltip title="Send password reset email">
                                    <Icon sx={{fontSize: 20}}>mail_lock</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>
                        <Popover title="Sure to Add coins to this user?"
                            open={isPopoverOpen}
                            trigger="click"
                            content={
                                <div>
                                    <div>
                                        <TextField id="outlined-basic" label="+ Coin Amount" variant="outlined" size="small" inputRef={coinInputRef}/>
                                    </div>
                                    <Space size="small" style={{marginTop: 10, marginLeft: 80}}>
                                        <Button onClick={() => setOpenPopoverId(null)} size="small">Cancel</Button>
                                        <Button onClick={() => handleConfirmAddCoin(record)} size="small" type="primary">Confirm</Button>
                                    </Space>
                                </div>
                            }
                        >
                            <Typography.Link style={{ color: "#ff9900"}} onClick={() => handlePopoverOpen(record._id)} >
                                <Tooltip title="Add Coins">
                                    <Icon sx={{fontSize: 20}}>loupe</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popover>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                            <Typography.Link style={{ color: "red"}}>
                                <Tooltip title="Delete">
                                    <Icon sx={{fontSize: 18}}>delete</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                </div>
            )
          }
        },
    ];

    //coin popover operation
    const [openPopoverId, setOpenPopoverId] = useState(null);
    const handlePopoverOpen = (recordId) => {
        setOpenPopoverId(recordId);
    };
    const handleConfirmAddCoin = (record) => {
        setOpenPopoverId(null);
        dispatch(addCoinsToUser({
            id: record._id,
            amount: coinInputRef.current.value
        }));
    };

    return(
        <>
            <Table
                className="userTable"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={userField.users}
                pagination={{...tableParams.pagination, total: userField.amount, showSizeChanger: true, showTotal: (total) => `Total : ${total} users` }}
                loading={userField.loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default PianoUser;