import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";

import { successNotification, errorNotification } from "../../utilities/Notification";

export const fetchVideos = createAsyncThunk('/video/fetch', async (data) => {
    const response = await api.get(`/api/video/get_all_video`, data);
    return response.data;
});

export const addVideo = createAsyncThunk('video/add', async (data) => {
    const response = await api.post(`/api/video/add_video`, data);
    return response;
})

export const editVideo = createAsyncThunk('video/edit', async (data) => {
    const response = await api.post(`/api/video/edit_video/${data._id}`, data);
    return response.data;
});

export const deleteOneVideo = createAsyncThunk('/video/deleteOneVideo', async (id) => {
    const response = await api.delete(`/api/video/delete_one/${id}`);
    return response.data;
});

const videoSlice = createSlice({
  name: "video",
  initialState: { videos:[],  status: "idle", error: null, loading: false, amount: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchVideos.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchVideos.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.videos = action.payload;
        })
        .addCase(fetchVideos.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editVideo.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(editVideo.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully edited!");
        })
        .addCase(editVideo.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addVideo.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(addVideo.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully added!");
        })
        .addCase(addVideo.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneVideo.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneVideo.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneVideo.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
    },
});

export const {} = videoSlice.actions;

export default videoSlice.reducer;