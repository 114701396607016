import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../config/api";

import { errorNotification } from "../../utilities/Notification";

export const fetchScores = createAsyncThunk('/score/fetch', async (data) => {
    const response = await api.post(`/api/score/admin/get_page`, data);
    return response.data;
});

const scoreSlice = createSlice({
    name: "score",
    initialState: { scores:[],  status: "idle", error: null, loading: false, amount: 0},
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchScores.pending, (state) => {
                state.status = "loading";
                state.loading = true;
            })
            .addCase(fetchScores.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.loading = false;
                state.amount = action.payload.amount;
                state.scores = action.payload.scores;
            })
            .addCase(fetchScores.rejected, (state, action) => {
                state.status = "failed";
                state.loading = false;
                state.error = action.error.message;
                if(action.error.message === "Request failed with status code 403") {
                  state.error = "Your token is expired or invalid! Please try to login again."
                }
                errorNotification("Failed!", state.error);
            })
    },
});

export default scoreSlice.reducer;