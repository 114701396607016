import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {Popconfirm, Typography, Space, Table, Button, Modal, Divider, ColorPicker, Badge, message} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { fetchAllTopics, addOneTopic, editOneTopic, deleteOneTopic } from "../../../../features/piano/topic/topicSlice";

import './Topic.css';

const Topic = () => {
    const dispatch = useDispatch();
    const topicInfo = useSelector(state => state.topic);

    const [messageApi, contextHolder] = message.useMessage();
    const warning = (content) => {
        messageApi.open({
          type: 'warning',
          content: content,
        });
    };

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20'],
    });
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [selectedTopicInfo, setSelectedTopicInfo] = useState({no: 0, title: "", name: "", coin: 0, badge: "#ffffff", id: ""});

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    useEffect(() => {
        if(topicInfo.status === "idle") {
          dispatch(fetchAllTopics());
        }
      }, [dispatch, topicInfo]);

      let maxNo = 0;

    //Table operation

    const handleAddTopic = () => {
        setSelectedTopicInfo({ no: maxNo + 1, title: "", name: "", coin: 0, badge: "#000000", id: ""});
        setIsAddModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedTopicInfo({
            no: record.no,
            title: record.title,
            name: record.name,
            badge: record.badge,
            coin: record.coin,
            id: record._id
        });
        setIsEditModalOpen(true);
    }

    const deleteTopic = (record) => {
        dispatch(deleteOneTopic({id: record._id, no: record.no}));
    }

    //modal operation

    const handleCreate = () => {
        if(selectedTopicInfo.title === "") {
            warning("Please input title.");
            return;
        }
        if(selectedTopicInfo.name === "") {
            warning("Please input name.");
            return;
        }
        dispatch(addOneTopic(selectedTopicInfo));
        setIsAddModalOpen(false);
    }

    const handleSave = () => {
        dispatch(editOneTopic(selectedTopicInfo));
        setIsEditModalOpen(false);
    }

    const handleCancel = () => {
        setIsEditModalOpen(false);
        setIsAddModalOpen(false);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            width: '10%',
            sorter: (a, b) => b.unit - a.unit,
            defaultSortOrder: 'descend',
            editable: true,
            ellipsis: true,
            render : (text, record, index) => {
                if(maxNo < text) maxNo = text;
                return text;
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '35%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Coin',
            dataIndex: 'coin',
            key: 'coin',
            width: '15%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Badge Color',
            dataIndex: 'badge',
            key: 'badge',
            width: "12%",
            render: (text, record, index) => (<Badge color={text} />)
                
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => deleteTopic(record)}>
                        <Typography.Link style={{ color: "red"}}>
                            <Tooltip title="Delete">
                                <Icon sx={{fontSize: 16}}>delete</Icon>
                            </Tooltip>
                        </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return(
        <>
            {contextHolder}
            <Button type="primary" size='middle' onClick={() => handleAddTopic()} className = "addTopic"> Add Topic </Button>
            <Table
                loading={topicInfo.loading}
                bordered
                dataSource={topicInfo.topics}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
                rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Topic</h2>} open={isAddModal} onOk={handleCreate} onCancel={handleCancel} okText="Create">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="No"
                    sx={{ m: 1, width: '12ch' }}
                    value = {selectedTopicInfo.no}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, no: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    disabled
                    />
                    <TextField
                    label="Coin"
                    sx={{ m: 1, width: '20ch' }}
                    value = {selectedTopicInfo.coin}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, coin: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <TextField
                    label="Name"
                    sx={{ m: 1, width: '24ch' }}
                    value = {selectedTopicInfo.name}
                    placeholder="Just used in AdminSite."
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, name: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    

                    <TextField
                    label="Title"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedTopicInfo.title}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, title: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <div style={{marginLeft: 10}}>
                    <label style={{fontSize: 16, verticalAlign: "super"}}> Badge Color : &nbsp;</label>
                    <ColorPicker 
                    style={{marginTop: 10}}                    
                    showText
          
                    value={selectedTopicInfo.badge} 
                    onChange={(color) => setSelectedTopicInfo({...selectedTopicInfo, badge: color.toHexString()})}
                    />
                    </div>
                </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>Topic {selectedTopicInfo.no}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="Name"
                    sx={{ m: 1, width: '25ch' }}
                    value = {selectedTopicInfo.name}
                    placeholder="Just used in AdminSite."
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, name: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />

                    <TextField
                    label="Required Coin to open"
                    sx={{ m: 1, width: '25ch' }}
                    value = {selectedTopicInfo.coin}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, coin: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />

                    <TextField
                    label="Title"
                    sx={{ m: 1, width: '100%' }}
                    value = {selectedTopicInfo.title}
                    onChange = {(e) => setSelectedTopicInfo({...selectedTopicInfo, title: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <div style={{marginLeft: 10}}>
                    <label style={{fontSize: 16, verticalAlign: "super"}}> Badge Color : &nbsp;</label>
                    <ColorPicker 
                    style={{marginTop: 10}}                    
                    showText
          
                    value={selectedTopicInfo.badge} 
                    onChange={(color) => setSelectedTopicInfo({...selectedTopicInfo, badge: color.toHexString()})}
                    />
                    </div>
                </Box>
            </Modal>

            
            
        </>
    );
}

export default Topic;