import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/authSlice";
import themeSlice from "./features/themeSlice";

import adminSlice from "./features/admin/adminSlice";

import topicSlice from "./features/piano/topic/topicSlice";
import unitSlice from "./features/piano/unit/unitSlice";
import avatarSlice from "./features/piano/shop/avatar/avatarSlice";
import coinSlice from "./features/piano/shop/coin/coinSlice";
import paymentSlice from "./features/piano/payment/paymentSlice";
import payInfoSlice from "./features/piano/payInfo/payInfo"
import backgroundSlice from "./features/piano/background/backgroundSlice";
import notificationSlice from "./features/piano/notification/notificationSlice";

import pianoUserSlice from "./features/piano/user/pianoUserSlice";
import practiceSlice from "./features/piano/lesson/practiceSlice";
import test1Slice from "./features/piano/test/test1/test1Slice";
import test2Slice from "./features/piano/test/test2/test2Slice";
import test3Slice from "./features/piano/test/test3/test3Slice";
import test4Slice from "./features/piano/test/test4/test4Slice";
import test5Slice from "./features/piano/test/test5/test5Slice";
import practiceRoundSlice from "./features/piano/lesson/practiceRoundSlice";
import test1RoundSlice from "./features/piano/test/test1/test1RoundSlice";
import test2RoundSlice from "./features/piano/test/test2/test2RoundSlice";
import test3RoundSlice from "./features/piano/test/test3/test3RoundSlice";
import test4RoundSlice from "./features/piano/test/test4/test4RoundSlice";
import test5RoundSlice from "./features/piano/test/test5/test5RoundSlice";
import test5ProblemSlice from "./features/piano/test/test5/test5ProblemSlice";

import versionSlice from "./features/piano/versionSlice";

import scoreSlice from "./features/unused/scoreSlice";
import videoSlice from "./features/unused/videoSlice";

import bubbleUserSlice from "./features/bubble/user/bubbleUserSlice";
import bubblePaymentSlice from "./features/bubble/payment/bubblePaymentSlice";
import bubblePayInfoSlice from "./features/bubble/payInfo/bubblePayInfo";
import bubbleBackgroundSlice from "./features/bubble/background/bubbleBackgroundSlice";
import bubbleTopicBackgroundSlice from "./features/bubble/topic_background/bubbleTopicBackgroundSlice";
import bubbleLessonSlice from "./features/bubble/lesson/bubbleLessonSlice";
import bubbleLessonDetailSlice from "./features/bubble/lesson/bubbleLessonDetailSlice";
import bubbleSoundSlice from "./features/bubble/sound/bubbleSoundSlice";
import bubbleVersionSlice from "./features/bubble/bubbleVersionSlice";
import bubbleNotificationSlice from "./features/bubble/notification/bubbleNotificationSlice";

import ebubbleUserSlice from "./features/ebubble/user/ebubbleUserSlice";
import ebubblePaymentSlice from "./features/ebubble/payment/ebubblePaymentSlice";
import ebubblePayInfoSlice from "./features/ebubble/payInfo/ebubblePayInfo";
import ebubbleBackgroundSlice from "./features/ebubble/background/ebubbleBackgroundSlice";
import ebubbleTopicBackgroundSlice from "./features/ebubble/topic_background/ebubbleTopicBackgroundSlice";
import ebubbleLessonSlice from "./features/ebubble/lesson/ebubbleLessonSlice";
import ebubbleLessonDetailSlice from "./features/ebubble/lesson/ebubbleLessonDetailSlice";
import ebubbleSoundSlice from "./features/ebubble/sound/ebubbleSoundSlice";
import ebubbleVersionSlice from "./features/ebubble/ebubbleVersionSlice";
import ebubbleNotificationSlice from "./features/ebubble/notification/ebubbleNotificationSlice";

import quranUserSlice from "./features/quran/user/quranUserSlice";
import quranContentSlice from "./features/quran/text/textSlice";
import quranRecitersSlice from "./features/quran/reciters/recitersSlice";
import quranRecitationSlice from "./features/quran/recitation/recitationSlice";
import quranSuraSlice from "./features/quran/sura/suraSlice";
import quranJuzSlice from "./features/quran/juz/juzSlice";
import quranContentDetailSlice from "./features/quran/text/textDetailSlice";
// import quranVersionSlice from "./features/quran/textVersionSlice";
const store = configureStore({
    reducer: { 
        auth: authReducer,
        practiceRound: practiceRoundSlice,
        practiceField: practiceSlice,
        test1Field: test1Slice,
        test1Round: test1RoundSlice,
        test2Field: test2Slice,
        test2Round: test2RoundSlice,
        test3Field: test3Slice,
        test3Round: test3RoundSlice,
        test4Field: test4Slice,
        test4Round: test4RoundSlice,
        test5Field: test5Slice,
        test5Round: test5RoundSlice,
        test5Problem: test5ProblemSlice,
        theme: themeSlice,
        version: versionSlice,
        admin: adminSlice,
        pianoUser: pianoUserSlice,
        score: scoreSlice,
        video: videoSlice,
        unit: unitSlice,
        topic: topicSlice,
        avatar: avatarSlice,
        coin: coinSlice,
        payment: paymentSlice,
        payInfo: payInfoSlice,
        background: backgroundSlice,
        notification: notificationSlice,
        bubbleUser: bubbleUserSlice,
        bubblePayment: bubblePaymentSlice,
        bubblePayInfo: bubblePayInfoSlice,
        bubbleBackground: bubbleBackgroundSlice,
        bubbleTopicBackground: bubbleTopicBackgroundSlice,
        bubbleLessonField: bubbleLessonSlice,
        bubbleVersion: bubbleVersionSlice,
        bubbleSound: bubbleSoundSlice,
        bubbleLessonDetail: bubbleLessonDetailSlice,
        bubbleNotification: bubbleNotificationSlice,
        ebubbleUser: ebubbleUserSlice,
        ebubblePayment: ebubblePaymentSlice,
        ebubblePayInfo: ebubblePayInfoSlice,
        ebubbleBackground: ebubbleBackgroundSlice,
        ebubbleTopicBackground: ebubbleTopicBackgroundSlice,
        ebubbleLessonField: ebubbleLessonSlice,
        ebubbleVersion: ebubbleVersionSlice,
        ebubbleSound: ebubbleSoundSlice,
        ebubbleLessonDetail: ebubbleLessonDetailSlice,
        ebubbleNotification: ebubbleNotificationSlice,

        quranUser: quranUserSlice,
        quranContentField: quranContentSlice,
        quranContentDetailField: quranContentDetailSlice,
        // quranVersion: quranVersionSlice,
        quranReciters: quranRecitersSlice,
        quranRecitation: quranRecitationSlice,
        quranSuraAyah: quranSuraSlice,
        quranJuzHizb: quranJuzSlice,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
        serializableCheck: false,
    })
});

export default store;
