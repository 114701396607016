import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { successNotification, errorNotification } from "../../utilities/Notification";
import { api } from "../../config/api";


export const fetchBubbleVersion = createAsyncThunk('/version/fetchEBubbleVersion', async () => {
  const response = await api.get(`/api/english_bubble/version/admin/get_all_version`);
  return response.data;
});

export const editBubbleLessonVersion  = createAsyncThunk(
  "/version/editEBubbleLessonVersion",
  async (data) => {
    const response = await api.post(`/api/english_bubble/version/edit_lesson_version`, data);
    return response.data;
  }
);

export const editBubbleBackgroundVersion  = createAsyncThunk(
  "/version/editEBubblebackgroundVersion",
  async (data) => {
    const response = await api.post(`/api/english_bubble/version/edit_background_version`, data);
    return response.data;
  }
);

export const editBubbleTopicBackgroundVersion  = createAsyncThunk(
  "/version/editEBubbleTopicbackgroundVersion",
  async (data) => {
    const response = await api.post(`/api/english_bubble/version/edit_topic_background_version`, data);
    return response.data;
  }
);

export const editBubbleSoundVersion  = createAsyncThunk(
  "/version/editEBubbleSoundVersion",
  async (data) => {
    const response = await api.post(`/api/english_bubble/version/edit_sound_version`, data);
    return response.data;
  }
);

const ebubbleVersionSlice = createSlice({
  name: "version",
  initialState: {versions: [], status: "idle", lessonVersion: 0.0, backgroundVersion: 0.0, soundVersion: 0.0, topicBackgroundVersion: 0.0},
  reducers: {
    setBubbleVersionStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchBubbleVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchBubbleVersion.fulfilled, (state, action) => {
          state.status = "succeeded";
          console.log(action.payload);
          state.versions = action.payload;
          state.lessonVersion = action.payload[0].version;
          state.backgroundVersion = action.payload[1].version;
          state.soundVersion = action.payload[2].version;
          state.topicBackgroundVersion = action.payload[3].version;
        })
        .addCase(fetchBubbleVersion.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
          if(action.error.message === "Request failed with status code 403") {
            state.error = "Your token is expired or invalid! Please try to login again."
          }
          errorNotification("Failed!", state.error);
        })
        .addCase(editBubbleLessonVersion.pending, (state) => {
            state.status = "loading";
        })
        .addCase(editBubbleLessonVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editBubbleLessonVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editBubbleBackgroundVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editBubbleBackgroundVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editBubbleBackgroundVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editBubbleSoundVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editBubbleSoundVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editBubbleSoundVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editBubbleTopicBackgroundVersion.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editBubbleTopicBackgroundVersion.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully updated!")
            state.status = "idle";
        })
        .addCase(editBubbleTopicBackgroundVersion.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export const {setBubbleVersionStatus} = ebubbleVersionSlice.actions;
export default ebubbleVersionSlice.reducer;