import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Popconfirm, Table, Typography, Button, Modal, Divider, Space, message} from 'antd';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { fetchAllCoinBundles, addOneCoinBundle, editOneCoinBundle, deleteOneCoinBundle, editTag } from "../../../../../features/piano/shop/coin/coinSlice";

import './Coin.css';

const Coin = () => {
    const dispatch = useDispatch();
    
    const coinInfo = useSelector(state => state.coin);
    const [selectedBundles, setSelectedBundles] = useState([]);
    const [offRate, setOffRate] = useState(0);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        //   console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          const selectedIds = selectedRows.map(row => row._id);
          setSelectedBundles(selectedIds);
        },
    };

    const [pagination, setPagination] = useState({
        pageSize: 10,
        current: 1,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
    });
    const [selectedCoinBundle, setSelectedCoinBundle] = useState({
        coin: 0,
        amount: 0.0,
        id: ""
    });

    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [isAddModal, setIsAddModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchAllCoinBundles());
    }, [])

    const handleTableChange = (pagina, filters, sorter) => {
        setPagination(pagina);
    };

    const handleAdd = () => {
        setSelectedCoinBundle({
            coin: 0,
            amount: 0.0,
            id: ""
        });
        setIsAddModalOpen(true);
    }

    const handleEdit = (record) => {
        setSelectedCoinBundle({
            coin: record.coin,
            amount: record.amount,
            id: record._id
        });
        setIsEditModalOpen(true);
    };

    const handleDelete = (record) => {
        dispatch(deleteOneCoinBundle(record._id));
    };

    const handleSave = () => {
        dispatch(editOneCoinBundle(selectedCoinBundle));
        setIsEditModalOpen(false);
    };

    const handleOk = () => {
        dispatch(addOneCoinBundle(selectedCoinBundle));
        setIsAddModalOpen(false);
    };

    const handleCancel = () => {
        setIsAddModalOpen(false);
        setIsEditModalOpen(false);
    }

    // Tag operation

    const handleAddTag = () => {
        if(isNaN(offRate)) {
            message.error("Off-Rate is not a number!", 1.5);
            return;
        }
        if(offRate > 100 || offRate <= 0) {
            message.error("Off-Rate must be between 0 to 100!", 1.5);
            return;
        }
        dispatch(editTag(
            {
                ids: selectedBundles,
                offRate: offRate
            }
        ));
    }

    const handleDeleteTag = () => {
        dispatch(editTag(
            {
                ids: selectedBundles,
                offRate: 0
            }
        ));
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: '_id',
            width: '10%',
            ellipsis: true,
            render: (text, record, index) => {
                return (pagination.current - 1) * pagination.pageSize + index + 1;
            }
        },
        {
            title: 'Number of Coins',
            dataIndex: 'coin',
            key: '_id',
            width: '25%',
            ellipsis: true,
        },
        {
            title: 'Vidiny',
            dataIndex: 'amount',
            key: '_id',
            width: '25%',
            ellipsis: true,
            render: (text, record, index) => {
                return <>$ {(text * (100 - record.offRate) / 100.0).toFixed(2)}</>
            }
        },
        {
            title: 'Tag',
            dataIndex: 'offRate',
            key: '_id',
            width: '20%',
            ellipsis: true,
            render: (text, record, index) => {
                return (
                    <>
                        {text > 0 ? <span className='off-tag'> &nbsp; {text}% Off &nbsp; </span> :  null}
                    </> 
                )
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            render: (_, record) => {
                return (
                    <Space size="large">
                        <Typography.Link>
                            <Tooltip title="Edit">
                                <Icon sx={{fontSize: 16}} onClick={() => handleEdit(record)}>edit</Icon>
                            </Tooltip>
                        </Typography.Link>
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
                            <Typography.Link style={{ color: "red"}}>
                                <Tooltip title="Delete">
                                    <Icon sx={{fontSize: 16}}>delete</Icon>
                                </Tooltip>
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    return (
        <>
            <Button type="primary" size='middle' onClick={() => handleAdd()} className = "addRound"> Add Bundle </Button>

            <span className="tag-button-group">
                <TextField
                    label="Off Rate"
                    id="outlined-size-small"
                    sx={{width: 150}}
                    value = {offRate}
                    onChange = {(e) => setOffRate(e.target.value)}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    className="off-rate"
                    size="small"
                    disabled = {selectedBundles.length === 0}
                />
                <Button type="primary" title="Add 'Off' badge" size='middle' onClick={() => handleAddTag()} className="tag-button" disabled = {selectedBundles.length === 0}> Add Tag </Button>
                <Button type="primary" danger title="Delete 'Off' badge" size='middle' onClick={() => handleDeleteTag()} className="tag-button" disabled = {selectedBundles.length === 0}> Delete Tag </Button>
                
            </span>
            
            <Table
                bordered
                rowSelection={{
                type: 'checkbox',
                ...rowSelection,
                }}
                rowKey={(record) => record._id}
                dataSource={coinInfo.coinBundles}
                columns={columns}
                loading={coinInfo.loading}
                pagination={pagination}
                scroll={{ x: '100%' }}
                onChange={handleTableChange}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>New Coin-Bundle</h2>} open={isAddModal} onCancel={handleCancel} onOk={handleOk} width={450}>
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="Number of Coins"
                    sx={{ m: 1, width: '24ch' }}
                    value = {selectedCoinBundle.coin}
                    onChange = {(e) => setSelectedCoinBundle({...selectedCoinBundle, coin: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <TextField
                    label="Vidiny"
                    sx={{ m: 1, width: '23ch' }}
                    value = {selectedCoinBundle.amount}
                    onChange = {(e) => setSelectedCoinBundle({...selectedCoinBundle, amount: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    />
                    <Divider/>
                </Box>
            </Modal>
            <Modal title= {<h2 style={{fontSize: '24px'}}>Edit Coin-Bundle</h2>} open={isEditModal} onCancel={handleCancel} onOk={handleSave} width={450}>
                <Divider/>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <TextField
                    label="Number of Coins"
                    sx={{ m: 1, width: '24ch' }}
                    value = {selectedCoinBundle.coin}
                    onChange = {(e) => setSelectedCoinBundle({...selectedCoinBundle, coin: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>,
                    }}
                    />
                    <TextField
                    label="Vidiny"
                    sx={{ m: 1, width: '23ch' }}
                    value = {selectedCoinBundle.amount}
                    onChange = {(e) => setSelectedCoinBundle({...selectedCoinBundle, amount: e.target.value})}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    />
                </Box>
                <Divider/>
            </Modal>
        </>
    )
}

export default Coin;