import React, { useRef, useState, useEffect } from 'react';
import { Button, Space, Table, Input, } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Highlighter from 'react-highlight-words';

import { useDispatch, useSelector } from 'react-redux';
import BubblePayInfo from './components/EBubblePayInfo';
import { fetchBubblePaymentHistories } from '../../../../features/ebubble/payment/ebubblePaymentSlice';
import "./EBubblePayment.css";

const EBubblePayment = () => {

    const dispatch = useDispatch();
    
    const bubblePayment = useSelector((state) => state.ebubblePayment);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
        filters: {
            user: "",
            currency: "",
        },
        sorter: {
            amount: 0,
            date: 0,
            status: 0
        }
    });
    const searchInput = useRef(null);

    // useEffect(() => {
    //     if(bubblePayment.status === "idle") {
    //         dispatch(fetchBubblePaymentHistories(tableParams));
    //     }
    // }, [dispatch, bubblePayment]);
    
    useEffect(() => {
        dispatch(fetchBubblePaymentHistories(tableParams));
    }, [JSON.stringify(tableParams)]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams(prevState => ({ 
            ...prevState,
            pagination
        }))
        if(sorter) {
            let flag1 = 0;
            let flag2 = 0;
            let flag3 = 0;
            if(sorter.field === "amount") {
                if(sorter.order === "ascend") {
                    flag1 = -1;
                }
                if(sorter.order === "descend") {
                    flag1 = 1;
                }
            }
            if(sorter.field === "date") {
                if(sorter.order === "ascend") {
                    flag2 = -1;
                }
                if(sorter.order === "descend") {
                    flag2 = 1;
                }
            }
            if(sorter.field === "status") {
              if(sorter.order === "ascend") {
                  flag3 = -1;
              }
              if(sorter.order === "descend") {
                  flag3 = 1;
              }
          }
            setTableParams(prevState => ({
                ...prevState,
                sorter : {
                    amount : flag1,
                    date : flag2,
                    status: flag3
                }
            }))
        }
    };

    //operation
    // const handleDelete = (record) => {
    //     dispatch(deleteOneBubblePaymentHistory(record._id));
    // }

    //For search function in antd Table

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);

        setTableParams(prevState => ({ 
            ...prevState,
            filters: {
                ...prevState.filters,
                [dataIndex]: selectedKeys[0]
            }
        }))
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
        <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
            marginBottom: 8,
            display: 'block',
        }}
        />
        <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                close();
                }}
            >
                close
            </Button>
        </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1890ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
        },
        render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{
                backgroundColor: '#ffc069',
                padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    const columns = [
        {
            title: 'No',
            key: 'No',
            width: '10%',
            ellipse: true,
            render: (text, record, index) => {
                return (tableParams.pagination.current - 1) * tableParams.pagination.pageSize + index + 1;
            }
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'usere',
            width: '20%',
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('user'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '13%',
            textWrap: 'word-break',
            ellipsis: true,
            sorter: true,
        },
        {
            title: 'Transaction-ID',
            dataIndex: 'transactionID',
            key: 'transactionID',
            width: '20%',
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: '10%',
            sorter: true,
            textWrap: 'word-break',
            ellipsis: true,
            ...getColumnSearchProps('currency')
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: true,
          textWrap: 'word-break',
          ellipsis: true,
          render: (text) => {
            return <span className='payment-status'> &nbsp; {text} &nbsp; </span>; 
          }          
      },

        // {
        //   title: 'Operation',
        //   dataIndex: 'operation',
        //   render: (_, record) => {
        //     return (
        //         <div>
        //             <Space size="middle" >

        //                 <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
        //                     <Typography.Link style={{ color: "red"}}>
        //                         <Tooltip title="Delete">
        //                             <Icon sx={{fontSize: 18}}>delete</Icon>
        //                         </Tooltip>
        //                     </Typography.Link>
        //                 </Popconfirm>
        //             </Space>
        //         </div>
        //     )
        //   }
        // },
    ];

    return(
        <>  
            <BubblePayInfo style={{wdith: 600}}/>
            <Table
                className="bubblePaymentHistoryTable"
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={bubblePayment.histories}
                pagination={{...tableParams.pagination, total: bubblePayment.count, showSizeChanger: true, showTotal: (total) => `Total : ${total} histories` }}
                loading={bubblePayment.loading}
                onChange={handleTableChange}
            />
        </>
    )
}

export default EBubblePayment;