import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import { Space, Switch, Breadcrumb } from "antd";

import { useNavigate } from "react-router";
import { useLocation, Link } from 'react-router-dom';

import { useDispatch } from "react-redux";
import { setAuth } from "../../features/authSlice";
import { setThemeColor, setThemeMode } from "../../features/themeSlice";

import "./Header.css";

const Header = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorElTheme, setAnchorElTheme] = useState(null);

    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i.toUpperCase());
    const routes = pathSnippets.map((snippet, index) => ({
        path: `/${pathSnippets.slice(0, index + 1).join('/')}`,
        breadcrumbName: snippet.charAt(0).toUpperCase() + snippet.slice(1),
    }));

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenThemeMenu = (event) => {
        setAnchorElTheme(event.currentTarget);
    }

    const handleCloseThemeMenu = (event) => {
        setAnchorElTheme(null);
    }

    const changeThemeColor = (value) => {
        dispatch(setThemeColor(value ? 'light' : 'dark'));
    }

    const changeThemeMode = (value) => {
        dispatch(setThemeMode(value ? 'vertical' : 'inline'));
    }

    const handleLogout = () => {
        handleCloseUserMenu();
        localStorage.removeItem('token');
        dispatch(setAuth({
            user: null,
            login: false
        }));
        navigate("/login");
    }

    return (
        <div style={{margin: 10}}>
        <AppBar position="static" style={{borderRadius: 15, backgroundColor: 'rgba(255, 255, 255)',  zIndex: 10 }} >
        <Container maxWidth="xl">
            <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Breadcrumb
                    style={{
                    margin: '16px 0',
                    fontSize: '16px',
                    fontWeight: "bold"
                    }}
                >
                    <Breadcrumb.Item><Link to="/home"><Icon>home</Icon></Link></Breadcrumb.Item>
                    {routes.map((route) => (
                        <Breadcrumb.Item key={route.path}>
                        <Link to={route.path}>{route.breadcrumbName}</Link>
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                </Box>
                <Space size="small">
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Profile">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Icon>account_circle</Icon>
                            </IconButton>
                        </Tooltip>
                        <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        >
                            <MenuItem key="Logout" onClick={handleLogout}>
                                <Typography textAlign="center">LogOut</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenThemeMenu} sx={{ p: 0 }}>
                                <Icon>settings</Icon>
                            </IconButton>
                        </Tooltip>
                        <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElTheme}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElTheme)}
                        onClose={handleCloseThemeMenu}
                        >
                            <MenuItem>
                                <Switch onChange={changeThemeMode} /> &nbsp;Change Mode
                            </MenuItem>
                            <MenuItem>
                                <Switch onChange={changeThemeColor} /> &nbsp;Change Color
                            </MenuItem>
                        </Menu>
                    </Box>
                </Space>
            </Toolbar>
        </Container>
        </AppBar>
        </div>
    );
}
export default Header;
