import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useParams,  } from 'react-router-dom';

import { fetchOneProblem, addOneWordStep, deleteOneWordStep, setTest5ProblemStatus, editOneWordStep, deleteAllWordStep } from "../../../../../../features/piano/test/test5/test5ProblemSlice.js";
import { api } from "../../../../../../config/api.js";

import { Popconfirm, Table, Typography, Space, Button, Image, Modal, Divider, Col, Row, message } from 'antd';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { successNotification, errorNotification } from "../../../../../../utilities/Notification.js";

import { serverURL } from "../../../../../../config/config.js";
import "./Test5ProblemDetail.css"

const Test5ProblemDetail = () => {
    const { rid, pid } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const problemInfo = useSelector((state) => state.test5Problem);

    useEffect(() => {
        if(problemInfo.status === "idle") {
            dispatch(fetchOneProblem(pid));
        }
    }, [dispatch, problemInfo]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedAudio, setSelectedAudio] = useState("");
    const [audioUploadPercent, setAudioUploadPercent] = useState(0);
    const [uploadedWordImgUrl, setUploadedWordImgUrl] = useState("");
    const [uploadedWordImgFileName, setUploadedWordImgFileName] = useState("");
    const [selectedWordImg, setSelectedWordImg] = useState("");
    const [selectedStep, setSelectedStep] = useState("");
    const [wordImgUploadPercent, setWordImgUploadPercent] = useState(0);
    
    const handleImageClick = (step) => {
        const data = {
            letterImg: step.keyboardImg,
            letterid: step._id,
            pid: pid
        }

        dispatch(addOneWordStep(data));
    }

    //Operation

    const handleDeleteAllLetters = () => {
        dispatch(deleteAllWordStep(pid));
    }

    const handleLetterEdit = (record) => {
        setSelectedStep(record._id);
        setUploadedWordImgFileName(record.wordImg);
        setUploadedWordImgUrl(serverURL + "/public/uploads/test5/images/words/" + record.wordImg);
        showModal();
    }

    const handleLetterDelete = (record) => {
        dispatch(deleteOneWordStep(record._id));
    }
    
    //Button

    const handleBackToRound = () => {
        navigate(`/home/piano/test5/${rid}`);
    }

    //Modal
    const handleEditSave = () => {
        const info = {
            sid: selectedStep,
            wordImgFileName: uploadedWordImgFileName
        }
        dispatch(setTest5ProblemStatus("idle"));
        dispatch(editOneWordStep(info));
        hideModal();
    }
    const handleEditCancel = () => {
        hideModal();
    }

    const showModal = () => {
        setIsModalOpen(true);
    }

    const hideModal = () => {
        setIsModalOpen(false);
    }

    //check type of upload files

    const isPngFile = (file) => {
        return file && file.type === 'image/png';
    }

    const isAudioFile = (file) => {
        return file && file.type.startsWith('audio/');
    }

    //upload images and audio of word

    const handleAudioUpload = (e) => {
        e.preventDefault();
        if(!isAudioFile(selectedAudio[0])) {
            message.error("Not an audio file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedAudio[0]);
        // const fileNameID = roundInfo.round + "-" + selectedProblemIndex + "-" + selectedStepIndex;

        api.post(`/api/temp/test5/word_audio_upload/${pid}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setAudioUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("success", "Successfully audio uploaded");
                dispatch(setTest5ProblemStatus("idle"));
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const handleWordImgUpload = (e) => {
        e.preventDefault();
        if(!isPngFile(selectedWordImg[0])) {
            message.error("Not an image/png file", 1.5);
            return;
        }
        const formData = new FormData();
        formData.append("myfile", selectedWordImg[0]);

        api.post(`/api/temp/test5/word_image_upload/${selectedStep}`, formData, {
                onUploadProgress: function(progressEvent) {
                    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setWordImgUploadPercent(percentComplete);
                }
            })
            .then((res) => {
                successNotification("Success", "Successfully image uploaded");
                const wordImgUrl = serverURL + '/public/uploads/test5/images/words/' + res.data.wordImgFileName;
                setUploadedWordImgFileName(res.data.wordImgFileName);
                setUploadedWordImgUrl(wordImgUrl);
            }).catch((error) => {
                errorNotification("Error!", "Upload Failed!");
        });
    }

    const changeSelectedWordImg = (e) => {
        setSelectedWordImg(e.target.files);
    }
    const changeSelectedAudio = (e) => {
        setSelectedAudio(e.target.files);
    }
    const columns = [
        {
        title: 'No',
        key: 'no',
        dataIndex: 'no',
        width: '10%',
        render : (text, record, index) => {
            return(index + 1);
        }
        },
        {
        title: 'Letter Order',
        key: 'letterImg',
        dataIndex: 'letterImg',
        width:'30%',
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/uploads/test5/images/keyboards/" + text} style={{width: 100, height: 100}} alt=""/>
        }
        },
        {
        title: 'Word Piece',
        key: 'wordImg',
        dataIndex: "wordImg",
        width: '50%',
        render: (text, record, index) => {
            return <Image src={serverURL + "/public/uploads/test5/images/words/" + text} style={{width: 100}} alt="Word Piece"/>
        }
        },
        {
        title: 'Operation',
        key: 'operation',
        dataIndex: 'operation',
        render: (_, record) => (
            <Space size="middle" >
                <Typography.Link onClick={() => handleLetterEdit(record)}>
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleLetterDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center"}}>
            {problemInfo.steps.map((step) => (
                <div style={{display: "inline-block", cursor: "pointer", marginRight: 20, marginLeft: 20}} onClick={() => handleImageClick(step)}>
                <img src={ serverURL + "/public/uploads/test5/images/keyboards/" + step.keyboardImg } alt="Letter" style={{width: 120, height: 120}}/>
                </div>
            ))}
            </div>
            <Button type="primary" danger size='middle' onClick={() => handleDeleteAllLetters()} className = "wordSave"> Clear </Button>
            <Table
              bordered
              columns={columns}
              dataSource={problemInfo.word}
              loading={problemInfo.loading}
              scroll={{ x: '100%' }}
              rowKey={(record) => record._id}
              pagination={{
                pageSize: 100
              }}
            />
            <form onSubmit={(e)=>handleAudioUpload(e)} style={{marginTop: 20, display: "flex", justifyContent: "center", alignItems: "center"}}>
                <label for="file-input">Sound of Word:&nbsp;</label>
                <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedAudio(e)} />
                <button className="upload-button" type="submit">Upload</button>
                <audio controls src={serverURL + '/public/uploads/test5/audios/' + problemInfo.wordSound} style={{marginLeft: 20}}/>
                <Box>
                    {audioUploadPercent} %
                </Box>
            </form>

            <div>
                <Button danger size='middle' onClick={() => handleBackToRound()} className = "wordSave"> Back to Round </Button>
            </div>

            <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>Word Piece</h2>} open={isModalOpen} onOk={handleEditSave} onCancel={handleEditCancel} okText="Save" width={500}>
                <Divider/>
                <Space direction="vertical" size="large" style={{width: "100%"}}>
                <Row>
                    <Col span={24}>    
                        <form onSubmit={(e)=>handleWordImgUpload(e)}>
                            <label for="file-input">Word Piece:&nbsp;</label>
                            <input type="file" className="file-input" name="my-keyboard-image" onChange= {(e)=>changeSelectedWordImg(e)} />
                            <button className="upload-button" type="submit">Upload</button>
                        </form>            
                        <Image src={uploadedWordImgUrl} alt='Word Piece image' style={{width: "100%"}}  />
                        <Box sx={{ width: '95%' }}>
                            <LinearProgress variant="determinate" value={wordImgUploadPercent} />
                        </Box>
                    </Col>
                </Row>
                </Space>
            </Modal>
        </>
    )
}

export default Test5ProblemDetail;