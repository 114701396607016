import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../../config/api";
import { successNotification, errorNotification } from "../../../../utilities/Notification";

export const fetchAllTest5s = createAsyncThunk(
  "/test5/fetchAllProblem",
  async () => {
    const response = await api.get(`/api/temp/test5/get_all_round`);
    return response.data;
  }
);

export const deleteOneRound = createAsyncThunk(
    "/test5/deleteOneRound",
    async (data) => {
      const response = await api.post(`/api/temp/test5/delete_one_round/${data._id}`, data);
      return response.data;
    }
);

export const addOneRound = createAsyncThunk(
  "/test5/addOneRound",
  async (data) => {
    const response = await api.post(`/api/temp/test5/add_round`, data);
    return response.step;
  }
);

export const editOneRound = createAsyncThunk(
  "/test5/editOneRound",
  async (data) => {
    const rid = data.rid;
    const response = await api.post(`/api/temp/test5/edit_round/${rid}`, data);
    return response;
  }
);

const test5Slice = createSlice({
  name: "test5Field",
  initialState: { test5s: [], status: "idle", error: null, message: "", loading: false, version: 0.0 },
  reducers: {
    setTest5FieldStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAllTest5s.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchAllTest5s.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.test5s = action.payload;
            state.loading = false;
        })
        .addCase(fetchAllTest5s.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(addOneRound.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully added!")
            state.status = "idle";
        })
        .addCase(addOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(editOneRound.pending, (state) => {
          state.status = "loading";
        })
        .addCase(editOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully edited!")
            state.status = "idle";
        })
        .addCase(editOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneRound.pending, (state) => {
            state.status = "loading";
        })
        .addCase(deleteOneRound.fulfilled, (state, action) => {
            successNotification("Success!", "Successfully deleted!")
            state.status = "idle";
        })
        .addCase(deleteOneRound.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })

  },
});

export const {setTest5FieldStatus} = test5Slice.actions;

export default test5Slice.reducer;
