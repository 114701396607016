/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Highlighter from 'react-highlight-words';

import { Popconfirm, Table, Typography, Space, Button, Input, Modal, Divider, message, Form} from 'antd';
import { SearchOutlined, ExclamationCircleFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { deleteOneRound, fetchAllPractices, editOneRound, addOneRound } from "../../../../features/piano/lesson/practiceSlice";
import { fetchVersion, editPracticeVersion, fetchTestRounds } from "../../../../features/piano/versionSlice";

import "./Lesson.css";

const {Text} = Typography;

const Practice = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const practiceField = useSelector((state) => state.practiceField);
    const version = useSelector((state) => state.version);

    const [pagination, setPagination] = useState({
      pageSize: 5,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10'],
      showTotal: (total) => `Total : ${total} lessons`
    });

    const titleFieldRef1 = useRef(null);
    const titleFieldRef2 = useRef(null);
    const videoFieldRef1 = useRef(null);
    const videoFieldRef2 = useRef(null);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [practiceCurrentVersion, setPracticeCurrentVersion] = useState(1.0);
    const [practiceUpdatedVersion, setPracticeUpdatedVersion] = useState(1.0);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [editedRound, setEditedRound] = useState(1);
    const [selectedRoundInfo, setSelectedRoundInfo] = useState({round: 1, fee: 0.0, style: 1, _id: "",title: "", video : "" });
    const [unfilledUpload, setUnfilledUpload] = useState(false);
    const [maxLesson, setMaxLesson] = useState(0);
    // const [addButtonClicked, setAddButtonClicked] = useState(false);
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [fieldData, setFieldData] = useState([]);

    let flag = true;


    // functions for modal when update button is pressed.

    const updateErrorModal = () => {
      Modal.error({
        title: 'Check your upload done.',
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>There are still some images and sounds to upload.</p>
            <p>Please check then try again!</p>
          </div>
        ),
        onOk() {},
      });
    };

    const updateConfirmModal = () => {
      Modal.confirm({
        title: 'Do you really Want to update version?',
        icon: <ExclamationCircleFilled />,
        width: 500,
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
            <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version. </p>
            <TextField
              disabled
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={practiceUpdatedVersion}
              className="version"
            />
          </div>
        ),
        onOk() {
          handleVersionUpdate();
        },
        onCancel() {
        },
      });
    };

    useEffect(() => {
      // if(titleFieldRef2.current) {
      //   titleFieldRef2.current.value = selectedRoundInfo.title;
      // }
      if(videoFieldRef2.current) {
        videoFieldRef2.current.value = selectedRoundInfo.video;
      }
    }, [isEditModal])

    useEffect(() => {
      if(practiceField.status === "idle") {
        dispatch(fetchAllPractices());
      }
      if(version.status === 'idle') {
        dispatch(fetchVersion());
        dispatch(fetchTestRounds());
      }
      if(version.status === 'succeeded') {
        setPracticeCurrentVersion(version.practiceVersion);
        setPracticeUpdatedVersion(parseFloat(version.practiceVersion + 0.1).toFixed(1));
      }
    }, [dispatch, practiceField, version]);

    useEffect(() => {
      let max = 0;
      let unfilled = false;
      for(let i = 0; i < practiceField.practices.length; i ++) {
        if(practiceField.practices[i].round > max) {
          max = practiceField.practices[i].round;
        }
        for(let j = 0; j < practiceField.practices[i].problems.length; j ++) {
          if(practiceField.practices[i].problems[j].keyNum * 2 !== practiceField.practices[i].problems[j].uploadedImgs) {
            unfilled = true; break;
          }
          if(practiceField.practices[i].problems[j].keyNum !== practiceField.practices[i].problems[j].uploadedSounds) {
            unfilled = true; break;
          }
        }
      }
      setUnfilledUpload(unfilled);
      setMaxLesson(max);
    }, [dispatch, practiceField.practices])

    const handleTableChange = (pagina, filters, sorter) => {
      setPagination(pagina);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    // Edit Modal 

    const showEditModal = () => {
      setIsEditModalOpen(true);
    };

    const handleSave = (values) => {
      const data={
        ...selectedRoundInfo,
        // title: titleFieldRef2.current.value,
        video: videoFieldRef2.current.value,
        tests: values.Tests
      }
      dispatch(editOneRound(data));
      setIsEditModalOpen(false);
    };
    const handleCancel = () => {
      setIsEditModalOpen(false);
      setIsAddModalOpen(false);
    };

    // Add Modal

    const showAddModal = () => {
      setIsAddModalOpen(true);
    };

    const handleAdd = (values) => {
      const data = {
        ...selectedRoundInfo,
        // title: titleFieldRef1.current.value,
        video: videoFieldRef1.current.value,
        tests: values.Tests
      }
      dispatch(addOneRound(data));
      setIsAddModalOpen(false);
    };

    
    // Modal textfield change

    const changeSelectedRound = (e) => {
      setSelectedRoundInfo(prev => {
        return {...prev, round: e.target.value};
      });
    }

    const changeSelectedRoundFee = (e) => {
      setSelectedRoundInfo(prev => {
        return {...prev, fee: e.target.value}
      })
    }

    const changeSelectedRoundStyle = (e) => {
      setSelectedRoundInfo(prev => {
        return {...prev, style: e.target.value}
      })
    }

    //update textfield
    const handleChangeVersion = (e) => {
      setPracticeUpdatedVersion(e.target.value);
    }

    //operation

    const handleRoundDetail = (record) => {
      navigate(`/home/arabic/piano/lesson/${record._id}`);
    }

    const handleRoundDelete = (record) => {
        dispatch(deleteOneRound(record));
    }

    const handleRoundAdd = () => {
      if(titleFieldRef1.current) {
        titleFieldRef1.current.value = '';
      }
      if(videoFieldRef1.current) {
        videoFieldRef1.current.value = '';
      }
      setSelectedRoundInfo({
        rid: "",
        round: maxLesson + 1,
        fee: 0,
        style: 1
      });
      showAddModal();
    }

    const handleRoundEdit = (record) => {
      if(titleFieldRef2.current) {
        titleFieldRef2.current.value = record.title;
      }
      if(videoFieldRef2.current) {
        videoFieldRef2.current.value = record.video;
      }
      setFieldData(record.tests);
      setSelectedRoundInfo({
        rid: record._id,
        round: record.round,
        fee: record.fee,
        style: record.style,
        title: record.title,
        video: record.video
      });
      setEditedRound(record.round);
      showEditModal();
    };

    const handleVersionUpdate = () => {
      if(practiceUpdatedVersion <= practiceCurrentVersion) {
        message.error("Plesae check the Next Version number", 3);
        return;
      }
      dispatch(editPracticeVersion({version: practiceUpdatedVersion}));
    }

    const problemColumns = [
        {
        title: 'No', 
        key: 'no',
        dataIndex: 'no',
        width: '15%',
        render: (text, record, index) => index + 1,
        },
        {
        title: 'Letter',
        key: 'letter',
        dataIndex: 'letter',
        width: '35%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
          // if(record.keyNum * 2 !== record.uploadedImgs) setUnfilledUpload(true);
          return (
            <>
              <div>
                <Box style={{ width: '6px', height: '12px', backgroundColor: 'white', border: '1px solid black', display: "inline-block", marginRight: 5 }} />
                <Text code>
                  <Text type={record.keyNum * 2 === record.uploadedImgs ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.keyNum * 2} Done: {record.uploadedImgs}
                  </Text>
                </Text>
              </div>
              <div>
                <Box style={{ width: '6px', height: '12px', backgroundColor: 'grey', border: '1px solid black', display: "inline-block", marginRight: 5 }} />
                <Text code>
                  <Text type={(record.keyNum - 1) === record.uploadedBlackImgs ? "success" : "warning"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.keyNum - 1} Done: {record.uploadedBlackImgs}
                  </Text>
                </Text>
              </div>
            </>)
        }
        },
        {
        title: 'Sound',
        key: 'sound',
        dataIndex: 'sound',
        width: '35%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
          // if(record.keyNum !== record.uploadedSounds) setUnfilledUpload(true);
          return (
            <>
              <div>
                <Box style={{ width: '6px', height: '12px', backgroundColor: 'white', border: '1px solid black', display: "inline-block", marginRight: 5 }} />
                <Text code>
                  <Text type={record.keyNum === record.uploadedSounds ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.keyNum} Upload: {record.uploadedSounds}
                  </Text>
                </Text>
              </div>
              <div>
                <Box style={{ width: '6px', height: '12px', backgroundColor: 'grey', border: '1px solid black', display: "inline-block", marginRight: 5 }} />
                <Text code>
                  <Text type={(record.keyNum - 1) === record.uploadedBlackSounds ? "success" : "warning"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need: {record.keyNum - 1} Upload: {record.uploadedBlackSounds}
                  </Text>
                </Text>
              </div>
            </>
          )
        }
        },
        {
        title: 'Keys',
        dataIndex: 'keyNum',
        // width: '12%',
        },
    ];

    const testColumns = [
      {
      title: 'Style',
      key: 'testStyle',
      dataIndex: 'testStyle',
      width: '50%',
      textWrap: 'word-break',
      ellipsis: true,
      },
      {
      title: 'Round',
      key: 'testRound',
      dataIndex: 'testRound',
      width: '50%',
      textWrap: 'word-break',
      ellipsis: true,
      },
    ];
    
    const columns = [
        {
        title: 'Lesson',
        key: 'round',
        dataIndex: 'round',
        width: '9%',
        fixed: 'left',
        sorter: (a, b) => b.round - a.round,
        defaultSortOrder: 'descend',
        ...getColumnSearchProps('round'),
        render : (text, record, index) => {
          // maxRound = maxRound > record.round ? maxRound : record.round
          return record.round;
        }
        },
        {
        title: 'Style',
        key: 'style',
        dataIndex: 'style',
        width: '7%',
        ...getColumnSearchProps('style'),
        },
        // {
        // title: 'Title',
        // key: 'title',
        // dataIndex: 'title',
        // width: '10%',
        // ellipsis: true,
        // },
        {
          title: 'Video',
          key: 'video',
          dataIndex: 'video',
          width: '17%',
        },
        {
        title: 'Practice Problems',
        key: 'innerTable',
        width: '43%',
        ellipsis: true,
        render: (text, record, index) => { 
            const i = practiceField.practices.findIndex(practice => practice === record);
            return (<Table
            style={{fontSize:15}}
            dataSource={practiceField.practices[i].problems}
            columns={problemColumns}
            pagination={false}
            />)
        },
        },
        {
        title: "Tests",
        key: "innerTable2",
        width: "12%",
        render: (text, record, index) => { 
          const i = practiceField.practices.findIndex(practice => practice === record);
          if(practiceField.practices[i].tests.length > 0) {
            return (<Table
              style={{verticalAlign: "top"}}
              dataSource={practiceField.practices[i].tests}
              columns={testColumns}
              pagination={false}
            />)
          }
          else {
            return <p style={{color: "red"}}>No Data</p>;
          }
        },
        },
        {
        title: 'Operation',
        key: 'operation',
        dataIndex: 'operation',
        fixed: 'right',
        render: (_, record) => (
            <Space size="middle" >
                <Typography.Link  onClick={() => handleRoundEdit(record)} >
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>

                <Typography.Link  onClick={() => handleRoundDetail(record)} style={{ color: "green"}}> 
                    <Tooltip title="Detail" >
                        <Icon sx={{fontSize: 18}}>visibility</Icon>
                    </Tooltip>
                </Typography.Link>

                <Popconfirm title="Sure to delete?" onConfirm={() => handleRoundDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return(
        <>
            <TextField
              disabled
              label="Current Version"
              id="outlined-size-small"
              size="small"
              value={practiceCurrentVersion}
              onChange={handleChangeVersion}
              className="version"
            />
            <TextField
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={practiceUpdatedVersion}
              onChange={handleChangeVersion}
              style={{backgroundColor: "white"}}
              className="version"
            />
            <Button type="primary" size='middle' onClick={() => unfilledUpload ?  updateErrorModal() : updateConfirmModal()} className="updateButton"> Update </Button>
            
            <Button type="primary" size='middle' onClick={() => handleRoundAdd()} className = "addRound"> Add Lesson </Button>
            <Table
              bordered
              dataSource={practiceField.practices}
              columns={columns}
              loading={practiceField.loading}
              scroll={{ x: 1500 }}
              pagination={pagination}
              onChange={handleTableChange}
              className={"MyPracticeTable"}
              rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"Lesson " + editedRound}</h2>} open={isEditModal} onCancel={handleCancel} footer={null}>
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="Lesson"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedRoundInfo.round}
                  onChange = {changeSelectedRound}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                {/* <TextField
                  label="Fee"
                  sx={{ m: 1, width: '20ch' }}
                  value = {selectedRoundInfo.fee}
                  onChange = {changeSelectedRoundFee}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                /> */}
                <FormControl sx={{ m: 1, width: '18ch'}}>
                  <InputLabel id="demo-select-small-label">Practice Style</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedRoundInfo.style}
                    label="Practice Style"
                    onChange={changeSelectedRoundStyle}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>


                {/* <TextField
                  label="Title"
                  sx={{ m: 1, width: '100%' }}
                  inputRef={titleFieldRef2}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                /> */}

                <TextField
                  label="Video URL"
                  sx={{ m: 1, width: '100%' }}
                  inputRef={videoFieldRef2}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />

                <Form
                  name="dynamic_form_nest_item1"
                  onFinish={handleSave}
                  style={{
                    width: "100%"
                  }}
                  autoComplete="off"
                  form={form1}
                  initialValues={{
                    Tests: fieldData
                  }}
                >
                  <Form.List name="Tests">
                    {(fields, { add, remove }) => {
                      if(flag) {
                        flag = false;
                        form1.resetFields();
                      }
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }) => {
                            return <Space
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 0,
                                marginTop: 20
                              }}
                              align="baseline"
                            >
                            <FormControl sx={{ m: 1, width: '18ch'}}>
                            <InputLabel id="demo-select-small-label">Test Style</InputLabel>
                              <Form.Item
                                {...restField}
                                name={[name, 'testStyle']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing Test Style',
                                  },
                                ]}
                              >
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  label="Test Style"
                                  style={{width: "100%"}}
                                >
                                  <MenuItem value={1}>1</MenuItem>
                                  <MenuItem value={2}>2</MenuItem>
                                  <MenuItem value={3}>3</MenuItem>
                                  <MenuItem value={4}>4</MenuItem>
                                  <MenuItem value={5}>5</MenuItem>
                                </Select>
                              </Form.Item>
                              </FormControl>
                              <Form.Item
                                {...restField}
                                name={[name, 'testRound']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing Test Round',
                                  },
                                ]}
                              >
                                <TextField
                                  label="Test Round"
                                  sx={{ m: 1, width: '20ch' }}
                                />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => {remove(name); }} />
                            </Space>
                          })}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop: 20, width: "400px", marginLeft: "10px"}}>
                              Add Test 
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                  <Form.Item style={{float: "right", marginBottom: 10, marginTop: 10}}>
                    <Button type="default" onClick={handleCancel} style={{marginRight: 20}}>
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                  
                </Form>
              </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"New Lesson"}</h2>} open={isAddModal} footer={null} onCancel={handleCancel}>
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="Lesson"
                  sx={{ m: 1, width: '18ch' }}
                  value = {selectedRoundInfo.round}
                  onChange = {changeSelectedRound}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                {/* <TextField
                  label="Fee"
                  sx={{ m: 1, width: '20ch' }}
                  value = {selectedRoundInfo.fee}
                  onChange = {changeSelectedRoundFee}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                /> */}
                <FormControl sx={{ m: 1, width: '18ch'}}>
                  <InputLabel id="demo-select-small-label">Practice Style</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={selectedRoundInfo.style}
                    label="Practice Style"
                    onChange={changeSelectedRoundStyle}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                  </Select>
                </FormControl>

                {/* <TextField
                  label="Title"
                  sx={{ m: 1, width: '100%' }}
                  inputRef={titleFieldRef1}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                /> */}

                <TextField
                  label="Video URL"
                  sx={{ m: 1, width: '100%' }}
                  inputRef={videoFieldRef1}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
                <Form
                  name="dynamic_form_nest_item2"
                  onFinish={handleAdd}
                  style={{
                    width: "100%"
                  }}
                  autoComplete="off"
                  initialValues={{
                    Tests: []
                  }}
                  form={form2}
                >
                  <Form.List name="Tests" initialValue={[]} >
                    {(fields, { add, remove }) => {
                      if(flag) {
                        flag = false;
                        form2.resetFields();
                      }
                      return (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            <Space
                              key={key}
                              style={{
                                display: 'flex',
                                marginBottom: 0,
                                marginTop: 20
                              }}
                              align="baseline"
                            >
                            <FormControl sx={{ m: 1, width: '18ch'}}>
                            <InputLabel id="demo-select-small-label">Test Style</InputLabel>
                              <Form.Item
                                {...restField}
                                name={[name, 'testStyle']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing Test Style',
                                  },
                                ]}
                              >
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  label="Test Style"
                                  style={{width: "100%"}}
                                >
                                  <MenuItem value={1}>1</MenuItem>
                                  <MenuItem value={2}>2</MenuItem>
                                  <MenuItem value={3}>3</MenuItem>
                                  <MenuItem value={4}>4</MenuItem>
                                  <MenuItem value={5}>5</MenuItem>
                                </Select>
                              </Form.Item>
                              </FormControl>
                              <Form.Item
                                {...restField}
                                name={[name, 'testRound']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Missing Test Round',
                                  },
                                ]}
                              >
                                <TextField
                                  label="Test Round"
                                  sx={{ m: 1, width: '20ch' }}
                                  
                                />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => {remove(name); }} />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{marginTop: 20, width: "400px", marginLeft: "10px"}}>
                              Add Test 
                            </Button>
                          </Form.Item>
                        </>
                      )
                    }}
                  </Form.List>
                  <Form.Item style={{float: "right", marginBottom: 10, marginTop: 10}}>
                    <Button type="default" onClick={handleCancel} style={{marginRight: 20}}>
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Create
                    </Button>
                  </Form.Item>
                  
                </Form>

              </Box>
            </Modal>
        </>
    )
}

export default Practice;
