import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Typography, Space, Modal, Divider, message, Button} from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import { successNotification, errorNotification } from "../../../../utilities/Notification";
import { api } from "../../../../config/api";
import { serverURL } from "../../../../config/config";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import LinearProgress from '@mui/material/LinearProgress';

import { fetchAllSounds, editOneSound } from "../../../../features/bubble/sound/bubbleSoundSlice";
import { fetchBubbleVersion, editBubbleSoundVersion } from "../../../../features/bubble/bubbleVersionSlice";

import "./BubbleSound.css";

const BubbleSound = () => {
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedSoundUrl, setUploadedSoundUrl] = useState("");
    const [uploadedSoundFileName, setUploadedSoundFileName] = useState("");
    const [selectedSound, setSelectedSound] = useState("");
    const [selectedSoundId, setSelectedSoundId] = useState("");
    const [selectedUsage, setSelectedUsage] = useState("");
    const [soundUploadPercent, setSoundUploadPercent] = useState(0);

    const audioRef = useRef(null);

    const soundInfo = useSelector((state) => state.bubbleSound);
    const version = useSelector((state) => state.bubbleVersion);

    const [soundCurrentVersion, setSoundCurrentVersion] = useState(1.0);
    const [soundUpdatedVersion, setSoundUpdatedVersion] = useState(1.0);

    useEffect(() => {
      if(soundInfo.status === "idle") {
          dispatch(fetchAllSounds());
      }
      if(version.status === 'idle') {
        dispatch(fetchBubbleVersion());
      }
      if(version.status === 'succeeded') {
        setSoundCurrentVersion(version.soundVersion);
        setSoundUpdatedVersion(parseFloat(version.soundVersion + 0.1).toFixed(1));
      }
    }, [dispatch, soundInfo, version]);


    const updateConfirmModal = () => {
      Modal.confirm({
        title: 'Do you really Want to update version?',
        icon: <ExclamationCircleFilled />,
        width: 500,
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
            <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version.</p>
            <TextField
              disabled
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={soundUpdatedVersion}
              className="version"
            />
          </div>
        ),
        onOk() {
          handleVersionUpdate();
        },
        onCancel() {
        },
      });
    };

    const showModal = () => {
      setIsModalOpen(true);
    };

    const hideModal = () => {
      setIsModalOpen(false);
    };

    const isAudioFile = (file) => {
      return file && file.type.startsWith('audio/');
    }

    const handleSoundUpload = (e) => {
      e.preventDefault();
      if(!isAudioFile(selectedSound[0])) {
          message.error("Not an audio file", 1.5);
          return;
      }
      const formData = new FormData();
      formData.append("myfile", selectedSound[0]);
      // const fileNameID = soundInfo.round + "-" + selectedProblemIndex + "-" + selectedBubbleIndex;

      api.post(`/api/bubble/temp/sound/sound_upload`, formData, {
            onUploadProgress: function(progressEvent) {
                const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setSoundUploadPercent(percentComplete);
            }
          })
          .then((res) => {
              successNotification("success", "Successfully sound uploaded");
              const soundUrl = serverURL + '/public/bubble/sounds/' + res.data.soundFileName;
              setUploadedSoundFileName(res.data.soundFileName);
              setUploadedSoundUrl(soundUrl);
          }).catch((error) => {
              errorNotification("Error!", "Upload Failed!");
      });
    }

    const changeSelectedSound = (e) => {
      setSelectedSound(e.target.files);
    }

    const handleSave = () => {
      const info = {
        sid: selectedSoundId,
        sound: uploadedSoundFileName
      }
      dispatch(editOneSound(info));
      hideModal();
    }

    const handleCancel = () => {
      hideModal();
    }

    const handleEdit = (record) => {
      setUploadedSoundFileName(record.sound);
      setUploadedSoundUrl(serverURL + "/public/bubble/sounds/" + record.sound);
      setSelectedSoundId(record._id);
      setSelectedUsage(record.usage);
      setSoundUploadPercent(0);
      showModal();
    }

    const handleChangeVersion = (e) => {
      setSoundUpdatedVersion(e.target.value);
    }

    const handleVersionUpdate = () => {
      if(soundUpdatedVersion <= soundCurrentVersion) {
        message.error("Plesae check the Next Version number", 3);
        return;
      }
      dispatch(editBubbleSoundVersion({version: soundUpdatedVersion}));
    }

    const columns = [
      {
        title: 'No',
        dataIndex: 'No',
        width: '10%',
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: 'Usage',
        dataIndex: 'usage',
        width: '20%'
      },
      {
        title: 'Sound',
        dataIndex: 'sound',
        width: '57%',
        render: (text, record, index) => {
          return (
              <audio controls style={{width: 500}} src={serverURL + "/public/bubble/sounds/" + text}/>
          )
      }
      },
      {
        title: 'Operation',
        key: 'operation',
        dataIndex: 'operation',
        render: (_, record) => (
                <Typography.Link  onClick={() => handleEdit(record)} >
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>
            ),
        },
    ];

    return (
      <>
        <TextField
          disabled
          label="Current Version"
          id="outlined-size-small"
          size="small"
          value={soundCurrentVersion}
          className="version"
        />
        <TextField
          label="Next Version"
          id="outlined-size-small"
          size="small"
          value={soundUpdatedVersion}
          onChange={handleChangeVersion}
          style={{backgroundColor: "white"}}
          className="version"
        />
        <Button type="primary" size='middle' onClick={() => updateConfirmModal()} className="updateButton"> Update </Button>

        <Table columns={columns} dataSource={soundInfo.sounds} loading = {soundInfo.loading} bordered rowKey={(record) => record._id}/>
        <Modal size="large" style={{fontSize: 24}} title={<h2 style={{fontSize: '24px'}}>{selectedUsage} Sound</h2>} open={isModalOpen} onOk={handleSave} onCancel={handleCancel} okText="Save" width={500}>
          <Divider/>
          <Space direction="vertical" size="large" style={{width: "100%"}}>

          <form onSubmit={(e)=>handleSoundUpload(e)}>
              <label for="file-input">Sound :&nbsp;</label>
              <input type="file" className="file-input" name="my-sound" onChange= {(e)=>changeSelectedSound(e)} />
              <button className="upload-button" type="submit">Upload</button>
          </form>

          <audio ref={audioRef} controls src={uploadedSoundUrl} />
          <Box sx={{ width: '95%' }}>
              <LinearProgress variant="determinate" value={soundUploadPercent} />
          </Box>
          
          </Space>
        </Modal>
      </>
    )
}

export default BubbleSound;