import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../config/api";

import { successNotification, errorNotification } from "../../../utilities/Notification";

export const fetchBubblePaymentHistories = createAsyncThunk('/ebubble/payment/fetch', async (data) => {
    const response = await api.post(`/api/english_bubble/payment/get_page`, data);
    return response.data;
});

export const deleteOneBubblePaymentHistory = createAsyncThunk('/ebubble/payment/deleteOneBubblePaymentHistory', async (id) => {
    const response = await api.delete(`/api/english_bubble/payment/delete_one/${id}`);
    return response.data;
});

const ebubblePaymentSlice = createSlice({
  name: "ebubblePayment",
  initialState: { histories:[],  status: "idle", error: null, loading: false, count: 0},
  reducers: {
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchBubblePaymentHistories.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(fetchBubblePaymentHistories.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.loading = false;
            state.count = action.payload.count;
            state.histories = action.payload.histories;
        })
        .addCase(fetchBubblePaymentHistories.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
        .addCase(deleteOneBubblePaymentHistory.pending, (state) => {
            state.status = "loading";
            state.loading = true;
        })
        .addCase(deleteOneBubblePaymentHistory.fulfilled, (state, action) => {
            state.status = "idle";
            state.loading = false;
            successNotification("Success!", "Successfully Deleted!")
        })
        .addCase(deleteOneBubblePaymentHistory.rejected, (state, action) => {
            state.status = "failed";
            state.loading = false;
            state.error = action.error.message;
            if(action.error.message === "Request failed with status code 403") {
              state.error = "Your token is expired or invalid! Please try to login again."
            }
            errorNotification("Failed!", state.error);
        })
  },
});

export default ebubblePaymentSlice.reducer;