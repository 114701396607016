import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Highlighter from 'react-highlight-words';

import { Popconfirm, Table, Typography, Space, Button, Input, Modal, Divider, message} from 'antd';
import { SearchOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import { deleteOneRound, fetchAllTest3s, editOneRound, addOneRound } from "../../../../../features/piano/test/test3/test3Slice.js";
import { fetchVersion, editTest3Version } from "../../../../../features/piano/versionSlice.js";

import "./Test3.css";

const {Text} = Typography;

const Test3 = () => {
  
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const test3Field = useSelector((state) => state.test3Field);
    const version = useSelector((state) => state.version);

    const [pagination, setPagination] = useState({
      pageSize: 5,
      current: 1,
      showSizeChanger: true,
      pageSizeOptions: ['5', '10'],
    });

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [test3CurrentVersion, setTest3CurrentVersion] = useState(1.0);
    const [test3UpdatedVersion, setTest3UpdatedVersion] = useState(1.0);
    const [isEditModal, setIsEditModalOpen] = useState(false);
    const [isAddModal, setIsAddModalOpen] = useState(false);
    const [editedRound, setEditedRound] = useState(1);
    const [selectedRoundInfo, setSelectedRoundInfo] = useState({round: 1, style: 1, _id: ""});
    const [unfilledUpload, setUnfilledUpload] = useState(false);
    const [maxRound, setMaxRound] = useState(0);


    // functions for modal when update button is pressed.

    const updateErrorModal = () => {
      Modal.error({
        title: 'Check your upload done.',
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>There are still some images and sounds to upload.</p>
            <p>Please check then try again!</p>
          </div>
        ),
        onOk() {},
      });
    };

    const updateConfirmModal = () => {
      Modal.confirm({
        title: 'Do you really Want to update version?',
        icon: <ExclamationCircleFilled />,
        width: 500,
        content: (
          <div>
            <p style={{marginTop: "15px", marginBottom: "5px"}}>If you press OK, app data will be updated.</p>
            <p>Please confirm if you input correct number for next version. The next version number must be bigger that current version. </p>
            <TextField
              disabled
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={test3UpdatedVersion}
              className="version"
            />
          </div>
        ),
        onOk() {
          handleVersionUpdate();
        },
        onCancel() {
        },
      });
    };

    useEffect(() => {
      if(test3Field.status === "idle") {
        dispatch(fetchAllTest3s());
      }
      if(version.status === 'idle') {
        dispatch(fetchVersion());
      }
      if(version.status === 'succeeded') {
        setTest3CurrentVersion(version.test3Version);
        setTest3UpdatedVersion(parseFloat(version.test3Version + 0.1).toFixed(1));
      }
    }, [dispatch, test3Field, version]);

    useEffect(() => {
      let max = 0;
      let unfilled = false;
      for(let i = 0; i < test3Field.test3s.length; i ++) {
        if(test3Field.test3s[i].round > max) {
          max = test3Field.test3s[i].round;
        }
        for(let j = 0; j < test3Field.test3s[i].problems.length; j ++) {
          if(test3Field.test3s[i].problems[j].keyNum !== test3Field.test3s[i].problems[j].uploadedImgs) {
            unfilled = true; break;
          }
          if(test3Field.test3s[i].problems[j].keyNum !== test3Field.test3s[i].problems[j].uploadedSounds) {
            unfilled = true; break;
          }
        }
      }
      setUnfilledUpload(unfilled);
      setMaxRound(max);
    }, [dispatch, test3Field.test3s])

    const handleTableChange = (pagina, filters, sorter) => {
      setPagination(pagina);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({
                  closeDropdown: false,
                });
                setSearchText(selectedKeys[0]);
                setSearchedColumn(dataIndex);
              }}
            >
              Filter
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1677ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });

    // Edit Modal 

    const showEditModal = () => {
      setIsEditModalOpen(true);
    };

    const handleSave = () => {
      dispatch(editOneRound(selectedRoundInfo));
      setIsEditModalOpen(false);
    };
    const handleCancel = () => {
      setIsEditModalOpen(false);
      setIsAddModalOpen(false);
    };

    // Add Modal

    const showAddModal = () => {
      setIsAddModalOpen(true);
    };

    const handleAdd = () => {
      dispatch(addOneRound(selectedRoundInfo));
      setIsAddModalOpen(false);
    };

    
    // Modal textfield change

    const changeSelectedRound = (e) => {
      setSelectedRoundInfo(prev => {
        return {...prev, round: e.target.value};
      });
    }

    //update textfield
    const handleChangeVersion = (e) => {
      setTest3UpdatedVersion(e.target.value);
    }

    //operation

    const handleRoundDetail = (record) => {
        navigate(`/home/arabic/piano/test3/${record._id}`);
    }

    const handleRoundDelete = (record) => {
        dispatch(deleteOneRound(record));
    }

    const handleRoundAdd = () => {
      setSelectedRoundInfo({
        rid: "",
        round: maxRound + 1,
        fee: 0,
        style: 1
      });
      showAddModal();
    }
    
    const handleRoundEdit = (record) => {
      setSelectedRoundInfo({
        rid: record._id,
        round: record.round,
        fee: record.fee,
        style: record.style
      });
      setEditedRound(record.round);
      showEditModal();
    };

    const handleVersionUpdate = () => {
      if(test3UpdatedVersion <= test3CurrentVersion) {
        message.error("Plesae check the Next Version number", 3);
        return;
      }
      dispatch(editTest3Version({version: test3UpdatedVersion}));
    }

    const problemColumns = [
        {
        title: 'No', 
        key: 'no',
        dataIndex: 'no',
        width: '8%',
        render: (text, record, index) => index + 1,
        },
        {
        title: 'Letter',
        key: 'letter',
        dataIndex: 'letter',
        width: '40%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
          return <Text code><Text type={record.keyNum === record.uploadedImgs ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need Upload: {record.keyNum} Upload Done: {record.uploadedImgs}</Text></Text>
        }
        },
        {
        title: 'Sound',
        key: 'sound',
        dataIndex: 'sound',
        width: '40%',
        textWrap: 'word-break',
        ellipsis: true,
        render: (text, record, index) => {
          return <Text code><Text type={record.keyNum === record.uploadedSounds ? "success" : "danger"} style={{fontFamily:"'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace", fontSize: 16}}>Need Upload: {record.keyNum} Upload Done: {record.uploadedSounds}</Text></Text>
        }
        },
        {
        title: 'KeyNum',
        dataIndex: 'keyNum',
        // width: '12%',
        },
    ];

    const columns = [
        {
        title: 'Round',
        key: 'round',
        dataIndex: 'round',
        width: '10%',
        sorter: (a, b) => b.round - a.round, 
        defaultSortOrder: 'descend',
        ...getColumnSearchProps('round'),
        },
        {
        title: 'Problems',
        key: 'innerTable',
        width: '80%',
        render: (text, record, index) => { 
            const i = test3Field.test3s.findIndex(test3 => test3 === record);
            return (<Table
            dataSource={test3Field.test3s[i].problems}
            columns={problemColumns}
            pagination={false}
            />)
        },
        },
        {
        title: 'Operation',
        key: 'operation',
        dataIndex: 'operation',
        render: (_, record) => (
            <Space size="middle" >
                <Typography.Link  onClick={() => handleRoundEdit(record)} >
                    <Tooltip title="Edit">
                        <Icon sx={{fontSize: 18}}>edit</Icon>
                    </Tooltip>
                </Typography.Link>

                <Typography.Link  onClick={() => handleRoundDetail(record)} style={{ color: "green"}}> 
                    <Tooltip title="Detail" >
                        <Icon sx={{fontSize: 18}}>visibility</Icon>
                    </Tooltip>
                </Typography.Link>

                <Popconfirm title="Sure to delete?" onConfirm={() => handleRoundDelete(record)}>
                    <Typography.Link style={{ color: "red"}}>
                        <Tooltip title="Delete">
                            <Icon sx={{fontSize: 18}}>delete</Icon>
                        </Tooltip>
                    </Typography.Link>
                </Popconfirm>
            </Space>
            ),
        },
    ];

    return(
        <>
            <TextField
              disabled
              label="Current Version"
              id="outlined-size-small"
              size="small"
              value={test3CurrentVersion}
              onChange={handleChangeVersion}
              className="version"
            />
            <TextField
              label="Next Version"
              id="outlined-size-small"
              size="small"
              value={test3UpdatedVersion}
              onChange={handleChangeVersion}
              style={{backgroundColor: "white"}}
              className="version"
            />
            <Button type="primary" size='middle' onClick={() => unfilledUpload ?  updateErrorModal() : updateConfirmModal()} className="updateButton"> Update </Button>
            
            <Button type="primary" size='middle' onClick={() => handleRoundAdd()} className = "addRound"> Add Round </Button>
            <Table
              className="MyTestTable"
              bordered
              dataSource={test3Field.test3s}
              columns={columns}
              loading={test3Field.loading}
              scroll={{ x: '100%' }}
              pagination={pagination}
              onChange={handleTableChange}
              rowKey={(record) => record._id}
            />

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"Round " + editedRound}</h2>} open={isEditModal} onOk={handleSave} onCancel={handleCancel} okText="Save">
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="Round"
                  sx={{ m: 1, width: '28ch' }}
                  value = {selectedRoundInfo.round}
                  onChange = {changeSelectedRound}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </Box>
            </Modal>

            <Modal title= {<h2 style={{fontSize: '24px'}}>{"New Round"}</h2>} open={isAddModal} onOk={handleAdd} onCancel={handleCancel} okText="Create">
              <Divider/>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <TextField
                  label="Round"
                  sx={{ m: 1, width: '28ch' }}
                  value = {selectedRoundInfo.round}
                  onChange = {changeSelectedRound}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                  }}
                />
              </Box>
            </Modal>

        </>
    )
}

export default Test3;
